
import { getCall, postCall, deleteCallAsync } from '../apiHelpers';
import { SiteCompressor } from '../interfaces/site_compressor';


export const fetchAllSiteCompressors =  (authToken: string, site_id: number, transformer:any, setter: any) => 
    getCall(authToken, `/compressors_site?siteid=${site_id}`, transformer, setter)

export const fetchSiteCompressor =  (authToken: string, site_id: number, compressor_site_id: number, transformer:any, setter: any) => 
    getCall(authToken, `/compressors_site/${compressor_site_id}?siteid=${site_id}`, transformer, setter)

export const deleteSiteCompressor =  (authToken: string, site_id: number, compressor_site_id: number) => 
    deleteCallAsync(authToken, `/compressors_site/${compressor_site_id}/delete?siteid=${site_id}`)

export const addSiteCompressor =(authToken: string,  site_id: number, data: SiteCompressor) =>
    postCall(authToken,`/compressors_site?siteid=${site_id}`, data)

export const updateSiteCompressor =(authToken: string,  site_id: number, compressor_site_id: number, data: SiteCompressor) =>
    postCall(authToken,`/compressors_site/${compressor_site_id}?siteid=${site_id}`, data)