import React, { useContext, useState, useEffect } from 'react'
import { Input } from "@progress/kendo-react-inputs";

/**
 * Component for the creation of a new compressor brand
 */

interface AddCompProperyProps {
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    label: string;
}

const AddCompProperty = (props: AddCompProperyProps) => {
    const { inputValue, setInputValue, label } = props;
    return (
        <div >
            <div className="mb-3">
                <label style={{marginRight: "10px"}} htmlFor="brand-name" className="text-info, label-add-compressors">{label} </label>
                <Input value={inputValue}
                    style={{ height: "30px" }}
                    onChange={e => {
                        setInputValue(e.target.value as string);
                    }}
                />
            </div>
        </div>
    )
}

export default AddCompProperty;
