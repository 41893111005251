import React, {useContext} from 'react'
import EventList from './EventList'
import EventDetail from './EventDetail'
import { EventContext } from '../../contexts/EventContextProvider'
import { Container, Row, Col } from 'reactstrap'

const CustomerEvents = () => {
  //ts-migrate FIXME: investigate better type for useContext not any
  const {dispEventDetail} = useContext<any>(EventContext)

  return (
    <Container className="mb-5">
      <Row>
        <Col lg="12" className="px-0 mt-3">
          <h4 className="text-info">PUBLISH LOG</h4>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="px-0">
          {dispEventDetail?
            <EventDetail/>:
            <EventList/>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CustomerEvents
