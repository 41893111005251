import { useContext } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import * as React from "react";
import { MyCommandCell } from "../../pages/myCASystem_elements/MyCommandCell";
import { Grid, GridCellProps, GridToolbar } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { createCas, deleteCas, fetchCaSystems, updateCas } from "../../api/cas/cas";
import { SiteContext } from "../../contexts/SiteContextProvider";
import { Cas } from "../../api/interfaces/cas";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { STATUS } from "../../constants/Status"
import { useLocalization } from '@progress/kendo-react-intl';
import { Field } from "@progress/kendo-react-form";
 
const editField = "inEdit";
const gridWidth = 800;

interface CasListItem extends Cas {
    inEdit?: boolean;
    Discontinued?: boolean;
}

const setPercentage = (percentage: any) => {
    return Math.round(gridWidth / 100) * percentage;

};

class CurrencyDropDownCell extends React.Component<GridCellProps>{
    localizedData = [
        { text: 'EUR', value: "EUR" },
        { text: 'SEK', value: "SEK" },
        { text: 'DOLLAR', value: "DOLLAR" }
    ];

    handleChange = (e: any) => {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.text,
            dataIndex: undefined
        });
    }

    render() {
        //@ts-ignore
        const dataValue = this.props.dataItem[this.props.field];

        if (!this.props.dataItem.inEdit) {
            return (
                <td>
                    {
                        //@ts-ignore
                        (!dataValue) ? '' : this.props.dataItem[this.props.field].toString()}
                </td>
            );
        }

        return (
            <td>
                <DropDownList
                    style={{ width: "100px" }}
                    onChange={this.handleChange}
                    value={this.localizedData.find(c => c.value === dataValue)}
                    data={this.localizedData}
                    textField="text"
                />
            </td>

        );
    }
}

const AddCas = () => {
    const localizationService = useLocalization();
    const { authToken } = useContext<any>(UserContext);
    const { currentSite } = useContext<any>(SiteContext)

    const [data, setData] = React.useState<CasListItem[]>([]);
    const [visibleDialog, setVisibleDialog] = React.useState(false);
    const [visibleDialogCurrency, setVisibleDialogCurrency] = React.useState(false);
    const [hoursError, setHoursError] = React.useState(false);
    const [co2Error, setco2Error] = React.useState(false);
    const [priceError, setPriceError] = React.useState(false);
    const [volumeError, setVolumeError] = React.useState(false);
    const [casLockedList, setcasLockedList] = React.useState<boolean>(false);
    const [visibleDialogCommandCell, setVisibleDialogCommandCell] = React.useState<boolean>(false);
    const [dataItem, setDataItem] = React.useState<any>();
    
    const toggleDialogvisibleDialogCommandCell = () => {
        setVisibleDialogCommandCell(!visibleDialogCommandCell);
      };
    
    const transformData = (result: any) => {
        for (let i = 0; i < result.data.length; i++) {
            result.data[i].numberCAS = i + 1;
        }
        return result.data;
    }

    React.useEffect(() => {
        if (currentSite) {
            fetchCaSystems(authToken, currentSite.id, transformData, setData)
        }
    }, [currentSite]);

    
    React.useEffect(() => {
        lockData();
    })


    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const toggleDialogCurrency = () => {
        setVisibleDialogCurrency(!visibleDialogCurrency);
    };

    const toggleDialogHours = () => {
        setHoursError(!hoursError);
    };

    const toggleDialogco2 = () => {
        setco2Error(!co2Error);
    };

    const toggleDialogPrice = () => {
        setPriceError(!priceError);
    };

    const toggleDialogVolume = () => {
        setVolumeError(!volumeError);
    };

    const insertItem = (item: any) => {
        const newItem = { ...item, inEdit: false };
        const { inEdit, Discontinued, id, ...newItemWithoutExtraParams } = newItem;

        const newCASForApi: Cas = {
            ...newItemWithoutExtraParams,
            id: null,
            nickname: item.nickname || null,
            currency: item.currency || null,
            hours: item.hours || null,
            co2: item.co2 || null,
            price: item.price || null,
            volume: item.volume || null,
            status: STATUS.NOT_PUBLISHED,
            site_id: currentSite.id,
            work_shifts: item.work_shifts || null,
            control_system_code: item.control_system_code || null,
            dew_point: item.dew_point || null,
            iso_standard: item.iso_standard || null,
            next_year_consumption: item.next_year_consumption || null,
            production_area: item.production_area || null,
            gases: item.gases || null,
            working_pressure: item.working_pressure || null,
            system_operation_hours_week: item.system_operation_hours_week || null
        };

        createCas(authToken, newCASForApi)
        .then((response) => {
            if (response.data && response.data.itemid) {
                fetchCaSystems(authToken, currentSite.id, transformData, setData)
            }
        })
    };
    const getItems = () => {
        return data;
    };

    const updateItem = (item: any) => {
        delete item.inEdit
        delete item.Discontinued
        let index = data.findIndex(record => record.id === item.id);
        data[index] = item;

        const updatedCASForApi: Cas = {
            id: item.id,
            nickname: item.nickname || null,
            currency: item.currency || null,
            hours: item.hours || null,
            co2: item.co2 || null,
            price: item.price || null,
            volume: item.volume || null,
            status: item.status,
            site_id: currentSite.id,
            work_shifts: item.work_shifts || null,
            control_system_code: item.control_system_code || null,
            dew_point: item.dew_point || null,
            iso_standard: item.iso_standard || null,
            next_year_consumption: item.next_year_consumption || null,
            production_area: item.production_area || null,
            gases: item.gases || null,
            working_pressure: item.working_pressure || null,
            system_operation_hours_week: item.system_operation_hours_week || null
        };
        updateCas(authToken, item.id, updatedCASForApi)
            .then(() => {
                fetchCaSystems(authToken, currentSite.id, transformData, setData)
            })

        return data;
    };

    const deleteItem = (item: any) => {
        let index = data.findIndex(record => record.id === item.id);
        data.splice(index, 1);
        return data;
    };

    const remove = (dataItem: any) => {
        setDataItem(dataItem);
        toggleDialogvisibleDialogCommandCell(); 
    };

    const removeCas = () => { 
        toggleDialogvisibleDialogCommandCell(); 
        deleteCas(authToken, dataItem.id, currentSite.id, null, null)
        .then((response) => {
            if(response != undefined && response != null && response.data) {
                if(response.data.success) {
                    const newData = deleteItem(dataItem);
                    setData(newData);
                    fetchCaSystems(authToken, currentSite.id, transformData, setData)
                } else { 
                    toggleDialog();
                } 
            }
          })
    };

    const add = (dataItem: any) => {
        const newData = insertItem(dataItem);
    };

    const update = (dataItem: any) => {
        dataItem.inEdit = false;
        const newData = updateItem(dataItem);
        setData(newData);
    };

    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
    };

    const cancel = (dataItem: any) => {
        const originalItem = getItems().find(p => p.id === dataItem.id);
        dataItem.inEdit = false;
        const newData = data.map(item => item.id === originalItem.id ? originalItem : item);
        setData(newData);
    };

    const enterEdit = (dataItem: any) => {
            setData(data.map(item => item.id === dataItem.id ? {
                ...item,
                inEdit: true
            } : item));

    };

    function resetPriceOnCurrencyChanged(): void {
        if (currencyItemChanged === -1) {
            setData(data.map(item => item.id === undefined ? {
                ...item,
                price: undefined
            } : item));
        } else {
            setData(data.map(item => item.id === currencyItemChanged ? {
                ...item,
                price: undefined
            } : item));
        }
        toggleDialogCurrency();
    }

    const [currencyItemChanged, setCurrencyItemChanged] = React.useState<number>();

    function handleSpecialFields(field: string, casId: number, value: string): void {
        const regexpCheckFloat =  new RegExp(/[\-\+]?[0-9]*(\.)?[0-9]*/);
        const regexpCheckInteger =  new RegExp(/^[+]?[0-9]\d*$/);
        switch (field) {
            case "currency":
                setCurrencyItemChanged(casId);
                toggleDialogCurrency()
                break;
            case "hours":
                const isInteger = regexpCheckInteger.test(value);
                if(isInteger || value === ""){
                    break;
                } 
                else {
                    toggleDialogHours()
                    break;
                } 
            case "co2":
                const regexExecCo2 = regexpCheckFloat.exec(value);
                const isFloatco2 = value && regexExecCo2[0] == value;
                if(isFloatco2) {
                    break;
                } 
                else {
                    toggleDialogco2()
                    break;
                } 
            case "price":
                const regexExecPrice = regexpCheckFloat.exec(value);
                const isFloatPrice = value && regexExecPrice[0] == value;
                if(isFloatPrice) {
                    break;
                } 
                else {
                    toggleDialogPrice()
                    break;
                } 
            case "volume":
                const isIntegerVolume = regexpCheckInteger.test(value);
                if(isIntegerVolume || value === ""){
                    break;
                } 
                else {
                    toggleDialogVolume()
                    break;
                } 
            default:
                break;
        }
    }

    const itemChange = (event: any) => {  
        handleSpecialFields(event.field, event.dataItem.id, event.value);
        const newData = data.map(item => item.id === event.dataItem.id ? {
            ...item,
            [event.field || '']: event.value
        } : item);
        setData(newData);
    };

    const addNew = () => {
        const newDataItem: CasListItem = {
            inEdit: true,
            Discontinued: false,
            nickname: undefined,
            currency: undefined,
            co2: undefined,
            price: undefined,
            hours: undefined,
            status: undefined,
            volume: undefined,
            site_id: undefined,
            work_shifts: undefined,
            control_system_code:undefined,
            dew_point:undefined,
            iso_standard:undefined,
            next_year_consumption:undefined,
            production_area:undefined,
            gases:undefined,
            working_pressure:undefined,
            system_operation_hours_week: undefined
        };
        setData([newDataItem, ...data]);
    };

    const lockData = () => {
        if(data.some(cas => cas.status == STATUS.PUBLISHED_WAITING_FOR_APPROVAL)){
            setcasLockedList(true)
        }else {
            setcasLockedList(false)
        }
    }

    const CommandCell = (props: any) => <MyCommandCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard}
        update={update} cancel={cancel} editField={editField} />;
    return (<>

        <div>
        {casLockedList ? <h5>Some of your CAS are being reviewed. You can't modify the data.</h5> : ""}
            <Grid style={{
                height: "420px", width: gridWidth.toString()
            }} data={data} onItemChange={itemChange} editField={editField}   >
                
                <GridToolbar>
                {casLockedList ? "" :
                        <>
                    <button title="Add new" className="k-button k-primary" onClick={addNew}>
                        {localizationService.toLanguageString('custom.addNew', '')}
                    </button>
                    </>
                    }
                </GridToolbar>
                <Column field="numberCAS" title={localizationService.toLanguageString('custom.number', '')} editable={false} width={setPercentage(10)} />
                <Column field="nickname" title={localizationService.toLanguageString('custom.nickname', '')} width={setPercentage(20)} editor="text" />
                <Column field="hours" title={localizationService.toLanguageString('custom.shutdownHoursPerYear', '')} editor="text"width={setPercentage(40)}/>
                <Column field="system_operation_hours_week" title={localizationService.toLanguageString('custom.operationHoursPerWeek', '')} editor="text" width={setPercentage(40)}/>
                <Column field="co2" title={localizationService.toLanguageString('custom.intensityOfElectricity', '')} editor="text" width={setPercentage(45)} />
                <Column field="volume" title={localizationService.toLanguageString('custom.systemStorageVolume', '')} editor="text" width={setPercentage(35)} />
                <Column field="currency" title={localizationService.toLanguageString('custom.currency', '')} width={setPercentage(20)} cell={CurrencyDropDownCell}  />
                <Column field="price" title={localizationService.toLanguageString('custom.priceOfElectricity', '')} editor="text" width={setPercentage(20)} />
                <Column field="status" title={localizationService.toLanguageString('custom.status', '')} editable={false} width={setPercentage(10)} />
                <Column field="work_shifts" title={localizationService.toLanguageString('custom.workShifts', '')} editor="text"width={setPercentage(30)} />
                <Column field="control_system_code" title={localizationService.toLanguageString('custom.controlSystemCode', '')} editor="text" width={setPercentage(40)} />
                <Column field="dew_point" title={localizationService.toLanguageString('custom.dewPoint', '')} editor="text" width={setPercentage(15)} />
                <Column field="iso_standard" title={localizationService.toLanguageString('custom.isoStandard', '')} editor="text" width={setPercentage(40)} />
                <Column field="next_year_consumption" title={localizationService.toLanguageString('custom.nextYearConsumption', '')} editor="text"width={setPercentage(80)} />
                <Column field="production_area" title={localizationService.toLanguageString('custom.productionArea', '')} editor="text" width={setPercentage(35)} />
                <Column field="gases" title={localizationService.toLanguageString('custom.gases', '')} editor="text"width={setPercentage(70)} />
                <Column field="working_pressure" title={localizationService.toLanguageString('custom.workingPressure', '')} editor="text" width={setPercentage(45)} />
                {casLockedList ? "" :<Column cell={CommandCell} width="200px" /> }
            </Grid>
            <div>
                {visibleDialog && (
                    <Dialog title={"Please confirm"} onClose={toggleDialog}>
                        <p
                            style={{
                                margin: "25px",
                                textAlign: "center",
                            }}
                        >
                            {localizationService.toLanguageString('custom.cannotDeleteCAS', '')}
                        </p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={toggleDialog}>
                                ok
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
            <div>
                {
                    visibleDialogCurrency &&
                    (
                        <Dialog title={"Please confirm"} onClose={toggleDialogCurrency}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "center",
                                }}
                            >
                                {localizationService.toLanguageString('custom.selectionOfCurrency', '')}
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={resetPriceOnCurrencyChanged}>
                                    ok
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
            </div>
            <div>
            {
                    hoursError &&
                    (
                        <Dialog title={"Format error"} onClose={toggleDialogHours}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "center",
                                }}
                            >
                                {localizationService.toLanguageString('custom.hoursFieldMustBeNumber', '')}
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={toggleDialogHours}>
                                    ok
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    
                {
                    co2Error &&
                    (
                        <Dialog title={"Format error"} onClose={toggleDialogco2}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "center",
                                }}
                            >
                                {localizationService.toLanguageString('custom.co2FieldMustBeFloat', '')}
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={toggleDialogco2}>
                                    ok
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    {
                    priceError &&
                    (
                        <Dialog title={"Format error"} onClose={toggleDialogPrice}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "center",
                                }}
                            >
                                {localizationService.toLanguageString('custom.priceFieldMustBeFloat', '')}
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={toggleDialogPrice}>
                                    ok
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    {
                    volumeError &&
                    (
                        <Dialog title={"Format error"} onClose={toggleDialogVolume}>
                            <p
                                style={{
                                    margin: "25px",
                                    textAlign: "center",
                                }}
                            >
                                {localizationService.toLanguageString('custom.volumeFieldMustBeFloat', '')}
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={toggleDialogVolume}>
                                    ok
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
            </div> 
            {visibleDialogCommandCell && (
                <Dialog onClose={toggleDialogvisibleDialogCommandCell}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                    {localizationService.toLanguageString('custom.confirmDeletingCAS', '')}
                </p>
                <DialogActionsBar>
                    <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={removeCas}
                    >
                    {localizationService.toLanguageString('custom.ok', '')}
                    </button>
                </DialogActionsBar>
                </Dialog>
            )}
        </div>
    </>)
};

export default AddCas;