import React, { useContext, useEffect } from 'react'
import { UserContext } from '../contexts/UserContextProvider'
import { Container } from 'reactstrap'
import SitesComp from "../components/sites/SitesComp";


/**
 * Page for the Sites user info.
 * A user can see their sites information.
 */
const Sites = () => {
    const { getSessions, currentUser } = useContext<any>(UserContext)

    return (
        <Container fluid className="profile">

            <SitesComp />

        </Container>
    )
}

export default Sites