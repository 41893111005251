import React, {useContext} from 'react'
import { EventContext } from '../../contexts/EventContextProvider'
import EditEvent from './EditEvent'
import { Container, Row, Col, Fade } from 'reactstrap';
import Spinner from '../../images/loading.gif'

/**
* Display the detail of a particular Event Log.
*/
const EventDetail = () => {
  //ts-migrate FIXME: investigate type of useContext to dont be "any"
  const {eventDetail, dispEvents, dispEditEvent, dispEventToEdit} = useContext<any>(EventContext);

  /**
  * Print the email of the Event's creator.
  * Email is saved in the database as 'customer_id'.
  */
  const dispEventInfo = () =>{
    return(
      <div className="d-flex">
        <h6 className="ml-1 pl-2 pt-2 text-info" key={"key"}>
          Created by: 
        </h6>
        <h6 className="ml-1 pl-2 pt-2 text-secondary">{eventDetail[0].customer_id}</h6>   
      </div>
    )
  }

  /**
  * Display a particular Event Data
  * To print data from the large string, 'JSON.parse' method is used. 
  */
  const dispEventData= () => {
    let rawData = JSON.parse(eventDetail[0].comp_raw_data)
    return (
      <div className="ml-1 mx-0 mt-2" key={"mridu"}>  
        {(()=>{
          let list = [];
          let data = rawData.compressor
          for(let x in data){          
            list.push(
              <Row className="pt-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="7">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="5">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.fixed_properties
          for(let x in data){          
            list.push(
              <Row className="pt-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="7">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="5">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.dynamic_properties
          for(let x in data){          
            list.push(
              <Row className="pt-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="7">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="5">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.motor
          for(let x in data){          
            list.push(
              <Row className="pt-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="7">
                  <h6 className="text-info text-capitalize">{data[x] !== null && x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="5">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
      </div>
    )        
  }

  /**
  * Gather all information of en Event by calling two other functions,
  * 'dispEventInfo' and 'dispEventData'.
  */
  const dispEventDetail = () => {
    return (
      <>
      <Row>
        <Col lg="12" className="event-detail-top-bg text-white py-1 text-center">
          <h3 className="mb-0 event-detail-top-text">{ eventDetail && eventDetail[0].action_desc } </h3>
        </Col>
      </Row>
      {!eventDetail?(
        <div className="d-flex justify-content-center">
          <img src={Spinner} alt="Loading..." width="30%"/>
        </div>):(
          <Fade>
            <Row className="mt-3 mb-3">
              <Col lg="6" className="mt-2">
                {dispEventInfo()}
                {dispEventData()}          
              </Col>
              <Col lg="6">
                
              </Col>
            </Row>
          </Fade>
        )}
      <hr className="mt-5"/>
      <Row className="d-flex justify-content-center mb-4">
        <Col lg="6" className="mt-1 d-flex justify-content-center px-0">
          <Col lg="4" className="px-0 mr-2">
            <button className="btn btn-sm btn-outline-info btn-block" 
              onClick={dispEvents}>BACK
            </button>
          </Col>
          <Col lg="4" className="px-0 mx-1">
            <button className="btn btn-sm btn-outline-primary btn-block" 
              onClick={()=>dispEventToEdit()}>EDIT
            </button>
          </Col>                      
          <Col lg="4" className="px-0 ml-2">
            <button disabled className="btn btn-sm btn-outline-danger btn-block">
              DELETE EVENT
            </button>
          </Col>            
        </Col>
      </Row>
      </>
    )        
  }

  return (
    <Container className="rounded" style={{border: "1px solid #12381d"}}>
      {!dispEditEvent? (
        dispEventDetail()):
        <EditEvent/>
      }
    </Container>
  )
}

export default EventDetail
