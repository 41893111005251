import React, {useContext, useState, useEffect} from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Fade } from 'reactstrap'
import { UserContext } from '../../contexts/UserContextProvider'
import APIURL from '../../configs/APIConfig'
import axios from 'axios';

const AddCompType = () => {
  const { getSessions, authToken } = useContext<any>(UserContext);
  const [compType, setCompType] = useState('')
  const [message, setMessage] = useState('')

  const authAxios = axios.create({
    baseURL: APIURL.url,
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })

  const data = {
    comp_type: compType
  }

  const postCompType = async (e: any) => {
    e.preventDefault();
    try{
    const result = await authAxios.post(`/comptypes`, data)
      setMessage(result.data)
      console.log(message);
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  return (
        <Container className="px-0">
            <Row className="px-0">
                <Col lg="7" className="px-0 mt-3">
                    <h5 className="text-secondary">ADD COMPRESSOR TYPE</h5>
                    <hr/>
                    <Fade>
                    <Form className="mt-3 mb-4 px-0" onSubmit={postCompType}>
                        <FormGroup>
                            <Label for="compressor-type" className="text-info">Compressor Type</Label>
                            <Input type="text" name="compressor-type" placeholder="ex: Turbo"
                style={{height: "30px"}}
                onChange={e=>setCompType(e.target.value)}/>
            </FormGroup>
                        <button className="btn btn-outline-success btn-block mt-1" 
              style={{height: "30px", fontSize: "16px", paddingTop: "4px"}}>
                            <h6>SUBMIT</h6>
            </button>
          </Form>
          </Fade>
        </Col>
                <Col lg="5" className="pl-4 pr-0 mt-5">
                    <p className="pr-0 pl-2 text-dark">Select a table from the menu. If the table does 
            not exist, please create a table with corresponding attrubutes.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default AddCompType
