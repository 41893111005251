export const svMessages = {
    "custom": {  
        "number": "№",
        "nickname": "Smeknamn",
        "brand": "Varumärke",
        "model": "Modell",
        "output": "Produktion",
        "powerSupply": "Power supply",
        "cooling": "Kyl",
        "motorPower": "Motor kraft",
        "pressure": "Tryck",
        "regulation": "förordning",
        "serialNumber": "Serienummer",
        "type": "Typ",
        "yearManufactured": "Tillverkningsår",
        "comment": "Kommentar",
        "exportExcel": "Exportera till Excel",
        "exportPDF": "Exportera till PDF",
        "AddCompressor": "Lägg till kompressor",
        "DisplayCompressors": "Visa kompressorer",
        "compressedAirSystem": "Tryckluftssystem",
        "newCompressor": "NY KOMPRESSOR", 
        "AddNewBrand": "Lägg till nytt varumärke",
        "AddNewModel": "Lägg till ny modell",
        "AllFieldsAreRequired": "alla fält är obligatoriska",
        "step": "Steg",
        "of": "av",
        "next": "Nästa",
        "newCompressorBrand": "Nytt kompressormärke",
        "newBrand": "Nytt märke",
        "add": "Lägg till",
        "cancel": "Avbryt",
        "newModel": "Ny modell",
        "newCompressorModel": "Ny kompressormodell",
        "unit": "Enhet",
        "powerSupplyVoltage": "Kraftkällespänning",
        "types": "Typer",
        "serialNo": "Serienummer",
        "myCompressorFiles": "Mina kompressorfiler",
        "uploadFiles": "Ladda upp filer",
        "selectFiles": "Välj filer",
        "Filename": "Filnamn",
        "clear": "Klar",
        "upload": "Ladda upp",
        "allUnitsAreRequired": "Alla enheter krävs",
        "submitAndDisplay": "Skicka och visa",
        "yourChangesSaved": "Dina ändringar har sparats",
        "previous": "Tidigare",
        "uploadedFiles": "Uppladdade filer",
        "none": "Ingen",
        "addNewCas": "Lägg till nytt CAS",
        "displayCas": "Visningsskåp",
        "publishCas": "Publicera CAS",
        "personalInfo": "PERSONLIG INFORMATION",
        "role": "Roll",
        "email": "E-post",
        "lastName": "Efternamn",
        "firstName": "Förnamn",
        "country": "Land",
        "sitesInfo": "SIDA INFO",
        "siteName": "Sidnamn",
        "exampleSites": "Lite snabb exempeltext om vi behöver beskriva webbplatsen eller lägga till mer information",
        "changeSite": "Byt webbplats",
        "addNew": "Lägg till ny",
        "cannotDeleteCAS": "Kan inte radera eftersom det finns länkade kompressorer till detta CAS",
        "selectionOfCurrency": "Valet av en ny valuta kommer att åsidosätta elpriset",
        "hoursFieldMustBeNumber": "Fältet timmar måste vara ett tal (inte decimal)",
        "shutdownHoursPerYear": "Systemavstängning timmar per år",
        "intensityOfElectricity": "CO2-intensitet för elproduktion",
        "systemStorageVolume": "Systemlagringsvolym",
        "priceOfElectricity": "Pris på el",
        "currency": "Valuta",
        "status": "Status",
        "youAreGoingToPublishCAS": "Du kommer att publicera tryckluftssystemet",
        "withTheFollowingData": "med följande data",
        "totalNumberOf": "Totalt antal",
        "compressors": "KOMPRESSORER",
        "afterPublishingCAS": "Efter publicering av tryckluftssystemet",
        "youWillNotBeAbleToModify": "data kommer du inte att kunna ändra den",
        "enersizeExpertWillReview": "Enersize-experterna kommer att granska den och verifiera informationen",
        "areYouSureYouWantToPublish": "Är du säker på att du vill publicera denna information? Om du trycker på Ja kan dina data inte längre redigeras",
        "yes": "Ja",
        "ThankYouYourInformation": "Tack! Din information har publicerats framgångsrikt",
        "ourExpertsWillReviewAndInformYou": "Våra experter kommer att granska din information och kontakta dig",
        "menu": "Meny",
        "addElementsToYourCAS": "Lägg till nya element till ditt tryckluftssystem",
        "myCASInfo": "MIN CAS INFO",
        "site": "Webbplats",
        "locale": "Plats",
        "bePartOfTheChange": "Var en del av förändringen inom hållbar tryckluftshantering",
        "enersizeIs": "Enersize är oberoende av kompressortillverkare och på ett uppdrag att bli världsledande" +
        "i mjukvara för komprimerad lufteffektivitet. Vi utvecklar och levererar smart mjukvara och tjänster för att möjliggöra" +
        "energioptimering av industriella tryckluftssystem." +
        "Vår 3-stegsresa mot energiexpertis erbjuder verktygen och expertis för energioptimering av " +
        "industriell tryckluft. Börja med en revision för att identifiera din unika besparingspotential – och lär dig " +
        "hur man uppnår det.",
        "needAnAccount": "Behöver ett konto? Kontakt",
        "ok":"ok",
        "maximumtenfiles": "Du kan bifoga högst tio filer",
        "confirmDeletingCAS": "Bekräfta borttagning av CAS",
        "enterValidCompressorInformation": "Fyll i kompressorinformationen innan du laddar upp",
        "delete": "Radera",
        "pleaseConfirm": "Vänligen bekräfta",
        "deleteConfirmationCompressor": "Är du säker på att du vill ta bort den här kompressorn?",   
        "co2FieldMustBeFloat": "Fältet CO2 måste vara en decimal separerad med ett kommatecken",
        "priceFieldMustBeFloat": "Elprisfältet måste vara en decimal avgränsad med ett kommatecken",
        "volumeFieldMustBeFloat": "Lagringssystemets volymfält måste vara nummer (inte decimal)",
        "profile": "Profil",
        "sites": "Webbplatser",
        "mycas": "Mitt tryckluftssystem",
        "compressorNickname": "Smeknamn för kompressor",
        "compressorSerialNo": "Kompressor serienummer",
        "compressorType": "Kompressor typ",
        "compressorPressure": "Kompressortryck",
        "pressureUnit": "Tryckenhet",
        "compressorRegulation": "Kompressorreglering",
        "compressorCooling": "Kompressorkylning",
        "compressorYearManufactured": "Kompressor Tillverkningsår",
        "compressorPowerSupply": "Kompressor strömförsörjning",
        "powerSupplyUnit": "Nätaggregat",
        "compressorMotorPower": "Kompressormotoreffekt",
        "motorPowerUnit": "Motorkraftsenhet",
        "compressorOutput": "Kompressorutgång",
        "outputUnit": "Utgångsenhet",
        "save": "spara",
        "phonenumber": "Telefon",
        "updateSite": "Uppdatera webbplats",
        "createSite": "Ny Sida",
        "disableSite": "Inaktivera webbplatsen",
        "changes_saved": "Dina ändringar har sparats",
        "something_wrong": "Oops! Något gick fel. Försök igen senare",
        "draw/map": "Karta/ritning av anläggningen (valfritt)",
        "name": "Namn",
        "address": "Adress",
        "currency": "valuta",
        "contact_person_name": "Kontakt",
        "contact_person_email": "Email",
        "contact_person_telephone": "telefon",
        "company_name": "Företagsnamn",
        "editSite" : "Redigera Webbplats",
        "validEmail" : "Vänligen ange en giltig email",
        "frequency" : "Frekvens",
        "optionalFields": "Valfria Fält",
        "optionalFieldsInfo": "Följande fält är valfria att fylla i. Vår Enersize Expert kommer att komplettera dem om du inte har denna information tillgänglig.",
        "PowerFactorLoaded": "Power Factor Loaded",
        "PowerFactorOffload": "Power Factor Offload",
        "totalPowerLoaded": "Total paketeffekt laddad",
        "totalPowerOffload": "Total paketströmavlastning",
        "controlSystem": "Ansluten till ett kontrollsystem?",
        "start": "Start",
        "leaqs": "Q+Leaqs",
        "workShifts": "Arbetsskift (antal, timmar)",
        "controlSystemCode": "Kontrollsystems varumärke och produktkod",
        "dewPoint": "Dagpunkt/°C",
        "isoStandard": "Vilken ISO-standard för luftkvalitet?",
        "nextYearConsumption": "Förväntar du dig att luftförbrukningen kommer att öka eller minska under nästa år?",
        "productionArea" : "Produktionsyta / m2",
        "gases": "Andra gaser förväntas rapporteras? Kväve, CO2, Argon, Syre?",
        "workingPressure": "Arbetstryck i systemet / bar",
        "operationHoursPerWeek": "Systemdrifttimmar per vecka"                            
                                                                          
    },
    "grid":{
        "groupPanelEmpty": "Dra en kolumnrubrik och släpp den här för att gruppera efter den kolumnen",
        "pagerItemsPerPage": "objekt per sida",
        "pagerInfo": "{0} - {1} av {2} föremål",
        "pagerFirstPage": "Gå till första sidan",
        "pagerPreviousPage": "Gå till föregående sida",
        "pagerNextPage": "Gå till nästa sida",
        "pagerLastPage": "Gå till sista sidan",
        "pagerPage": "Sida",
        "pagerOf": "av",
        "filterClearButton":"Rensa",
        "filterEqOperator": "Är lika med",
        "filterNotEqOperator": "Är inte lika med",
        "filterIsNullOperator": "Är null",
        "filterIsNotNullOperator": "Är inte null",
        "filterIsEmptyOperator": "Är tom",
        "filterIsNotEmptyOperator": "Är inte tom",
        "filterStartsWithOperator": "Börjar med",
        "filterContainsOperator": "Innehåller",
        "filterNotContainsOperator": "Innehåller inte",
        "filterEndsWithOperator": "Slutar med",
        "filterGteOperator": "Är större än eller lika med",
        "filterGtOperator": "Är större än",
        "filterLteOperator": "Är mindre än eller lika med",
        "filterLtOperator":"Är mindre än",
        "filterIsTrue":"Är sant",
        "filterIsFalse": "Är falskt",
        "filterBooleanAll": "(Allt)",
        "filterAfterOrEqualOperator": "Är efter eller lika med",
        "filterAfterOperator": "Är efter",
        "filterBeforeOperator":"Är innan",
        "filterBeforeOrEqualOperator":"Är före eller lika med",
        "noRecords":"Inga poster tillgängliga."
    },
    "filter": {
        "eqOperator": "Är lika med",
        "notEqOperator": "Är inte lika med",
        "isNullOperator": "Är null",
        "isNotNullOperator": "Är inte null",
        "isEmptyOperator": "Är tom",
        "isNotEmptyOperator": "Är inte tom",
        "startsWithOperator": "Börjar med",
        "containsOperator": "Innehåller",
        "notContainsOperator": "Innehåller inte",
        "endsWithOperator": "Slutar med",
        "gteOperator": "Är större än eller lika med",
        "gtOperator": "Är större än",
        "lteOperator": "Är mindre än eller lika med",
        "ltOperator": "Är mindre än",
        "isTrue": "Är sant",
        "isFalse": "Är falskt",
        "afterOrEqualOperator": "Är efter eller lika med",
        "afterOperator": "Är efter",
        "beforeOperator": "Är innan",
        "beforeOrEqualOperator": "Är före eller lika med",
        "andLogic": "Och",
        "orLogic": "Eller",
        "addExpression": "Lägg till uttryck",
        "addGroup": "Lägg till grupp",
        "close": "Stäng"
    },
    "dateinput":{
        "increment": "Öka",
        "decrement": "Minska"
    },
    "calendar":{
        "today":"I dag"
    },
    "datepicker":{
        "toggleCalendar": "Visa kalender"
    },
    "daterangepicker": {
        "swapStartEnd": "Byt start- och slutvärden",
        "start": "Start",
        "end": "Slut",
        "separator": " "
    },
    "datetimepicker": {
        "date": "Datum",
        "time": "Tid",
        "toggleDateTimeSelector": "Växla datum- och tidväljare",
        "cancel": "Avbryt",
        "set": "Välj"
    },
    "editor": {
        "bold": "Fet",
        "italic": "Kursiv",
        "underline": "Understrykning",
        "strikethrough": "Genomstruken",
        "subscript": "Index",
        "superscript": "Exponent",
        "unlink": "Ta bort länk",
        "undo": "Ångra",
        "redo": "Göra om",
        "fontSize": "Textstorlek",
        "fontName": "Typsnittsnamn",
        "format": "Format",
        "alignLeft": "Justera texten till vänster",
        "alignRight": "Justera texten åt höger",
        "alignCenter": "Centrera texten",
        "alignJustify": "Rättfärdiga",
        "indent": "Indrag",
        "outdent": "Utdrag",
        "orderedList": "Infoga numrerad lista",
        "bulletList": "Infoga punktlista",
        "createTable": "Skapa en tabell",
        "insertTableHint": "Skapa en {0} x {1} tabell",
        "addRowBefore": "Lägg till rad ovan",
        "addRowAfter": "Lägg till rad nedan",
        "addColumnBefore": "Lägg till kolumn till vänster",
        "addColumnAfter": "Lägg till kolumn till höger",
        "deleteRow": "Ta bort rad",
        "deleteColumn": "Ta bort kolumn",
        "deleteTable": "Ta bort tabell",
        "mergeCells": "Sammanfoga celler",
        "splitCell": "Dela cell",
        "hyperlink": "Infoga hyperlänk",
        "hyperlink-dialog-title": "Infoga hyperlänk",
        "hyperlink-dialog-content-address": "webbadress",
        "hyperlink-dialog-content-title": "Title",
        "hyperlink-dialog-content-newwindow": "Öppna länk i nytt fönster",
        "hyperlink-dialog-cancel": "Avbryt",
        "hyperlink-dialog-insert": "Föra in",
        "image": "Insert image",
        "image-dialog-title": "Insert image",
        "image-address": "Web address",
        "image-title": "Title",
        "image-altText": "Alternate text",
        "image-width": "Bredd (px)",
        "image-height": "Höjd (px)",
        "image-cancel": "Avbryt",
        "image-insert": "Föra in",
        "viewHtml": "Se HTML",
        "viewHtml-dialog-title": "Se HTML",
        "viewHtml-cancel": "Avbryt",
        "viewHtml-update": "Uppdatering"
    },
    "timepicker": {
        "now": "NU",
        "selectNow": "Välj nu",
        "cancel": "Avbryt",
        "set": "Uppsättning",
        "toggleTimeSelector": "Växla TimeSelector",
        "toggleClock": "Växla klocka"
    },
    "dropdowns":{
        "nodata": "INGEN INFORMATION HITTAD.",
        "clear": "Rensa"
    },
    "numerictextbox":{
        "increment": "Öka värdet",
        "decrement": "Minska värdet"
    },
    "upload": {
        "cancel": "Avbryt",
        "clearSelectedFiles": "Rensa",
        "dropFilesHere": "Släpp filer här för att ladda upp",
        "headerStatusUploaded": "Gjort",
        "headerStatusUploading": "Laddar upp...",
        "invalidFileExtension": "Filtyp är inte tillåten.",
        "invalidFiles": "Ogiltig(a) fil(er). Kontrollera kraven för filuppladdning.",
        "invalidMaxFileSize": "Filstorleken är för stor.",
        "invalidMinFileSize": "Filstorleken är för liten.",
        "remove": "Ta bort",
        "retry": "Försök igen",
        "select": "Välj filer...",
        "uploadSelectedFiles": "Ladda upp",
        "total": "Total",
        "files": "filer"
    },
    "sortable": {
        "noData": "Inga data"
    },
    "pager": {
        "itemsPerPage": "objekt per sida",
        "info": "{0} - {1} av {2} objekt",
        "firstPage": "Gå till första sidan",
        "previousPage": "Gå till föregående sida",
        "nextPage": "Gå till nästa sida",
        "lastPage": "Gå till sista sidan",
        "page": "Sida",
        "of": "av"
    },
    "treelist": {
        "filterClearButton": "Rensa",
        "filterEqOperator": "Är lika med",
        "filterNotEqOperator": "Är inte lika med",
        "filterIsNullOperator": "Är null",
        "filterIsNotNullOperator": "Är inte null",
        "filterIsEmptyOperator": "Är tom",
        "filterIsNotEmptyOperator": "Är inte tom",
        "filterStartsWithOperator": "Börjar med",
        "filterContainsOperator": "Innehåller",
        "filterNotContainsOperator": "Innehåller inte",
        "filterEndsWithOperator": "Slutar med",
        "filterGteOperator": "Är större än eller lika med",
        "filterGtOperator": "Är större än",
        "filterLteOperator": "Är mindre än eller lika med",
        "filterLtOperator": "Är mindre än",
        "filterIsTrue": "Är sant",
        "filterIsFalse": "Är falskt",
        "filterBooleanAll": "(Allt)",
        "filterAfterOrEqualOperator": "Är efter eller lika med",
        "filterAfterOperator": "Är efter",
        "filterBeforeOperator": "Är innan",
        "filterBeforeOrEqualOperator": "Är före eller lika med",
        "noRecords": "Inga poster tillgängliga"
    },
    "scheduler": {
        "editorValidationRequired": "Fältet är obligatoriskt.",
        "editorValidationStart": "Starttiden måste vara före Sluttiden.",
        "editorValidationEnd": "Sluttiden måste vara efter starttiden.",
        "allEvents": "Alla evenemang",
        "allDay": "hela dagen",
        "today": "I dag",
        "dayViewTitle": "Dag",
        "monthViewTitle": "Månad",
        "weekViewTitle": "Vecka",
        "workWeekViewTitle": "Arbetsvecka",
        "timelineViewTitle": "Tidslinje",
        "agendaViewTitle": "Agenda",
        "deleteTitle": "Radera",
        "previousTitle": "Tidigare",
        "nextTitle": "Nästa",
        "showFullDay": "Visa hela dagen",
        "showWorkDay": "Visa öppettider",
        "editorOccurrence": "Redigera aktuell förekomst",
        "editorSeries": "Redigera serien",
        "editorRecurringConfirmation": "Vill du bara redigera denna händelseförekomst eller hela serien?",
        "editorRecurringDialogTitle": "Redigera återkommande objekt",
        "editorSave": "Spara",
        "editorCancel": "Avbryt",
        "editorDelete": "Radera",
        "editorTitle": "Händelse",
        "editorEventTitle": "Titel",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Starta tidszon",
        "editorEventEnd": "Slut",
        "editorEventEndTimeZone": "Sluttidszon",
        "editorEventAllDay": "Heldagsevent",
        "editorEventDescription": "Beskrivning",
        "editorEventSeparateTimeZones": "Sluta i en annan tidszon",
        "editorEventTimeZone": "Ange tidszon",
        "recurrenceEditorRepeat": "Upprepa",
        "recurrenceEditorDailyInterval": "dag(ar)",
        "recurrenceEditorDailyRepeatEvery": "Upprepa varje",
        "recurrenceEditorWeeklyInterval": "Veckor",
        "recurrenceEditorWeeklyRepeatEvery": "Upprepa varje",
        "recurrenceEditorWeeklyRepeatOn": "Upprepa på",
        "recurrenceEditorMonthlyDay": "Dag",
        "recurrenceEditorMonthlyInterval": "månad(er)",
        "recurrenceEditorMonthlyRepeatEvery": "Upprepa varje",
        "recurrenceEditorMonthlyRepeatOn": "Upprepa på",
        "recurrenceEditorYearlyOf": "av",
        "recurrenceEditorYearlyRepeatEvery": "Upprepa varje",
        "recurrenceEditorYearlyRepeatOn": "Upprepa på",
        "recurrenceEditorYearlyInterval": "år",
        "recurrenceEditorFrequenciesDaily": "Dagligen",
        "recurrenceEditorFrequenciesMonthly": "En gång i månaden",
        "recurrenceEditorFrequenciesNever": "Aldrig",
        "recurrenceEditorFrequenciesWeekly": "Varje vecka",
        "recurrenceEditorFrequenciesYearly": "Årlig",
        "recurrenceEditorOffsetPositionsFirst": "Först",
        "recurrenceEditorOffsetPositionsSecond": "Andra",
        "recurrenceEditorOffsetPositionsThird": "Tredje",
        "recurrenceEditorOffsetPositionsFourth": "Fjärde",
        "recurrenceEditorOffsetPositionsLast": "Sista",
        "recurrenceEditorWeekdaysDay": "Dag",
        "recurrenceEditorWeekdaysWeekday": "Veckodag",
        "recurrenceEditorWeekdaysWeekendday": "Helgdag",
        "recurrenceEditorEndAfter": "Efter",
        "recurrenceEditorEndOccurrence": "händelse(r)",
        "recurrenceEditorEndLabel": "Slut",
        "recurrenceEditorEndNever": "Aldrig",
        "recurrenceEditorEndOn": "På",
        "deleteConfirmation": "Är du säker på att du vill ta bort denna händelse?",
        "deleteRecurringConfirmation": "Vill du ta bort endast denna händelseförekomst eller hela serien?",
        "deleteOccurrence": "Ta bort aktuell förekomst",
        "deleteSeries": "Ta bort serien",
        "deleteDialogTitle": "Ta bort händelse",
        "deleteRecurringDialogTitle": "Ta bort återkommande objekt",
        "dateTitle": "Datum",
        "timeTitle": "Tid",
        "eventTitle": "Händelse",
        "noEvents": "inga händelser"
    }
};