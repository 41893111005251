import {useEffect} from "react" 
import {config} from "../Config"
import {Row, Col, Button, Container} from "reactstrap"

const SignupCognito = () => { 
  
    const signup = () => {
        const signUpUri = `https://${config.authDomain}/signup?client_id=${config.clientId}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${config.redirectUri}`
        console.log("Signing in at:", signUpUri)
        window.location.href = signUpUri
    }  
  useEffect(()=>{
    signup()
   }, [])
    return ( 
        <div> 
        </div>
    )
}

export default SignupCognito