import { useContext } from 'react'
import { Button } from "@progress/kendo-react-buttons";
import { FileCompressor } from "../../api/interfaces/file_compressor";
import { UserContext } from '../../contexts/UserContextProvider'
import { deleteCompFile } from '../../api/compressor_site_files/compressor_site_files';
import { useLocalization } from '@progress/kendo-react-intl';

/**
* Component to display files
*/
const DisplayFiles = (props: any) => {
  const localizationService = useLocalization();
  const { authToken } = useContext<any>(UserContext);

  const deleteFile = (file: FileCompressor) => {  
    deleteCompFile(authToken, file.site_compressor_id, file.id, file.site_id)
    .then(()=> {
      props.finishUploadCallback();
    })
  };

  return (
    <div> 
    <label htmlFor="uploaded-files" className="text-info" style={{marginTop: "10px"}}>{localizationService.toLanguageString('custom.upload', '')}: </label>
    {props.currentFiles.length > 0 ? (
      <div className="filesContainer">
        {props.currentFiles.map((selectedFile: any) => (  
          <div className="filesInfo">
            <p>{localizationService.toLanguageString('custom.filename', '')}: {selectedFile?.name}</p> 
            <Button type="button" className="deleteButton" onClick={e => {deleteFile(selectedFile)}}><span className="k-icon cross"></span></Button>

          </div>
        ))}
      </div>
      ) : (
        <p>{localizationService.toLanguageString('custom.none', '')}</p>
      )} 
    </div>
  )
}

export default DisplayFiles
