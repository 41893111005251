import React, { useContext, useEffect } from 'react'
import { Container, Row, Col, Fade } from 'reactstrap'
import { UserContext } from '../../contexts/UserContextProvider'
import Spinner from '../../images/loading.gif'
import pipes from '../../images/pipes.jpg'
import avatarprofile from '../../images/icon.png'
import Attributes from '../Attributes'
import { useLocalization } from '@progress/kendo-react-intl';

const UserProfile = () => {
  const { currentUser, getSessions } = useContext<any>(UserContext);
  const localizationService = useLocalization();

  useEffect(() => {
    getSessions()
  }, [])

  const dispUserPrfile = () => {
    return (
      <>
        <Row>
          <Col lg="12" className="profile-bg py-4" style={{
            backgroundImage: "url(" + pipes + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
            <Row>
              <Col lg="3" className="px-0">
                <div className="ml-4"
                  style={{
                    width: "180px", height: "180px", border: "2px solid #ddd",
                    boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    backgroundImage: "url(" + avatarprofile + ")",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                  }}>
                </div>
              </Col>
              <Col lg="9" className="mt-lg-5 pl-1">
                <div className="mt-5 ml-3">
                  <h2 className="mt-5 user-info-top-text">
                    {currentUser.given_name} {currentUser.family_name}
                  </h2>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Fade>
          <Row className="mb-5">
            <Col lg="12 px-4 py-5" style={{ border: "2px solid #ddd" }}>
              <hr />
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.role', '')}:</h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser["custom:role"]}</h5>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.email', '')}:</h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser.email}</h5>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.lastName', '')}: </h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser.family_name}</h5>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.firstName', '')}: </h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser.given_name}</h5>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.country', '')}: </h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser.address}</h5>
                </Col>
              </Row>
              <Row className="d-flex">
                <Col lg="3">
                  <h5 className="text-info">{localizationService.toLanguageString('custom.phonenumber', '')}: </h5>
                </Col>
                <Col lg="9">
                  <h5>{currentUser.phone_number}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Fade>
      </>
    )
  }

  return (
    <Container className="">
      <Row className="">
        <Col lg="12" className="px-0 mt-3">
          <h4 className="text-info">{localizationService.toLanguageString('custom.personalInfo', '')}</h4>
          <hr />
        </Col>
      </Row>
      {!currentUser ?
        (<div className="d-flex justify-content-center">
          <img src={Spinner} alt="Loading..." width="30%" />
        </div>) :
        (dispUserPrfile())}
      {/* <Row>
        <Attributes/>
      </Row> */}

    </Container>
  )
}

export default UserProfile
