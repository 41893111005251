import { Col } from 'reactstrap'
import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../contexts/UserContextProvider'
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { SiteContext } from '../contexts/SiteContextProvider';
import { fetchCas, fetchCasSummary, fetchCaSystems, publishCas } from '../api/cas/cas';
import { useLocalization } from '@progress/kendo-react-intl';
import { STATUS } from '../constants/Status';
import { Cas } from '../api/interfaces/cas';


const PublishCAS = () => {
  const localizationService = useLocalization();

  const { authToken } = useContext<any>(UserContext);
  const [compAirSystemData, setCompAirSystemData] = useState([])
  const { sites, currentSite } = useContext<any>(SiteContext)
  const [compAirSystemId, setCompAirSystemId] = useState<any>()
  const [isPublished, setIsPublished] = useState(false);
  const [data, setData] = React.useState('');
  const [cas, setCAS] = useState<Cas>()
  const [totalCompressors, setTotalCompressors] = useState('')
  const [visible, setVisible] = React.useState(true);
  
  const [casIsBlocked, setCasIsBlocked] = useState<boolean>(false)


  const publishCAS = async () => {
    publishCas(authToken, compAirSystemId.id, currentSite.id, null)            
    .then((response) => {
      if (response && response.data && response.data.success)
        setIsPublished(response.data.success)
  })
  }

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const fetchCAS = async (id: number) => {
      fetchCas(authToken, id, currentSite.id, null, (response:any) => setCAS(response.data))
  }

  const checkIfCasIsPublished = (casStatus) => {
    if(casStatus == STATUS.PUBLISHED_WAITING_FOR_APPROVAL){
      setCasIsBlocked(true)
    }else {
        setCasIsBlocked(false)
    }
  }


  const casSummary = async (id: number) =>{
    fetchCasSummary(authToken, id, currentSite.id, (d: any) => d.data.total_compressors, setTotalCompressors)
  }

  useEffect(() => {
    fetchCaSystems(authToken, currentSite.id, (d: any) => d.data.map((e: any) => ({ text: e.nickname, id: e.id, status: e.status })), setCompAirSystemData)
  }, [])

  useEffect(() => {
    if(compAirSystemData.some(item => item.text === null )) {
      setCompAirSystemData(oldData => oldData.filter(item => item.text !== null));

    }
  }, [compAirSystemData])

  return (
    <>
      {!isPublished &&
        <div className="mb-3">
          <label htmlFor="compressed-air-system" className="text-info"
            style={{ position: "relative", marginTop: "2%", zIndex: +1, backgroundColor: "rgba(255, 255, 255, 1)" }}>
            {localizationService.toLanguageString('custom.compressedAirSystem', '')} </label>&nbsp;&nbsp;
          <Col lg="12" className="px-4"> </Col>
          <DropDownList
            value={compAirSystemId}
            textField="text"
            dataItemKey="id"
            onChange={(e) => {
              setCompAirSystemId(e.target.value);
              fetchCAS(e.target.value.id);
              checkIfCasIsPublished(e.target.value.status);
              casSummary(e.target.value.id);
            }}
            data={compAirSystemData}
          />
          {compAirSystemId && !casIsBlocked && cas &&
            <div>
              <div style={{
                position: "relative", marginTop: "2%", zIndex: +1, backgroundColor: "rgba(255, 255, 255, 1)",
                width: "auto", height: "auto", border: "1px solid rgb(0, 73, 77)"
              }}>
                <div className="mt-2"
                  style={{ marginLeft: "10px", fontSize: "17px", paddingTop: "2px" }}>
                  {localizationService.toLanguageString('custom.youAreGoingToPublishCAS', '') + " " + cas.nickname + " " +   localizationService.toLanguageString('custom.withTheFollowingData', '')}:
                  {localizationService.toLanguageString('custom.totalNumberOf', '')+ " " +totalCompressors + " " + localizationService.toLanguageString('custom.compressors', '')}. </div>
                <div className="text-info"
                  style={{ marginLeft: "10px", fontSize: "17px", paddingTop: "2px" }}>
                  {localizationService.toLanguageString('custom.afterPublishingCAS', '')+ " " + cas.nickname + " " + localizationService.toLanguageString('custom.youWillNotBeAbleToModify', '')}. </div>
                <div className="text-info"
                  style={{ marginLeft: "10px", fontSize: "17px", paddingTop: "2px", marginBottom: "6px" }}>
                  {localizationService.toLanguageString('custom.enersizeExpertWillReview', '')}.
                </div>
              </div>&nbsp;&nbsp;
              <div className="k-form-buttons">
                <Button primary={true} onClick={toggleDialog}>Publish</Button>
                {!visible && <Dialog title={"Please confirm"} onClose={toggleDialog}>
                  <p style={{
                    margin: "25px",
                    textAlign: "center"
                  }}>{localizationService.toLanguageString('custom.areYouSureYouWantToPublish', '')}</p>
                  <DialogActionsBar>
                    <button className="k-button" onClick={publishCAS}>{localizationService.toLanguageString('custom.yes', '')}</button>
                    <button className="k-button" onClick={toggleDialog}>{localizationService.toLanguageString('custom.cancel', '')}</button>
                  </DialogActionsBar>
                </Dialog>}</div>
            </div>
          }
        </div>
      }
      {casIsBlocked && compAirSystemId &&
                <div>
                  <h5>{localizationService.toLanguageString('custom.theSelectedCas', '')}</h5>
                  </div>
      }
      {isPublished &&
        <div>
          <div className="text-info"
            style={{ marginTop: "40px", fontSize: "17px", paddingTop: "2px" }}>
            {localizationService.toLanguageString('custom.ThankYouYourInformation', '')}. </div>
          <div className="mb-3"
            style={{ fontSize: "17px", paddingTop: "2px" }}>
            {localizationService.toLanguageString('custom.ourExpertsWillReviewAndInformYou', '')}.</div>
        </div>
      }

    </>
  );
};

export default PublishCAS;