import React, {useEffect} from "react"
import {config} from "../Config"
import {Row, Col, Button, Container} from "reactstrap"
import { Card, CardBody, CardActions } from '@progress/kendo-react-layout';
import moodle from "../images/moodle.png";
import logoWhite from "../images/enersize_white.png"
import pipes from './../images/Picture1.jpg';
import { useLocalization } from '@progress/kendo-react-intl';

export const SigninCognito = (profile: any) => { 
      const localizationService = useLocalization();

 
    const signedIn = !!profile
   
    const signIn = () => {
        const signInUri = `https://${config.authDomain}/login?client_id=${config.clientId}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${config.redirectUri}`
        window.location.href = signInUri
    }  
    const goToLeaqs = () => {
        /* TODO: add final link of signin in Leaqs */
    }  
    return (
        
        <div className="servicesDiv">
            <img className="splashImageBackground" src={pipes} alt="vision" />;
            <Card className="signinCard" style={{
                width: 800,  marginLeft: "auto", marginRight: "auto", height: 200, position: "absolute",
                backgroundColor: "lightgray", top: 0, marginTop: 300,
            }}>
                <CardBody>
                    <p style={{ textAlign: "center",fontSize: "larger", }}>
                        Choose your service to sign in:
                    </p>
                </CardBody>
                <CardActions layout='center' style={{ alignContent: "stretch", justifyContent: "space-evenly" }}>
                    {signedIn &&
                    <>
                        <Row className={"mt-2"}>
                            <Col>
                                <Button style={{ backgroundColor: "#008c95",     paddingRight: 100, display: "flex", alignItems: "center" }} active onClick={signIn}>
                                    <img style ={{ width: 55, marginRight: 45, }} src={logoWhite} />
                                    {localizationService.toLanguageString('custom.start', '')}
                                </Button>
                            </Col>
                        </Row>
                    <Row className={"mt-2"}>
                        <Col>
                            <Button style={{ backgroundColor: "#075b5d", paddingRight: 100 , display: "flex", alignItems: "center"}} active onClick={goToLeaqs}>
                                <img style ={{ width: 55, marginRight: 45, }} src={logoWhite} />
                                {localizationService.toLanguageString('custom.leaqs', '')}
                            </Button>
                        </Col>
                    </Row>
                </>
                    }
                    <Row className={"mt-2"}>
                            <Col>
                    <Button style={{ backgroundColor: "#f98012", color: "black", display: "flex", height: "60px", alignItems: "center"}}  onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://enersize.moodlecloud.com/login/index.php';
                    }}>
                        <img style ={{ width: 70, }} src={moodle} />
                        Enersize Academy
                    </Button>
                    </Col>
                    </Row>
                </CardActions>
            </Card>

        </div>
    )
}