import React, {useState, useEffect} from 'react';

import UserContextProvider, { UserContext }  from './contexts/UserContextProvider'
import SiteContextProvider, { SiteContext }  from './contexts/SiteContextProvider'
import EventContextProvider from './contexts/EventContextProvider'
import MainWrapper from './components/MainWrapper';
import LocaleContextProvider from './contexts/LocaleContextProvider';
import { loadMessages } from "@progress/kendo-react-intl";
import { esMessages } from "./languages/es";
import { enMessages } from "./languages/en";
import { svMessages } from "./languages/sv";
/**
* Main Component.
* All Context-Providers declared here. 
*/
const App = () => {
 
loadMessages(esMessages, "es-ES"); 
loadMessages(enMessages, "en-US"); 
loadMessages(svMessages, "sv"); 
localStorage.setItem("refreshingTokenSemaphore", "false");
  return (
    <div className="App">
      <EventContextProvider>
        <UserContextProvider>
          <SiteContextProvider>
            <LocaleContextProvider>
              <MainWrapper />
            </LocaleContextProvider>
          </SiteContextProvider>
        </UserContextProvider>
      </EventContextProvider>
    </div>
  );
}

export default App;
