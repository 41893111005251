import React, { createContext, useEffect, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from '../configs/UserPool'
import { config } from "../Config"
import jwtDecode from "jwt-decode"


// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const UserContext = createContext();

const UserContextProvider = (props: any) => {
  const [userStatus, setUserStatus] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  const [authToken, setAuthToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [userGroups, setUserGroups] = useState('');

  const [failureMessage, setFailureMessage] = useState('');

  const [userDetail, setUserDetail] = useState();

  const [dispUserDetail, setDispUserDetail] = useState(false);
  const dispUser = () => setDispUserDetail(!dispUserDetail);

  const getSessions = async () => {
    await new Promise((resolve, reject) => {
      try {
        const user = Pool.getCurrentUser();
        if (user) {
          user.getSession(async (err: any, session: any) => {
            if (err) {
              reject();
            } else {
              const group = session.getIdToken().payload['cognito:groups'];
              const email = session.getIdToken().payload['email'];

              setUserGroups(group);

              const attributes = await new Promise((resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results = {};
                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
                    setCurrentUser(results);
                    resolve(results);
                  }
                });
              });
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'CognitoUser' is not assignable t... Remove this comment to see the full error message
              setUserInfo(user);
              const token = session.getIdToken().getJwtToken()
              setAuthToken(token)
              resolve({
                user,
                headers: {
                  'x-api-key': attributes['custom:role'],
                  Authorization: token
                },
                ...session,
                // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                ...attributes
              });
              setUserStatus(true);
            }
          });
        }
      } catch (e) {
        throw (e);
      }
    });
  }

  
  /**
  * Listener attached to RefreshToken in order to update the context with the value in the local storage
  */ 
  window.addEventListener("storage", e => { 
    for (const key in localStorage) { 
      if (key.includes("idToken")){
        setAuthToken(localStorage.getItem(key))
      }  
    }
  });

  const authenticateCognito = (code: any) => {


    const params = new URLSearchParams()
    params.append("grant_type", "authorization_code")
    params.append("client_id", config.clientId)
    params.append("code", code)
    params.append("redirect_uri", config.redirectUri)

    const req = {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: params,
    }
    return fetch(`https://${config.authDomain}/oauth2/token`, req)
      .then(response => response.json())
      .then(data => {
        setUserStatus(true);
        setAccessToken(data);
      })
  }

  const authenticate = async (Username: any, Password: any) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          setUserStatus(true);
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'CognitoUserSession' is not assig... Remove this comment to see the full error message
          setAccessToken(data);
          resolve(data);
        },
        onFailure: err => {
          setFailureMessage(err);
          console.error('On Failure: ', err);
          reject(err);
        },
        newPasswordRequired: data => {
          resolve(data);
        }
      });
    })
  }

  const logout = () => {
    //clearAccessToken()
    const user = Pool.getCurrentUser();
    if (user) {
      setUserStatus(false);
      localStorage.clear();
      window.location.href = `https://${config.authDomain}/logout?client_id=${config.clientId}&logout_uri=${config.signOutUri}`
      //user.signOut(); 
    }
    window.location.href = "/"
  }

  const getAccessToken = () => {
    const tokenRaw = localStorage.getItem("token")
    if (tokenRaw && tokenRaw.includes("invalid")) {
      localStorage.clear();
      return ""
    }
    if (tokenRaw) {
      let token = JSON.parse(tokenRaw)
      let profile = jwtDecode(token.id_token)
      return profile;
    }
  };


  useEffect(() => {
    getSessions()
  }, [])

  const values = {
    authenticate,
    authenticate2: authenticateCognito,
    logout,
    getSessions,
    userStatus,
    currentUser,
    authToken,
    userInfo,
    accessToken,
    failureMessage,
    dispUser,
    dispUserDetail,
    setUserDetail,
    userDetail,
    userGroups,
    getAccessToken
  }



  return (
    <UserContext.Provider value={values}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
