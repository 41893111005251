import * as React from "react";


/**
 * Helper method to work with My CAS Grid component
 * It handles the action buttons
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */


export const MyCommandCell = (props: any) => {
    const {
        dataItem
    } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;
    return inEdit ? <td className="k-command-cell">
        <button className="k-button k-grid-save-command" onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}>
            {isNewItem ? <span className="k-icon k-i-check"></span> : <span className="k-icon k-i-check"></span>}
        </button>
        <button className="k-button k-grid-cancel-command" onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)}>
            {isNewItem ? <span className="k-icon k-i-cancel"></span> : <span className="k-icon k-i-cancel"></span>}
        </button>
    </td> : <td className="k-command-cell">
        <button className="k-primary k-button k-grid-edit-command" onClick={() => props.edit(dataItem)}>
            <span className="k-icon k-i-edit"></span>
        </button>
        <button className="k-button k-grid-remove-command" onClick={() => props.remove(dataItem)}>
            <span className="k-icon k-i-delete"></span>
        </button>
    </td>;
};