import React, {useContext, useState, useEffect} from 'react'
import { EventContext } from '../../contexts/EventContextProvider'
import { UserContext } from '../../contexts/UserContextProvider'
import { Row, Col, Fade, Input } from 'reactstrap';
import Spinner from '../../images/loading.gif'
import APIURL from '../../configs/APIConfig';
import axios from 'axios';

const EditEvent = () => {
  //ts-migrate FIXME: investigate better type for useContext not any
  const {authToken, currentUser} = useContext<any>(UserContext);
  const {eventDetail, dispEventToEdit} = useContext<any>(EventContext); 

  const [propertyName, setPropertyName] = useState([]);  
  
  const [newCompModel, setNewCompModel] = useState('')
  const [newManufacturer, setNewManufacturer] = useState('')  
  const [newCompType, setNewCompType] = useState('')
  const [newAirCooled, setNewAirCooled] = useState('')

  //FETCH DATA WITH AUTH TOKEN
  const authAxios = axios.create({
    baseURL: APIURL.url,
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })

  /**
  * States to store Compressor data after fetch
  */
  const [compressors, setCompressors] = useState(null)
  const [compressor, setCompressor] = useState(null)
  const [compTypes, setCompTypes] = useState(null)

  const fetchComressors = async () => {
    try{
    const result = await authAxios.get(`/compressors`)
      setCompressors(result.data)
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  const fetchManufacturers = async () => {
    try{
    const result = await authAxios.get(`/manufacturers`)
      setCompressor(result.data)
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  const fetchCompTypes = async () => {
    try{
    const result = await authAxios.get(`/comptypes`)
      setCompTypes(result.data)
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  const searchCompByModel = async (qstring: any) => {
    let allCategories = await fetch(`${APIURL.url}/searchcomp?manufact=${qstring}`)
    allCategories= await allCategories.json();
    // ts-migrate(2345) FIXME: Argument of type 'Response' is not assignable to p... Remove this comment to see the full error message
    setCompressor(allCategories)
    console.log(compressor);
  }

  useEffect(()=>{
    fetchComressors()   
    fetchManufacturers()    
    fetchCompTypes()
  }, [])

  useEffect(()=>{
     searchCompByModel(newCompModel)
  }, [])

  const getKeyNames = () => {
    let rawData = JSON.parse(eventDetail[0].comp_raw_data)
    let compressor = rawData.compressor
    let dynamic_properties = rawData.dynamic_properties
    let fixed_properties = rawData.fixed_properties
    let motor = rawData.motor

    for(let key in compressor){
      //  ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
      propertyName.push({name:key})
    }
    for(let key in fixed_properties){
      // ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
      propertyName.push({name:key})
    }
    for(let key in dynamic_properties){
      //ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
      propertyName.push({name:key})
    }
    for(let key in motor){
      if (motor[key] !== null) {
         // ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
         propertyName.push(key);
      }     
    }             
  }

  useEffect(()=>{
    getKeyNames();
  }, [propertyName])

  /**
  * Print the email of the Event's creator.
  * Email is saved in the database as 'customer_id'.
  */
  const dispCreatorEmail= () =>{
    return(
      <>
      <div className="d-flex">
        <h6 className="ml-1 pl-2 pt-2 text-info" key={"key"}>
          Created by: 
        </h6>
        <h6 className="ml-1 pl-2 pt-2 text-secondary">
          {eventDetail[0].customer_id}
        </h6>
      </div>
      <hr/>
      </>
    )
  }

  /**
  * Print the email of the Current User.
  * The current user is the modifier. 
  */
  const dispEditorEmail= () =>{
    return(
      <>
      <div className="d-flex">
        <h6 className="ml-1 pl-2 pt-2 text-info" key={"key"}>
          Verifying by: 
        </h6>
        <h6 className="ml-1 pl-2 pt-2 text-secondary">
          {currentUser.email}
        </h6>
      </div>
      <hr/>
      </>
    )
  }

  /**
  * Display a particular Event Data
  * To print data from the large string, 'JSON.parse' method is used. 
  */
  const dispEventData= () => {
    let rawData = JSON.parse(eventDetail[0].comp_raw_data)
    return (
      <div className="ml-1 mx-0 mt-2" key={"mridu"}>  
        {(()=>{
          let list = [];
          let data = rawData.compressor
          for(let x in data){          
            list.push(
              <Row className="py-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="5">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="7">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.fixed_properties
          for(let x in data){          
            list.push(
              <Row className="py-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="5">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="7">
                  <h6>{data[x]}</h6>
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.dynamic_properties
          for(let x in data){          
            list.push(
              <Row className="py-2 pl-2 mt-2 comp-detail-row data-row" key={"key"+ x}>
                <Col lg="5">
                  <h6 className="text-info text-capitalize">{x.split('_').join(' ')}</h6>
                </Col> 
                <Col lg="7">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
        {(()=>{
          let list = [];
          let data = rawData.motor
          for(let x in data){          
            list.push(
              <Row className="py-2 pl-2 mt-2 ecomp-detail-row data-row" key={"key"+ x}>
                <Col lg="5">
                  <h6 className="text-info text-capitalize">{data[x] !== null && x.split('_').join(' ')}</h6> 
                </Col> 
                <Col lg="7">
                  {data[x]}
                </Col>
              </Row>
            )
          }            
          return list;
        })()}
      </div>
    )        
  }

  /**
  * Display data to modify.
  * 
  */
  const dispDataToEdit = () =>{
    return <>
    {(()=>{

      let list = []
      for(let i=0; i<propertyName.length; i++){
        list.push(
          <Row className="comp-detail-row mt-2 py-1 px-2">
            <Col lg="5" className="pt-2">
              <h6 className="text-capitalize text-info">{(propertyName[i].name)}</h6>
            </Col>
            <Col lg="5">
              {(()=>{
                let nlist = []
                // ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                if(propertyName[i].name === 'comp_model'){
                  nlist.push(
                  <h6 style={{height: "20px"}}>                     
                    <Input list="select" name="select" style={{height: "32px", marginBottom: "-35px"}}
                      onChange={e=>setNewCompModel(e.target.value)} />
                    <datalist className="form-control" id="select"> 
                      {(() => {
                        if(compressors !== null){
                          //  ts-migrate(2531) FIXME: Object is possibly 'null'.
                          return compressors.map((subject: any, i: any) => {
                            return (
                              <option key={'sub' + subject.id + i} 
                                value={subject.comp_model} 
                              />
                            )
                          }); 
                        }        
                      })()}
                    </datalist>
                    </h6>
                  )
                }
                //  ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                else if(propertyName[i].name === 'manufacturer'){
                  nlist.push(
                  <>                     
                    <Input list="select2" name="select2" style={{height: "32px", marginBottom: "-35px"}}
                      onChange={e=>setNewManufacturer(e.target.value)} />
                    <datalist className="form-control" id="select2"> 
                      {(() => {
                        if(compressor !== null){
                          // ts-migrate(2531) FIXME: Object is possibly 'null'.
                          return compressor.map((subject: any, i: any) => {
                            return (
                              <option key={'sub' + subject.id + i} 
                                value={subject.manufacturer_name} 
                              />
                            )
                          }); 
                        }        
                      })()}
                    </datalist>
                    </>
                  )
                }
                // ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                else if(propertyName[i].name === 'comp_type'){
                  nlist.push(
                  <>                     
                    <Input list="select3" name="select3" style={{height: "32px", marginBottom: "-35px"}}
                      onChange={e=>setNewCompType(e.target.value)} />
                    <datalist className="form-control" id="select3"> 
                      {(() => {
                        if(compTypes !== null){
                          //ts-migrate(2531) FIXME: Object is possibly 'null'.
                          return compTypes.map((subject: any, i: any) => {
                            return (
                              <option key={'sub' + subject.id + i} 
                                value={subject.comp_type} 
                              />
                            )
                          }); 
                        }        
                      })()}
                    </datalist>
                    </>
                  )
                } 
                // ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                else if(propertyName[i].name === 'air_cooled'){
                  nlist.push(
                    <>
                    <Input type="select" name="select" className="py-0"
                      style={{height: "30px"}}
                      onChange={e=>setNewAirCooled(e.target.value)} >
                      <option value ='no'>No</option>
                      <option value ='yes'>Yes</option>
                    </Input>
                    </>
                  )              
                }
                 // ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                 else if(propertyName[i].name === 'water_cooled'){
                  nlist.push(
                    <>
                    <Input type="select" name="select" className="py-0"
                      style={{height: "30px"}}
                      onChange={e=>setNewCompType(e.target.value)} >
                      <option value ='no'>No</option>
                      <option value ='yes'>Yes</option>
                    </Input>
                    </>
                  )              
                }
                // ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                else if(propertyName[i].name === 'oil_injected' ){
                  nlist.push(
                    <>
                    <Input type="select" name="select" className="py-0"
                      style={{height: "30px"}}
                      onChange={e=>setNewCompType(e.target.value)} >
                      <option value ='no'>No</option>
                      <option value ='yes'>Yes</option>
                    </Input>
                    </>
                  )              
                }
                // ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
                else if(propertyName[i].name === 'oil_free'){
                  nlist.push(
                    <>
                    <Input type="select" name="select" className="py-0"
                      style={{height: "30px"}}
                      onChange={e=>setNewCompType(e.target.value)} >
                      <option value ='no'>No</option>
                      <option value ='yes'>Yes</option>
                    </Input>
                    </>
                  )              
                }
                else {               
                  return <Input type="text" style={{height: "36px"}}/>             
                }
                return nlist;
              
              })()}
            </Col>
          </Row>
        )
      }
      return list;
    })()}
    </>;
  }

  return (
    <>
      <Row>
        <Col lg="12" className="event-detail-top-bg text-white py-1 text-center">
          <h3 className="mb-0 event-detail-top-text">{ eventDetail && eventDetail[0].action_desc } </h3>
        </Col>
      </Row>
      {!eventDetail?(
        <div className="d-flex justify-content-center">
          <img src={Spinner} alt="Loading..." width="30%"/>
        </div>):(
          <Fade>
            <Row className="mt-3">
              <Col lg="6">
                {dispCreatorEmail()}
                {dispEventData()}
              </Col>
              <Col lg="6">
                {dispEditorEmail()}
                {dispDataToEdit()}
              </Col>
            </Row>
          </Fade>
        )} 

      <hr className="mt-1"/>
      <Row className="d-flex justify-content-center mb-4">
        <Col lg="6" className="mt-1 d-flex justify-content-center px-0">
          <Col lg="4" className="px-0 mr-2">
            <button className="btn btn-sm btn-outline-info btn-block" 
              onClick={dispEventToEdit}>BACK
            </button>
          </Col>                      
          <Col lg="4" className="px-0 ml-2">
            <button disabled className="btn btn-sm btn-outline-success btn-block">
              SAVE
            </button>
          </Col>            
        </Col>
      </Row>
    </>
  )
}

export default EditEvent
