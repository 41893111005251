import React, { useContext, useEffect, useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { UserContext } from '../../contexts/UserContextProvider'
import APIURL from '../../configs/APIConfig'
import axios from 'axios';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import AddCompProperties from "./AddCompPropertiesDialog";
import { addBrand, fetchBrands } from '../../api/brands/brands';
import { fetchCompressorTypes } from '../../api/compressor_types.ts/compressor_types';
import { fetchCaSystems } from '../../api/cas/cas';
import { SiteContext } from '../../contexts/SiteContextProvider';
import { fetchCompressorCoolings } from '../../api/compressor_coolings/compressor_coolings';
import { updateSiteCompressor, addSiteCompressor, fetchAllSiteCompressors } from '../../api/compressors_site/compressors_site';
import { SiteCompressor } from '../../api/interfaces/site_compressor';
import { CompressorFiles } from '../../api/interfaces/compressor_files';
import { fetchCompressorRegulations } from '../../api/compressor_regulations/compressor_regulations';
import UploadFiles from '../uploadfiles/UploadFiles';
import { tryParseFloat, tryParseInt } from '../../common/castHelpers';
import { fetchTemplateCompressors } from '../../api/compressors_template/compressors_template';
import { CompressorType } from '../../api/interfaces/compressor_type';
import { CompressorCooling } from '../../api/interfaces/compressor_cooling';
import { Unit } from '../../api/interfaces/unit';
import { CompressorRegulation } from '../../api/interfaces/compressor_regulation';
import { Brand } from '../../api/interfaces/brands';
import { FileCompressor } from '../../api/interfaces/file_compressor';
import { listCompFiles } from '../../api/compressor_site_files/compressor_site_files';
import DisplayFiles from '../uploadfiles/DisplayFiles';
import { Stepper } from '@progress/kendo-react-layout';
import { STATUS } from "../../constants/Status"
import { fetchUnits } from '../../api/units/units';
//import { UNITS } from '../../constants/Units';
import { useLocalization } from '@progress/kendo-react-intl';

import { Property } from '../../api/enums/property';
import {
  Notification, NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { SelectedCasContext } from '../../contexts/SelectedCasContextProvider';

/**
* Component to add new Compressor.
*/
const AddCompData = () => {
  const localizationService = useLocalization();
  const { authToken } = useContext<any>(UserContext);
  const { sites, currentSite } = useContext<any>(SiteContext)

  const [compNickname, setCompNickname] = useState<string>('')
  const [compSerialNo, setCompSerialNo] = useState('')
  const [compCoolings, setCompCoolings] = useState<[CompressorCooling]>()
  const [compressorModelSelectedCooling, setCompressorModelSelectedCooling] = useState<CompressorCooling>()
  const [compTypes, setCompTypes] = useState<[CompressorType]>()
  const [compressorType, setCompressorType] = useState<CompressorType>()
  const [compRegulations, setCompRegulations] = useState<[CompressorRegulation]>()
  const [compressorModelSelectedRegulation, setCompressorModelSelectedRegulation] = useState<CompressorRegulation>()
  const [compYear, setCompYear] = useState<string>('')
  const [message, setMessage] = useState('')
  const [idCreatedCompressor, setIdCreatedCompressor] = useState<number>(-1)
  const [compAirSystemData, setCompAirSystemData] = useState([])
  const [compAirSystem, setcompAirSystem] = useState({ id: -1, text: "" })
  const [compId, setCompId] = useState<number>()
  const [compressorComment, setCompressorComment] = useState('')
  const [currentFiles, setCurrentFiles] = React.useState<Array<FileCompressor>>([]);

  /**Compressor Model Data */
  const [compBrandData, setCompBrandData] = useState<[Brand]>()
  const [compBrand, setCompBrand] = useState<Brand>()
  const [compModelData, setCompModelData] = useState([])
  const [compModelText, setCompModelText] = useState('')
  const [model, setModel] = useState('')
  const [compressorsModelData, setCompressorsModelData] = useState([])
  const [compressorModelSelected, setCompressorModelSelected] = useState([])
  const [modelDropDownDisable, setModelDropDownDisable] = useState(true)

  const [compressorModelSelectedPressure, setCompressorModelSelectedPressure] = useState('')


  const [compressorModelSelectedPowerSupply, setCompressorModelSelectedPowerSupply] = useState('')
  const [compressorModelSelectedMotorPower, setCompressorModelSelectedMotorPower] = useState('')
  const [compressorModelSelectedOutput, setCompressorModelSelectedOutput] = useState('')
  const [compressorModelSelectedId, setCompressorModelSelectedId] = useState('')

  const [visible, setVisible] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { setSelectedCas } = useContext<any>(SelectedCasContext);

  const [compFrequency, setCompFrequency] = useState<number>()
  const [powerFactorLoaded, setPowerFactorLoaded] = useState<number>()
  const [powerFactorOffload, setPowerFactorOffload] = useState<number>()
  const [totalPowerOffload, setTotalPowerOffload] = useState<number>()
  const [totalPowerLoaded, setTotalPowerLoaded] = useState<number>()
  const [controlSystem, setControlSystem] = useState<boolean>()


  /******************* Steps interface ******************* */
  interface stepsInterface {
    isValid: boolean | undefined,
    label: string
  }
  const [step, setStep] = React.useState<number>(0);
  const [steps, setSteps] = React.useState<Array<stepsInterface>>([
    { label: '', isValid: undefined },
    { label: '', isValid: undefined }
  ]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;

  const onStepSubmit =
    (event: any) => {
      console.log("onStepSubmit")
      if (step === 0 && !firstStepIsValid()) {
        return;
      }
      else if (step === 1 && !secondStepIsValid()) {
        setValidationError(true)
        return;
      }
      else {
        setValidationError(false)
        const { isValid, values } = event;
        const currentSteps = steps.map((currentStep: stepsInterface, index: number) => ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid
        }));

        setSteps(currentSteps);

        if (!isValid) {
          return;
        }

        setStep(() => Math.min(step + 1, lastStepIndex));
        onSubmit(event)
      }
    }



  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );


  let newCompressorFilesForApi: CompressorFiles = {
    id: null,
    site_compressor_id: idCreatedCompressor,
    name: fileName,
    site_id: currentSite?.id
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };


  const year = 2001;
  const years = Array.from(new Array(22), (val, index) => (year + index));


  const fetchCompModelData = async () => {
    try {
      fetchTemplateCompressors(authToken, currentSite.id, (d: any) => d.data, setCompressorsModelData)
      const uniqueItemsBrand = uniqueElementBrand(compressorsModelData);
      setCompBrandData(uniqueItemsBrand);
    } catch (err: any) {
      console.log("AXIOS ERROR", err.message);
    }
  }

  const fetchBrandsData = async () => {
    try {
      fetchBrands(authToken, (d: any) => d.data.map((e: any) => ({ name: e.name, id: e.id })), setCompBrandData)
    } catch (err: any) {
      console.log("AXIOS ERROR", err.message);
    }
  }


  const uniqueElementBrand = (arr: any) => {
    var resultBrand = arr.filter(function (a: any) {
      var key = a.fk_brand_id + '|' + a.brand_name;
      // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
      if (!this[key]) {
        // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
        this[key] = true;
        return true;
      }
    }, Object.create(null));
    const newresult = resultBrand.map(e => ({ text: e.brand_name, id: e.fk_brand_id }));
    return newresult
  }

  const uniqueElementModel = (arr) => {
    var resultModel = arr.filter(function (a: any) {
      var key = a.model;
      // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
      if (key != undefined && key != null && !this[key]) {
        // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
        this[key] = true;
        return true;
      }
    }, Object.create(null));
    const newresult = resultModel.map(e => (e.full_model));
    return newresult
  }

  useEffect(() => {
    if (currentSite) {
      fetchCaSystems(authToken, currentSite.id, (d: any) => d.data.map((e: any) => ({ text: e.nickname, id: e.id, status: e.status })), setCompAirSystemData);
    }
  }, [currentSite])

  useEffect(() => {
    fetchCompressorRegulations(authToken, (d: any) => d.data.map((e: any) => ({ name: e.name, id: e.id })), setCompRegulations);
    fetchCompressorCoolings(authToken, (d: any) => d.data.map((e: any) => ({ name: e.name, id: e.id })), setCompCoolings);
    fetchCompModelData();
    fetchCompressorTypes(authToken, (d: any) => d.data.map((e: any) => ({ name: e.name, id: e.id })), setCompTypes);
    fetchBrandsData();

  }, [])

  useEffect(() => {
    if (currentSite) {
      fetchCompModelData()
      listCompFiles(authToken, idCreatedCompressor, currentSite.id, (d: any) => d.data.map((e: any) => e), setCurrentFiles)
    }
  }, [idCreatedCompressor, currentSite]);

  const finishUploadCallback = () => {
    listCompFiles(authToken, idCreatedCompressor, currentSite.id, (d: any) => d.data.map((e: any) => e), setCurrentFiles)
  };


  /****************************  Units *************************************/
  const [unitsPower, setUnitsPower] = React.useState<[Unit]>()
  const [unitsPressure, setUnitsPressure] = React.useState<[Unit]>()
  const [unitsMotorPower, setMotorPower] = React.useState<[Unit]>()
  const [unitsOutput, setUnitsOutput] = React.useState<[Unit]>()
  const [unitsPressureSelected, setUnitsPressureSelected] = React.useState<Unit>()
  const [unitsPowerSelected, setUnitsPowerSelected] = React.useState<Unit>()
  const [unitsMotorPowerSelected, setUnitsMotorPowerSelected] = React.useState<Unit>()
  const [unitsOutputSelected, setUnitsOutputSelected] = React.useState<Unit>()


  useEffect(() => {
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsPower, Property.GENERIC_POWER_SUPPLY);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsPressure, Property.GENERIC_PRESSURE);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setMotorPower, Property.GENERIC_MOTOR_POWER);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsOutput, Property.GENERIC_OUTPUT);

  }, [])

  /* preparing data object for new api */
  const newdata: SiteCompressor = {
    id: idCreatedCompressor,
    nickname: compNickname || null,
    output: compressorModelSelectedOutput && tryParseFloat(compressorModelSelectedOutput) || null,
    pressure: compressorModelSelectedPressure && tryParseFloat(compressorModelSelectedPressure) || null,
    motor_power: compressorModelSelectedMotorPower && tryParseFloat(compressorModelSelectedMotorPower) || null,
    serial_number: compSerialNo || null,
    year: compYear || null,
    cooling: compressorModelSelectedCooling?.name || null,
    type: compressorType?.name || null,
    regulation: compressorModelSelectedRegulation?.name || null,
    power_supply: compressorModelSelectedPowerSupply && tryParseInt(compressorModelSelectedPowerSupply) || null,
    brand: compBrand?.name || null,
    model: model || null,
    cas_id: compAirSystem?.id || null,
    type_id: compressorType?.id || null,
    regulation_id: compressorModelSelectedRegulation?.id || null,
    brand_id: compBrand?.id || null,
    cooling_id: compressorModelSelectedCooling?.id || null,
    template_compressor_id: tryParseInt(compressorModelSelectedId) || null,
    site_id: currentSite?.id,
    file_number: currentFiles.length || null,
    comment: compressorComment || null,
    status: STATUS.NOT_PUBLISHED,
    output_unit_id: unitsOutputSelected?.id || null,
    motor_power_unit_id: unitsMotorPowerSelected?.id || null,
    power_supply_unit_id: unitsPowerSelected?.id || null,
    pressure_unit_id: unitsPressureSelected?.id || null,
    output_unit_symbol: unitsOutputSelected?.symbol || null,
    pressure_unit_symbol: unitsPressureSelected?.symbol || null,
    motor_power_unit_symbol: unitsMotorPowerSelected?.symbol || null,
    power_supply_unit_symbol: unitsPowerSelected?.symbol || null,
    frequency: compFrequency || null,
    power_factor_loaded: powerFactorLoaded || null,
    power_factor_offload: powerFactorOffload || null,
    total_power_loaded: totalPowerLoaded || null,
    total_power_offload: totalPowerOffload || null,
    control_system: controlSystem || null
  };



  /**
  * Function to go to the  page. 
  */
  const onSubmit = async (e: any) => {
    postCompressorSave();
    toggleDialog();
  }

  const Window = async (e: any) => {
    toggleDialog()
    let element: HTMLElement = document.getElementsByClassName("display-compressor-btn")[0] as HTMLElement;
    element.click();
  }

  /**
  * Function to save Compressor Data. 
  */
  const postCompressorSave = async () => {
    if (isSaving) {
      return;
    } else {
      setIsSaving(true);
    }
    const isEmpty = Object.values(newdata).every(x => x === null || x === '');
    if (isEmpty) {
      return;
    }
    if (idCreatedCompressor !== -1) {
      postCompressorUpdate();
      return;
    } else {

      const dataWithStatus: SiteCompressor = { ...newdata, id: null, status: STATUS.NOT_PUBLISHED, }

      try {
        addSiteCompressor(authToken, currentSite.id, dataWithStatus)
          .then((response) => {
            setIsSaving(false);
            if (response?.data) {
              setIdCreatedCompressor(response.data.itemid)
            }
          })
      } catch (err: any) {
        console.log("AXIOS ERROR", err.message);
      }
    }
  }
  /**
  * Function to save Compressor Data. 
  */
  const postCompressorUpdate = async () => {

    const dataWithStatus: SiteCompressor = { ...newdata, status: STATUS.NOT_PUBLISHED }

    try {
      updateSiteCompressor(authToken, currentSite.id, idCreatedCompressor, dataWithStatus)
        .then((response) => {
          setIsSaving(false);
          if (response && response.data) {
            setMessage(response.data)
            console.log(message);
          }
        })
    } catch (err: any) {
      console.log("AXIOS ERROR", err.message);
    }
  }

  const insertBrandApiCall = async (compNewBrandName: string) => {
    try {

      const newBrand: Brand = { id: null, name: compNewBrandName, status: STATUS.NOT_PUBLISHED }
      addBrand(authToken, newBrand)
        .then((response) => {
          const { data } = response
          if (response.status !== 200) {
            //show error
          } else {
            fetchBrandsData();
          }
        })

    } catch (err: any) {
      console.log("AXIOS ERROR", err.message);
    }
  }

  const setNewModelName = (compNewModelName: string) => {
    setCompModelText(compNewModelName);
    setModel(compNewModelName);
    setCompressorModelSelected([]);
    setCompressorModelSelectedPressure('');
    setUnitsPressureSelected(null);
    setCompressorModelSelectedRegulation(null);
    setCompressorModelSelectedCooling(null);
    setCompressorModelSelectedPowerSupply('');
    setUnitsPowerSelected(null);
    setCompressorModelSelectedMotorPower('');
    setUnitsMotorPowerSelected(null);
    setCompressorModelSelectedOutput('');
    setUnitsOutputSelected(null);
    setCompressorModelSelectedId('');
    setCompressorType(null);
  }


  const updateCompNickname = (value: any) => {
    setCompNickname(value);
  };

  const updateCompSerialNo = (value: any) => {
    setCompSerialNo(value);
  };

  useEffect(() => {
    if (compAirSystemData.some(item => item.text === null)) {
      setCompAirSystemData(oldData => oldData.filter(item => item.text !== null));

    }
  }, [compAirSystemData])

  /***************** functions to handle template popup *************************************/
  const [brandDialogVisible, setVisibleBrand] = React.useState(false);
  const [modelDialogVisible, setModelDialogVisible] = React.useState(false);
  const [showModelInput, setShowModelInput] = React.useState(false);
  const [newCompModelInput, setNewCompModelInput] = React.useState('');


  const toggleDialogBrand = () => {
    setVisibleBrand((visibleBrand) => !visibleBrand);

  };

  const toggleDialogModel = () => {
    setModelDialogVisible(visibleModel => !visibleModel);
  }

  const toggleModelInput = () => {
    if (!showModelInput) {
      setNewCompModelInput('');
      setCompressorModelSelectedPressure('');
      setCompressorModelSelectedRegulation(undefined);
      setCompressorModelSelectedPowerSupply('');
      setCompressorModelSelectedMotorPower('');
      setCompressorModelSelectedOutput('');
      setCompressorModelSelectedCooling(undefined);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setCompressorModelSelected(false);
    }
    setShowModelInput((oldState) => !oldState);
  }

  const defaultItem = { symbol: "Select unit ..." };
  const integerValidationMessage = "Only numbers with no decimals"
  const floatValidationMessage = "Only numbers or decimals with dot"

  const [validationError, setValidationError] = React.useState<Boolean>(false);


  const firstStepIsValid = () => {
    const validFields =
      [compAirSystem,
        compModelText,
        compBrand].filter(field => field !== undefined && (field !== "" || checkIfIdIsValidIfExists(field))).length;

    return validFields === 3;
  }

  const checkIfIdIsValidIfExists = (element: any) => {
    if (element["id"] && element.id !== -1) return true;
    return false;
  }

  const secondStepIsValid = () => {
    const validFieldsSecondstep =
      [unitsMotorPowerSelected,
        unitsPressureSelected, unitsOutputSelected,
        unitsPowerSelected].filter(field => field !== undefined && field !== null && field.symbol !== "Select unit ...").length;

    return validFieldsSecondstep === 4;
  }

  useEffect(() => {
    if (validationError) {
      setTimeout(() => setValidationError(false), 5000);
    }
  }, [validationError])

  const [casIsBlocked, setCasIsBlocked] = useState<boolean>(false)

  const checkIfBlockCAS = (cas_status: any) => {
    if (cas_status == STATUS.PUBLISHED_WAITING_FOR_APPROVAL) {
      setCasIsBlocked(true)
    } else {
      setCasIsBlocked(false)
    }
  }

  return (
    <div >
      <Stepper value={step} items={steps} style={{ marginBottom: "25px" }} />
      <Form onSubmit={onSubmit} onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650
            }}
          >
            {step === 0 ? (
              <>
                <div className="mb-3">
                  <label htmlFor="compressed-air-system" className="text-info, label-add-compressors" style={{ marginRight: "10px" }}> {localizationService.toLanguageString('custom.compressedAirSystem', '')}</label>
                  <DropDownList
                    required={true}
                    value={compAirSystem}
                    textField="text"
                    dataItemKey="id"
                    onChange={(e) => {
                      setcompAirSystem(e.target.value);
                      setSelectedCas(e.target.value.id);
                      checkIfBlockCAS(e.target.value.status)
                    }}
                    data={compAirSystemData}
                  />
                  {casIsBlocked && <h5 style={{ color: "red", marginTop: "15px" }}>The CAS selected is being reviewed. You can't add more compressors. </h5>}
                </div>
                {!casIsBlocked &&
                  <>
                    <div className="mb-3" >
                      <label htmlFor="brand-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.brand', '')}</label>&nbsp;&nbsp;
                      <DropDownList
                        value={compBrand}
                        textField="name"
                        dataItemKey="id"
                        onChange={(e) => {
                          setCompBrand(e.target.value);
                          const brandIdModels = compressorsModelData.filter(function (elem) {
                            return (elem.brand_id == e.target.value.id)
                          });
                          const uniqueItemsModel = uniqueElementModel(brandIdModels);
                          setCompModelData(uniqueItemsModel)
                          setModelDropDownDisable(false);
                          setCompModelText('');
                          setModel('');
                          Object.keys(compressorModelSelected).forEach((i) => compressorModelSelected[i] = null);
                        }}
                        data={compBrandData}
                      />
                      <Button primary={true} style={{ marginLeft: 15 }} onClick={toggleDialogBrand} >+ {localizationService.toLanguageString('custom.AddNewBrand', '')} </Button>
                      {(brandDialogVisible) && (<AddCompProperties onInsert={insertBrandApiCall} dialogTitle={localizationService.toLanguageString('custom.newCompressorBrand', '')} fetchBrands={fetchBrandsData} fetchCompModelData={fetchCompModelData} toggleDialog={toggleDialogBrand} label={localizationService.toLanguageString('custom.newBrand', '')} />)}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="model-name" className="text-info, label-add-compressors"> {localizationService.toLanguageString('custom.model', '')} </label>&nbsp;&nbsp;

                      <DropDownList
                        disabled={modelDropDownDisable}
                        data={compModelData}
                        onChange={(e) => {
                          setCompModelText(e.target.value);
                          const compressorModelSelected = compressorsModelData.filter(function (elem) {
                            //return false for the element that matches both the name and the id
                            return (elem.brand_id == compBrand.id && elem.full_model == e.target.value)
                          });
                          if (compressorModelSelected.length > 0) {
                            setModel(compressorModelSelected[0].model)
                            setCompressorModelSelected(compressorModelSelected[0]);
                            setCompressorModelSelectedPressure(compressorModelSelected[0].pressure);
                            setUnitsPressureSelected(unitsPressure.find((e) => e.id == compressorModelSelected[0].pressure_unit_id));
                            setCompressorModelSelectedRegulation({ id: compressorModelSelected[0].regulation_id, name: compressorModelSelected[0].regulation });
                            setCompressorModelSelectedCooling({ id: compressorModelSelected[0].cooling_id, name: compressorModelSelected[0].cooling });
                            setCompressorModelSelectedPowerSupply(compressorModelSelected[0].power_supply);
                            setUnitsPowerSelected(unitsPower.find((e) => e.id == compressorModelSelected[0].power_supply_unit_id));
                            setCompressorModelSelectedMotorPower(compressorModelSelected[0].motor_power);
                            setUnitsMotorPowerSelected(unitsMotorPower.find((e) => e.id == compressorModelSelected[0].motor_power_unit_id));
                            setCompressorModelSelectedOutput(compressorModelSelected[0].output);
                            setUnitsOutputSelected(unitsOutput.find((e) => e.id == compressorModelSelected[0].output_unit_id));
                            setCompressorModelSelectedId(compressorModelSelected[0].id);
                            setCompressorType(compTypes.find((e) => e.id == compressorModelSelected[0].type_id));
                          }
                        }
                        }
                        value={compModelText}
                      />

                      <Button primary={true} type="button" style={{ marginLeft: 15 }} onClick={toggleDialogModel} >+ {localizationService.toLanguageString('custom.AddNewModel', '')}</Button>
                      {(modelDialogVisible) && (<AddCompProperties onInsert={setNewModelName} dialogTitle={localizationService.toLanguageString('custom.newCompressorModel', '')} fetchBrands={fetchBrandsData} fetchCompModelData={fetchCompModelData} toggleDialog={toggleDialogModel} label={localizationService.toLanguageString('custom.newModel', '')} />)}
                    </div>
                  </>}
                <div>
                  {validationError && (
                    <Fade>
                      <NotificationGroup style={{
                        display: "contents"
                      }} >
                        <Notification
                          type={{ style: "error", icon: true }}
                          closable={true}
                          onClose={() => setValidationError(false)}


                        >
                          <span>{localizationService.toLanguageString('custom.AllFieldsAreRequired', '')}</span>
                        </Notification>
                      </NotificationGroup>

                    </Fade>
                  )}
                </div>
              </>


            ) : (
              <>

                <div className="mb-3">
                  <label htmlFor="regulation-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.regulation', '')}: </label>&nbsp;
                  <DropDownList
                    onBlur={postCompressorSave}
                    value={compressorModelSelectedRegulation}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => {
                      setCompressorModelSelectedRegulation(e.target.value);
                    }}
                    data={compRegulations}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="cooling-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.cooling', '')}: </label>&nbsp;
                  <DropDownList
                    onBlur={postCompressorSave}
                    value={compressorModelSelectedCooling}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => {
                      setCompressorModelSelectedCooling(e.target.value);
                    }}
                    data={compCoolings}
                  />
                </div>
                <div className="mb-3">
                  <div className='inputUnitDiv'>
                    <label htmlFor="pressure-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.pressure', '')}: </label>
                    <Input value={compressorModelSelectedPressure}
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      maxLength={5}
                      validationMessage={floatValidationMessage}
                      onBlur={postCompressorSave}
                      style={{ height: "30px", marginRight: "10px", width: "190px" }}
                      onChange={e => {
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompressorModelSelectedPressure(e.target.value)
                      }} />

                    <DropDownList
                      required={true}
                      style={{ height: "30px", width: "175px" }}
                      defaultItem={defaultItem}
                      textField='symbol'
                      value={unitsPressureSelected}
                      dataItemKey='id'
                      onChange={(e) => {
                        setUnitsPressureSelected(e.target.value);
                      }}
                      data={unitsPressure}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="powersuply-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.powerSupplyVoltage', '')}: </label>&nbsp;
                  <div className='inputUnitDiv'>
                    <Input value={compressorModelSelectedPowerSupply}
                      validationMessage={integerValidationMessage}
                      pattern={"^[0-9]*[1-9][0-9]*$"}
                      style={{ height: "30px", marginRight: "10px", width: "190px" }}
                      onBlur={postCompressorSave}
                      onChange={e => {
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompressorModelSelectedPowerSupply(e.target.value)
                      }} />
                    <DropDownList
                      required={true}
                      style={{ height: "30px", width: "175px" }}
                      defaultItem={defaultItem}
                      textField='symbol'
                      dataItemKey='id'
                      value={unitsPowerSelected}
                      onChange={(e) => {
                        setUnitsPowerSelected(e.target.value);
                      }}
                      data={unitsPower}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="motorpower-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.motorPower', '')}: </label>&nbsp;
                  <div className='inputUnitDiv'>
                    <Input value={compressorModelSelectedMotorPower}
                      validationMessage={floatValidationMessage}
                      type="float"
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      onBlur={postCompressorSave}
                      style={{ height: "30px", marginRight: "10px", width: "190px" }}
                      onChange={e => {
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompressorModelSelectedMotorPower(e.target.value)
                      }} />
                    <DropDownList
                      required={true}
                      style={{ height: "30px", width: "175px" }}
                      defaultItem={defaultItem}
                      value={unitsMotorPowerSelected}
                      textField='symbol'
                      dataItemKey='id'
                      onChange={(e) => {
                        setUnitsMotorPowerSelected(e.target.value);
                      }}
                      data={unitsMotorPower}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="output-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.output', '')}: </label>&nbsp;
                  <div className='inputUnitDiv'>
                    <Input value={compressorModelSelectedOutput}
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      maxLength={5}
                      onBlur={postCompressorSave}
                      style={{ height: "30px", marginRight: "10px", width: "190px" }}
                      onChange={e => {
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompressorModelSelectedOutput(e.target.value)
                      }} />
                    <DropDownList
                      validationMessage={floatValidationMessage}
                      style={{ height: "30px", width: "175px" }}
                      defaultItem={defaultItem}
                      value={unitsOutputSelected}
                      textField='symbol'
                      dataItemKey='id'
                      onChange={(e) => {
                        setUnitsOutputSelected(e.target.value);
                      }}
                      data={unitsOutput}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="types-name" className="text-info, label-add-compressors" style={{ height: "32px", width: "120px", fontSize: "17px", paddingTop: "2px" }}> {localizationService.toLanguageString('custom.types', '')}: </label>&nbsp;

                  <DropDownList
                    onBlur={postCompressorSave}
                    value={compressorType}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => {
                      setCompressorType(e.target.value);
                    }}
                    data={compTypes}
                  />

                </div>

                <div className="mb-3">
                  <div>
                    <label htmlFor="nickname-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.nickname', '')}</label>
                    <Input
                      type="text"
                      name="nickname"
                      placeholder=""
                      style={{ height: "30px" }}
                      onBlur={postCompressorSave}
                      onChange={(e) => {
                        setCompNickname(e.value)
                      }}
                    />

                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="serial-no-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.serialNo', '')}</label>
                  <Input
                    type="text"
                    name="serialNo"
                    placeholder=""
                    onBlur={postCompressorSave}
                    style={{ height: "30px" }}
                    onChange={e => {
                      setCompSerialNo(e.value)
                    }}
                  />

                </div>

                <div className="mb-3">
                  <label htmlFor="year-manufactured-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.yearManufactured', '')}</label>
                  <DropDownList
                    onBlur={postCompressorSave}
                    value={compYear}
                    onChange={(e) => {
                      setCompYear(e.target.value);
                    }}
                    data={years}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="comment-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.comment', '')}</label>
                  <TextArea
                    onBlur={postCompressorSave}
                    value={compressorComment}
                    maxLength={500}
                    rows={4}
                    onChange={e => {
                      setCompressorComment(e.value)
                    }}
                  />
                </div>
                <h1 className="UploadFilesFirstTitle">{localizationService.toLanguageString('custom.myCompressorFiles', '')}</h1>
                <UploadFiles
                  idElement={idCreatedCompressor}
                  element="compressors_site"
                  setFileName={setFileName}
                  fileName={fileName}
                  data={newCompressorFilesForApi}
                  finishUploadCallback={finishUploadCallback}>
                </UploadFiles>
                <DisplayFiles currentFiles={currentFiles} finishUploadCallback={finishUploadCallback} />
                <div>
                  <div className="strike"><span>{localizationService.toLanguageString('custom.optionalFields', '')} </span></div>
                  <div className="info">
                    <p><strong><span className="k-icon k-i-information"></span></strong> {localizationService.toLanguageString('custom.optionalFieldsInfo', '')} </p>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="frequency" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.frequency', '')} Hz</label>
                      <Input value={compFrequency}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setCompFrequency(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="power_factor_loaded" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.PowerFactorLoaded', '')}</label>
                      <Input value={powerFactorLoaded}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setPowerFactorLoaded(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="power_factor_offload" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.PowerFactorOffload', '')} </label>
                      <Input value={powerFactorOffload}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setPowerFactorOffload(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="total_power_loaded" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.totalPowerLoaded', '')}</label>
                      <Input value={totalPowerLoaded}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setTotalPowerLoaded(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="total_power_offload" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.totalPowerOffload', '')}</label>
                      <Input value={totalPowerOffload}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setTotalPowerOffload(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="control_system" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.controlSystem', '')}</label>
                      <Checkbox value={controlSystem}
                      style={{marginLeft: "10px"}}
                        onBlur={postCompressorSave}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setControlSystem(e.target.value)
                        }} />

                    </div>
                  </div>
                  {validationError && (
                    <Fade>
                      <NotificationGroup style={{
                        display: "contents"
                      }} >
                        <Notification
                          type={{ style: "error", icon: true }}
                          closable={true}
                          onClose={() => setValidationError(false)}


                        >
                          <span>{localizationService.toLanguageString('custom.allUnitsAreRequired', '')}</span>
                        </Notification>
                      </NotificationGroup>

                    </Fade>
                  )}
                </div>
                <div className="k-form-buttons">
                  <Button primary type={"submit"} style={{ margin: "auto" }} >
                    {localizationService.toLanguageString('custom.submitAndDisplay', '')}
                  </Button>

                  {/*                 <button type={"submit"} className="btn btn-outline-success btn-block mt-1"
                  style={{ height: "30px", fontSize: "16px", paddingTop: "4px" }}>
                  Submit and Display
                </button> */}

                </div>
              </>
            )}
          </FormElement>
        )
        }
      />
      {!casIsBlocked &&
        < div
          style={{ justifyContent: 'space-between', alignContent: 'center', marginTop: "50px", maxWidth: "650px" }}
          className={'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}
        >
          <span style={{ alignSelf: 'center' }}>{localizationService.toLanguageString('custom.step', '')} {step + 1} {localizationService.toLanguageString('custom.of', '')} 2</span>
          <div>
            {
              step !== 0 ? (
                <Button style={{
                  marginRight: '16px', border: "#008c95 solid 2px",
                  color: "#008c95"
                }} onClick={onPrevClick}>
                  {localizationService.toLanguageString('custom.previous', '')}
                </Button>
              ) : (<Button style={{
                border: "#008c95 solid 2px",
                color: "#008c95"
              }}
                onClick={() => {
                  if (firstStepIsValid()) {
                    setStep(1)
                  } else {
                    setValidationError(true)
                  }
                }}
              >
                {localizationService.toLanguageString('custom.next', '')}
              </Button>
              )
            }

          </div>
        </div >
      }

      <div>
        {(visible) &&
          <Dialog title={"Please confirm"} onClose={Window}>
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              {localizationService.toLanguageString('custom.yourChangesSaved', '')}
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={Window}>
                ok
              </button>
            </DialogActionsBar>
          </Dialog>
        }
      </div>
      {/* </form> */}
    </div >
  )
}

export default AddCompData
