import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../contexts/UserContextProvider'
import { Col, Container, Row } from 'reactstrap'
import { SiteContext } from '../../contexts/SiteContextProvider'
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardHeader,
} from "@progress/kendo-react-layout";
import SiteImage from '../../images/site.jpg'
import { DropDownList } from '@progress/kendo-react-dropdowns/dist/npm/DropDownList/DropDownList';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { createSite, fetchSite, fetchSites, fetchUserSites, listSiteFiles, updateSite } from '../../api/sites/sites';
import { Field, FieldRenderProps, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Fade } from '@progress/kendo-react-animation';
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import UploadFiles from '../uploadfiles/UploadFiles';
import DisplayFiles from '../uploadfiles/DisplayFiles';
import { Site } from '../../api/interfaces/site';
import { Error } from '@progress/kendo-react-labels';
import { currencies } from 'currencies.json';


const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value: string) => (emailRegex.test(value) ? "" :  "Please enter a valid email");
const EmailInput = (fieldRenderProps: FieldRenderProps) => {
 
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {
        visited && validationMessage &&
        (<Error>{validationMessage}</Error>)
      }
    </div>
  );
};

const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
export const phoneValidator = (value: string) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
      ? ""
      : "Not a valid phone number.";
const PhoneInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {
        visited && validationMessage &&
        (<Error>{validationMessage}</Error>)
      }
    </div>
  );
    }

  const SitesComp = () => {
    const localizationService = useLocalization();
    const { sites, currentSite, setCurrentSite } = useContext<any>(SiteContext)
    const [visible, setVisible] = React.useState<boolean>(false);
    const { authToken } = useContext<any>(UserContext);
    const [infoCurrentSite, setInfoCurrentSite] = React.useState<any>()
    const [allUserSites, setAllUsersSites] = React.useState<any>()
    const [updateForm, setUpdateForm] = React.useState<boolean>(false)
    const [success, setSuccess] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [fileName, setFileName] = React.useState("");
    const [currentFiles, setCurrentFiles] = React.useState<Array<any>>([]);
    const [visibleDialogConfirmationDelete, setVisibleDialogConfirmationDelete] = React.useState(false);

    const toggleDialog = () => {
      setVisible(!visible);
      if (updateForm) {
        setUpdateForm(false)
      }
    };

    useEffect(() => {
      if (currentSite) {
        fetchSite(authToken, currentSite.id, (d: any) => d.data.map((e: any) => ({
          name: e.name,
          id: e.id,
          schema_name: e.schema_name,
          address: e.address,
          currency: e.currency,
          contact_person_email: e.contact_person_email,
          company_name: e.company_name,
          contact_person_name: e.contact_person_name,
          contact_person_telephone: e.contact_person_telephone,
        })), setInfoCurrentSite)
        fetchUserSites(authToken, (d: any) => d.data.map((e: any) => ({
          name: e.name,
          id: e.id,
          schema_name: e.schema_name,
          address: e.address,
          currency: e.currency,
          contact_person_email: e.contact_person_email,
          company_name: e.company_name,
          contact_person_name: e.contact_person_name,
          contact_person_telephone: e.contact_person_telephone,
        })), setAllUsersSites)
      }

    }, [currentSite, updateForm, visible]);


    const handleSubmit = (dataItem) => {
      if (updateForm) {
        const siteForApi: Site = {
          id: infoCurrentSite.id,
          name: dataItem?.name || infoCurrentSite?.name,
          schema_name: infoCurrentSite?.schema_name,
          address: dataItem?.address || infoCurrentSite?.address,
          currency: dataItem.currency || infoCurrentSite?.currency,
          company_name: dataItem.company_name || infoCurrentSite?.company_name,
          contact_person_email: dataItem.contact_person_email || infoCurrentSite?.contact_person_email,
          contact_person_name: dataItem.contact_person_name || infoCurrentSite?.contact_person_name,
          contact_person_telephone: dataItem.contact_person_telephone || infoCurrentSite?.contact_person_telephone

        }
        updateSite(authToken, currentSite.id, siteForApi)
          .then(response => {
            if (response.data.success) {
              setSuccess(true)
              toggleDialog()


            } else {
              setError(true)
              toggleDialog()
            }

          });
      } else {
        const siteForApi: Site = {
          address: dataItem?.address || null,
          company_name: dataItem?.company_name || null,
          contact_person_email: dataItem?.contact_person_email || null,
          contact_person_name: dataItem?.contact_person_name || null,
          contact_person_telephone: dataItem?.contact_person_telephone || null,
          currency: dataItem?.currency || null,
          id: null,
          name: dataItem?.name || null,
          schema_name: "new_site"
        }
        createSite(authToken, siteForApi)
          .then(response => {
            if (response?.status == 200) {
              setSuccess(true)
              toggleDialog()


            } else {
              setError(true)
              toggleDialog()
            }

          });
      }

    }

    const openUpdateForm = () => {
      if (currentSite.schema_name == null) {
        setError(true)
        toggleDialog()

      } else {
        setUpdateForm(true);
        toggleDialog()
      }

    }

    let newSiteFileForApi: any = {
      id: null,
      site_id: infoCurrentSite?.id,
      name: fileName
    };

    const finishUploadCallback = () => {
      listSiteFiles(authToken, infoCurrentSite.id, (d: any) => d.data.map((e: any) => e), setCurrentFiles)
    };

    const currencyList = currencies.map(currency => currency.code)



    return (
      <Container style={{ marginTop: "10px", display: 'grid', justifyContent: 'flex-start' }}>
        <Row className="">
          <Col lg="12" className="px-0 mt-3">
            <h4 className="text-info">{localizationService.toLanguageString('custom.sitesInfo', '')}</h4>
            <hr />
          </Col>
        </Row>

        <Card orientation="horizontal">
          <CardImage src={SiteImage} />
          {currentSite &&
            <div >

              <CardHeader>
                <CardTitle> {localizationService.toLanguageString('custom.siteName', '')}: {currentSite?.name}</CardTitle>
              </CardHeader>
              <CardBody>
                <p>
                  <li>{localizationService.toLanguageString('custom.siteName', '')}: {currentSite?.name}</li>
                  <li>{localizationService.toLanguageString('custom.address', '')}: {currentSite?.address}</li>
                  <li>{localizationService.toLanguageString('custom.currency', '')}: {currentSite?.currency}</li>
                  <li>{localizationService.toLanguageString('custom.company_name', '')}: {currentSite?.company_name}</li>
                  <li>{localizationService.toLanguageString('custom.contact_person_email', '')}: {currentSite?.contact_person_email}</li>
                  <li>{localizationService.toLanguageString('custom.contact_person_name', '')}: {currentSite?.contact_person_name}</li>
                  <li>{localizationService.toLanguageString('custom.contact_person_telephone', '')}: {currentSite?.contact_person_telephone}</li>
                </p>
              </CardBody>
              <CardActions>
                <PermissibleRender
                  userPermissions={["admin", "partner"]}
                  requiredPermissions={["admin", "test"]}
                  oneperm={true}
                >
                  <Button onClick={openUpdateForm}>
                    {localizationService.toLanguageString('custom.updateSite', '')}
                  </Button>
                  <Button disabled={true} >
                    {localizationService.toLanguageString('custom.disableSite', '')}
                  </Button>
                  <Button className="k-button" primary={true} onClick={toggleDialog}>
                    {localizationService.toLanguageString('custom.createSite', '')}
                  </Button>
                </PermissibleRender>
              </CardActions>
            </div>
          }
        </Card>
        <hr />


        <div>
          <p>{localizationService.toLanguageString('custom.changeSite', '')}:</p>
          <DropDownList
            textField="name"
            dataItemKey="id"
            onChange={(e) => {
              setCurrentSite(e.target.value);
              setInfoCurrentSite(e.target.value)
            }}
            data={allUserSites}
            value={currentSite}

          />
        </div>
        {visible && (
          <Window onClose={toggleDialog} initialHeight={850} initialWidth={550}>
            <Form
              initialValues={{
                name: updateForm ? currentSite?.name : "",
                address: updateForm ? currentSite?.address : "",
                currency: updateForm ? currentSite?.currency : "",
                contact_person_email: updateForm ? currentSite?.contact_person_email : "",
                company_name: updateForm ? currentSite?.company_name : "",
                contact_person_name: updateForm ? currentSite?.contact_person_name : "",
                contact_person_telephone: updateForm ? currentSite?.contact_person_telephone : ""
              }}
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ maxWidth: 850 }}>
                  <fieldset>
                    {updateForm ? <legend> {localizationService.toLanguageString('custom.editSite', '')}</legend> : <legend> {localizationService.toLanguageString('custom.createSite', '')}</legend>}

                    <label className="k-form-field">
                      <Field
                        name={"name"}
                        component={Input}
                        required={true}
                        label={localizationService.toLanguageString('custom.name', '')}
                      />
                    </label>
                    <label className="k-form-field">
                      <DropDownList
                        name={"currency"}
                        label={localizationService.toLanguageString('custom.currency', '')}
                        data={currencyList}
                      />
                    </label>
                    <label className="k-form-field">
                      <Field
                        required={true}
                        name={"address"}
                        component={Input}
                        label={localizationService.toLanguageString('custom.address', '')}
                      />
                    </label>
                    <label className="k-form-field">
                      <Field
                        name={"company_name"}
                        component={Input}
                        required={true}
                        label={localizationService.toLanguageString('custom.company_name', '')}
                      />
                    </label>
                    <label className="k-form-field">
                      <Field
                        name={"contact_person_name"}
                        component={Input}
                        label={localizationService.toLanguageString('custom.contact_person_name', '')}
                      />
                    </label>
                    <label className="k-form-field">
                      <Field
                        name={"contact_person_email"}
                        component={EmailInput}
                        required={true}
                        label={localizationService.toLanguageString('custom.contact_person_email', '')}
                        validator={emailValidator}
                      />

                    </label>
                    <label className="k-form-field">
                      <Field
                        name={"contact_person_telephone"}
                        component={PhoneInput}
                        required={true}
                        label={localizationService.toLanguageString('custom.contact_person_telephone', '')}
                        validator={phoneValidator}

                      />
                    </label>
                    <label className="k-form-field">
                      <span>{localizationService.toLanguageString('custom.draw/map', '')}</span>
                      <UploadFiles idElement={currentSite.id} element="sites" setFileName={setFileName} fileName={fileName} data={newSiteFileForApi} finishUploadCallback={finishUploadCallback}></UploadFiles>
                      <DisplayFiles currentFiles={currentFiles} finishUploadCallback={finishUploadCallback} />
                    </label>
                  </fieldset>

                  <div className="text-right">
                    <button
                      type="button"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={toggleDialog}
                    >
                      Cancel
                    </button>
                    <Button
                      primary={true}
                      type={"submit"}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </Window>
        )}
        <NotificationGroup
          style={{
            display: "contents"
          }}
        >
          {success && (
            <Fade  >
              <Notification
                type={{ style: "success", icon: true }}
                closable={true}
                onClose={() => setSuccess(false)}
              >
                <span>{localizationService.toLanguageString('custom.changes_saved', '')}</span>
              </Notification>

            </Fade>
          )}
          {error && (
            <Fade>
              {error && (
                <Notification
                  type={{ style: "error", icon: true }}
                  closable={true}
                  onClose={() => setError(false)}
                >
                  <span>{localizationService.toLanguageString('custom.something_wrong', '')}</span>
                </Notification>
              )}
            </Fade>
          )}
        </NotificationGroup>
      </Container>

    )

  }

  export default SitesComp