import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../contexts/UserContextProvider'
import APIURL from '../../configs/APIConfig'
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import { File } from '../../api/interfaces/file';
import { SaveFilePayload } from '../../api/interfaces/saveFilePayload';
import { flushSync } from 'react-dom'; 
import { forceCast } from  '../../common/castHelpers'; 
import { DecodedAuthToken } from '../../api/interfaces/decoded_auth_token';
import { minutesUntilDate } from  '../../common/dateHelpers';
import RefreshToken from '../../common/RefreshToken';
import jwtDecode from "jwt-decode"
import { useLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

/**
* Component to upload files
*/
const UploadFiles = (props: any) => {
  const localizationService = useLocalization();
  //ts-migrate FIXME: investigate type of useContext to dont be "any"
  const { authToken } = useContext<any>(UserContext);
  let  saveUrls: string[]= [];
  let savePayloads: SaveFilePayload[]= [];
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [visibleValidCompressor, setVisibleValidCompressor] = React.useState<boolean>(false);
  const [isSelected, setIsSelected] = useState(false);
  /**
  * Axios (NPM) is used to send the 'Auth Token' through headers
  */
  const authAxios = axios.create({
    baseURL: APIURL.url,
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })
  const isMounted = useRef(false)

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleDialogValidCompressor = () => {
    setVisibleValidCompressor(!visibleValidCompressor);
  };

  const changeHandler = async (event: any) => {  
    if(props.data.site_compressor_id < 0) {
      toggleDialogValidCompressor();
      return;
    }
    if(event.target.files.length > 10 ) {
      toggleDialog();
      return;
    }
    setIsSelected(true); 
    let currentFiles : Array<File> = [];
    if(selectedFiles.length > 0 ) {
      currentFiles = [...selectedFiles, ...event.target.files];
      setSelectedFiles(selectedFiles => ([...selectedFiles, ...event.target.files]));
    } else {
      currentFiles = Array.from(event.target.files);
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  useEffect(() => {
    if(isMounted.current){

      const decodedToken : DecodedAuthToken = forceCast<DecodedAuthToken>(jwtDecode(authToken));
      const expirationDateToken = new Date(1000*decodedToken.exp);
      const minutesUntilExpiration = minutesUntilDate(expirationDateToken);
      if(minutesUntilExpiration <= 10) {
        RefreshToken();
      }  
      try {
        authAxios.post(`/`+ props.element +`/` + props.idElement + '/file/upload' , props.data)
        .then((response) => {
          if(response != undefined && response != null && Object.keys(response).includes("data")) {
            console.log(response);
            saveUrls = [...saveUrls, response.data.url]
            savePayloads = [...savePayloads, response.data.fields]
            for (let i = 0; i < saveUrls.length; i++) {
              const formData = new FormData();
              formData.append('key', savePayloads[i].key);
              formData.append('policy', savePayloads[i].policy);
              formData.append('x-amz-algorithm', savePayloads[i]['x-amz-algorithm']);
              formData.append('x-amz-credential', savePayloads[i]['x-amz-credential']);
              formData.append('x-amz-date', savePayloads[i]['x-amz-date']);
              formData.append('x-amz-security-token', savePayloads[i]['x-amz-security-token']);
              formData.append('x-amz-signature', savePayloads[i]['x-amz-signature']);
              formData.append('file', JSON.stringify(selectedFiles[i]));
              fetch(
                saveUrls[i],
                {
                  method: 'POST',
                  body: formData
                }
              )
              .then((response) => {
                return response? response.text() : ""
              })
              .then((result) => {
                console.log('Success:', result);
              })
              .catch((error) => {
                console.error('Error:', error);
              }); 
            }
            saveUrls =[];
            savePayloads=[];
            setSelectedFiles([]);
            setIsSelected(false); 
            if(props.finishUploadCallback) props.finishUploadCallback();

          }
        }) 
      } catch (err: any) {
        console.log('AXIOS ERROR', err.message);
        if(err.response && err.response.status == 401) {
            RefreshToken();
            window.location.reload();  
        }
      } 
    } else {
     isMounted.current = true;
    } 
  }, [props.fileName]);

  const handleSubmission = () => { 
    Array.from(selectedFiles).forEach(file => {
      try {
        const castedFile = forceCast<File>(file)
        flushSync(() => {
          props.setFileName(castedFile.name);
        });
      } catch (err: any) {
        console.log("AXIOS ERROR", err.message);
      }
    }); 
  };

  const clearSelection = (event: any) => {
    saveUrls =[];
    savePayloads=[];
    setSelectedFiles([]);
    setIsSelected(false);  
  };

  return (
    <div>
      {/* Upload files component */}
      <div className="uploadFilesDiv">
        <fieldset className="filesTitle">
          <legend className="legendUploadFiles"> {localizationService.toLanguageString('custom.uploadFiles', '')} </legend>
          <div className="uploadFilesContent">
            <input id="fileInput" className="inputUploadFiles hidden" type="file" name="file" onChange={changeHandler} multiple/>
            <label id="selectFilesLabel" htmlFor="fileInput">{localizationService.toLanguageString('custom.selectFiles', '')}</label>
            {isSelected ? (
              <div className="filesInfoAdd"> 
              {selectedFiles.map((selectedFile: File) => (  
                  <div className="filesInfo">
                    <p>{localizationService.toLanguageString('custom.filename', '')}: {selectedFile?.name}</p>
                    {/*<p>Filetype: {selectedFile?.type}</p>
                    <p>Size in bytes: {selectedFile?.size}</p>
                    <p>
                      lastModifiedDate:{' '}
                      {selectedFile.lastModifiedDate.toLocaleDateString()}
                    </p>*/}
                  </div>
                ))}
              </div>
            ) : (
              <p></p>
            )} 
            <div>
              <Button type="button" style={{marginRight: "10px"}} onClick={clearSelection}>{localizationService.toLanguageString('custom.clear', '')}</Button>

              <Button type="button" primary={true} onClick={handleSubmission}>{localizationService.toLanguageString('custom.upload', '')}</Button>
            </div>
          </div>
        </fieldset>
      </div>
      {visible && (
        <Dialog onClose={toggleDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            {localizationService.toLanguageString('custom.maximumtenfiles', '')}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              {localizationService.toLanguageString('custom.ok', '')}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {visibleValidCompressor && (
        <Dialog onClose={toggleDialogValidCompressor}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            {localizationService.toLanguageString('custom.enterValidCompressorInformation', '')}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialogValidCompressor}
            >
              {localizationService.toLanguageString('custom.ok', '')}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  )
}

export default UploadFiles
