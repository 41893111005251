export const enMessages = {
    "custom": {  
        "number": "№",
        "nickname": "Nickname",
        "brand": "Brand",
        "model": "Model",
        "output": "Output",
        "powerSupply": "Power supply",
        "cooling": "Cooling",
        "motorPower": "Motor power",
        "pressure": "Pressure",
        "regulation": "Regulation",
        "serialNumber": "Serial №",
        "type": "Type",
        "yearManufactured": "Year manufactured",
        "comment": "Comment",
        "exportExcel": "Export to Excel",
        "exportPDF": "Export to PDF",
        "AddCompressor": "Add Compressor",
        "DisplayCompressors": "Display Compressors",
        "compressedAirSystem": "Compressed Air System",
        "newCompressor": "NEW COMPRESSOR", 
        "AddNewBrand": "Add new brand",
        "AddNewModel": "Add new model",
        "AllFieldsAreRequired": "All fields are required",
        "step": "Step",
        "of": "of",
        "next": "Next",
        "newCompressorBrand": "New Compressor Brand",
        "newBrand": "New brand",
        "add": "Add",
        "cancel": "Cancel",
        "newModel": "New model",
        "newCompressorModel": "New Compressor Model",
        "unit": "Unit",
        "powerSupplyVoltage": "Power Supply Voltage",
        "types": "Types",
        "serialNo": "Serial No",
        "myCompressorFiles": "My Compressor Files",
        "uploadFiles": "Upload Files",
        "selectFiles": "Select files",
        "filename": "Filename",
        "clear": "Clear",
        "upload": "Upload",
        "allUnitsAreRequired": "All units are required",
        "submitAndDisplay": "Submit And Display",
        "yourChangesSaved": "Your changes have been saved",
        "previous": "Previous",
        "uploadedFiles": "Uploaded Files",
        "none": "None",
        "addNewCas": "Add new CAS",
        "displayCas": "Display CAS",
        "publishCas": "Publish CAS",
        "personalInfo": "PERSONAL INFO",
        "role": "Role",
        "email": "Email",
        "lastName": "Last name",
        "firstName": "First name",
        "country": "Country",
        "sitesInfo": "SITES INFO",
        "siteName": "Site Name",
        "exampleSites": "Some quick example text if we need to describe site or add more information",
        "changeSite": "Change the Site",
        "addNew": "Add New",
        "cannotDeleteCAS": "Cannot delete because there are linked compressors to this CAS",
        "selectionOfCurrency": "The selection of a new currency will overide the price of electricity",
        "hoursFieldMustBeNumber": "The hours field must be a number (not decimal)",
        "shutdownHoursPerYear": "System shutdown hours per year",
        "intensityOfElectricity": "CO2 intensity of electricity generation",
        "systemStorageVolume": "System storage volume",
        "priceOfElectricity": "Price of electricity",
        "currency": "Currency",
        "status": "Status",
        "youAreGoingToPublishCAS": "You are going to publish the Compressed Air System",
        "withTheFollowingData": "with the following data ",
        "totalNumberOf": "Total number of",
        "compressors": "COMPRESSORS",
        "afterPublishingCAS": "After publishing the Compressed Air System",
        "youWillNotBeAbleToModify": "you will not be able to modify it and your data will be locked.",
        "enersizeExpertWillReview": "The Enersize experts will review it and verify the information and unlocked the data. You will recieved an email when the verification is done",
        "areYouSureYouWantToPublish": "Are you sure you want to publish this information? If you press Yes, your data could not be editable any longer",
        "yes": "Yes",
        "ThankYouYourInformation": "Thank you! Your information has been published successfully",
        "ourExpertsWillReviewAndInformYou": "Our experts will review your information and contact you",
        "menu": "Menu",
        "addElementsToYourCAS": "Add new elements to your Compressed Air System",
        "myCASInfo": "MY CAS INFO",
        "site": "Site",
        "locale": "Locale",
        "bePartOfTheChange": "Be part of the change in sustainable compressed air management",
        "enersizeIs": "Enersize is independent of compressor manufacturers and on a mission to become the global leader" +
        "in compressed air efficiency software. We develop and deliver smart software and services to enable" +
        "energy optimization of industrial compressed air systems." +
        "Our 3-step journey to energy excellence offers the tools and expertise for energy optimization of " +
        "industrial compressed air. Begin with an audit to identify your unique savings potential – and learn " +
        "how to achieve it.",
        "needAnAccount": "Need an account? Contact", 
        "theSelectedCas" : "The selected CAS is already published. Select another CAS or wait for expert approval.",
        "ok":"Ok",
        "maximumtenfiles": "Maximum ten files can be uploaded",
        "confirmDeletingCAS": "Confirm deleting the CAS",
        "enterValidCompressorInformation": "Please complete the compressor information before uploading",
        "delete": "Delete",
        "pleaseConfirm": "Please Confirm",
        "deleteConfirmationCompressor": "Are you sure you want to delete this compressor?",  
        "co2FieldMustBeFloat": "The CO2 field must be a decimal separated by dot",
        "priceFieldMustBeFloat": "The price field must be a decimal separated by dot",
        "volumeFieldMustBeFloat": "System Storage Volume field must be number (not decimal)",
        "profile": "Profile",
        "sites": "Sites",
        "mycas": "My Compressed Air System",
        "compressorNickname": "Compressor Nickname",
        "compressorSerialNo": "Compressor Serial No",
        "compressorType": "Compressor Type",
        "compressorPressure": "Compressor Pressure",
        "pressureUnit": "Pressure unit",
        "compressorRegulation": "Compressor Regulation",
        "compressorCooling": "Compressor Cooling",
        "compressorYearManufactured": "Compressor Year Manufactured",
        "compressorPowerSupply": "Compressor Power Supply",
        "powerSupplyUnit": "Power Supply Unit",
        "compressorMotorPower": "Compressor Motor Power",
        "motorPowerUnit": "Motor Power Unit",
        "compressorOutput": "Compressor Output",
        "outputUnit": "Output Unit",
        "save": "Save",
        "phonenumber": "Phone number",
        "updateSite": "Update Site",
        "createSite": "New Site",
        "disableSite": "Disable Site",
        "energy_cost": "Energy cost",
        "map": "Map / Drawing",
        "changes_saved": "Your changes has been saved",
        "something_wrong": "Oops! Something went wrong. Try again later",
        "draw/map": "Map/draw of the plant (optional)",
        "name": "Name",
        "address": "Address",
        "currency": "Currency",
        "contact_person_name": "Contact Person Name",
        "contact_person_email": "Contact Person Email",
        "contact_person_telephone": "Contact Person Phone",
        "company_name": "Company Name",
        "editSite" : "Edit Site",
        "validEmail": "Please enter a valid email",
        "frequency": "Frequency",     
        "optionalFields": "Optional Fields",
        "optionalFieldsInfo": "The following fields are optional to be filled. Our Enersize Expert will complete them if you don't have this information available.",
        "PowerFactorLoaded": "Power Factor Loaded",
        "PowerFactorOffload": "Power Factor Offload",
        "totalPowerLoaded": "Total Package Power Loaded",
        "totalPowerOffload": "Total Package Power Offload",
        "controlSystem": "Connected to a control system?",
        "start": "Start",
        "leaqs": "Q+Leaqs",
        "workShifts": "Work Shifts (number, hours)",
        "controlSystemCode": "Control system brand and product code",
        "dewPoint": "Dew point/ °C",
        "isoStandard": "What ISO standard on quality of air?",
        "nextYearConsumption": "Do you expect air consumption to increase or decrease within the next year? ",
        "productionArea": "Production area / m2",
        "gases": "Other gases expected to be reported? Nitrogen, CO2, Argon, Oxygen?",
        "workingPressure": "Working pressure in the system / bar",
        "operationHoursPerWeek": "System operation hours per week"

    },
    "grid":{
        "groupPanelEmpty": "Drag a column header and drop it here to group by that column",
        "pagerItemsPerPage": "items per page",
        "pagerInfo": "{0} - {1} of {2} items",
        "pagerFirstPage": "Go to the first page",
        "pagerPreviousPage": "Go to the previous page",
        "pagerNextPage": "Go to the next page",
        "pagerLastPage": "Go to the last page",
        "pagerPage": "Page",
        "pagerOf": "of",
        "filterClearButton":"Clear",
        "filterEqOperator": "Is equal to",
        "filterNotEqOperator": "Is not equal to",
        "filterIsNullOperator": "Is null",
        "filterIsNotNullOperator": "Is not null",
        "filterIsEmptyOperator": "Is empty",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterStartsWithOperator": "Starts with",
        "filterContainsOperator": "Contains",
        "filterNotContainsOperator": "Does not contain",
        "filterEndsWithOperator": "Ends with",
        "filterGteOperator": "Is greater than or equal to",
        "filterGtOperator": "Is greater than",
        "filterLteOperator": "Is less than or equal to",
        "filterLtOperator":"Is less than",
        "filterIsTrue":"Is true",
        "filterIsFalse": "Is false",
        "filterBooleanAll": "(All)",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterAfterOperator": "Is after",
        "filterBeforeOperator":"Is before",
        "filterBeforeOrEqualOperator":"Is before or equal to",
        "noRecords":"No records available."
    },
    "filter": {
        "eqOperator": "Is equal to",
        "notEqOperator": "Is not equal to",
        "isNullOperator": "Is null",
        "isNotNullOperator": "Is not null",
        "isEmptyOperator": "Is empty",
        "isNotEmptyOperator": "Is not empty",
        "startsWithOperator": "Starts with",
        "containsOperator": "Contains",
        "notContainsOperator": "Does not contain",
        "endsWithOperator": "Ends with",
        "gteOperator": "Is greater than or equal to",
        "gtOperator": "Is greater than",
        "lteOperator": "Is less than or equal to",
        "ltOperator": "Is less than",
        "isTrue": "Is true",
        "isFalse": "Is false",
        "afterOrEqualOperator": "Is after or equal to",
        "afterOperator": "Is after",
        "beforeOperator": "Is before",
        "beforeOrEqualOperator": "Is before or equal to",
        "andLogic": "And",
        "orLogic": "Or",
        "addExpression": "Add Expression",
        "addGroup": "Add Group",
        "close": "Close"
    },
    "dateinput":{
        "increment": "Increase value",
        "decrement": "Decrease value"
    },
    "calendar":{
        "today":"TODAY"
    },
    "datepicker":{
        "toggleCalendar": "Toggle calendar"
    },
    "daterangepicker": {
        "swapStartEnd": "Swap start and end values",
        "start": "Start",
        "end": "End",
        "separator": " "
    },
    "datetimepicker": {
        "date": "Date",
        "time": "Time",
        "toggleDateTimeSelector": "Toggle date-time selector",
        "cancel": "Cancel",
        "set": "Set"
    },
    "editor": {
        "bold": "Bold",
        "italic": "Italic",
        "underline": "Underline",
        "strikethrough": "Strikethrough",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "unlink": "Remove hyperlink",
        "undo": "Undo",
        "redo": "Redo",
        "fontSize": "Font Size",
        "fontName": "Font Name",
        "format": "Format",
        "alignLeft": "Align text left",
        "alignRight": "Align text right",
        "alignCenter": "Center text",
        "alignJustify": "Justify",
        "indent": "Indent",
        "outdent": "Outdent",
        "orderedList": "Insert ordered list",
        "bulletList": "Insert unordered list",
        "createTable": "Create a table",
        "insertTableHint": "Create a {0} x {1} table",
        "addRowBefore": "Add row above",
        "addRowAfter": "Add row below",
        "addColumnBefore": "Add column on the left",
        "addColumnAfter": "Add column on the right",
        "deleteRow": "Delete row",
        "deleteColumn": "Delete column",
        "deleteTable": "Delete table",
        "mergeCells": "Merge cells",
        "splitCell": "Split cell",
        "hyperlink": "Insert hyperlink",
        "hyperlink-dialog-title": "Insert hyperlink",
        "hyperlink-dialog-content-address": "Web address",
        "hyperlink-dialog-content-title": "Title",
        "hyperlink-dialog-content-newwindow": "Open link in new window",
        "hyperlink-dialog-cancel": "Cancel",
        "hyperlink-dialog-insert": "Insert",
        "image": "Insert image",
        "image-dialog-title": "Insert image",
        "image-address": "Web address",
        "image-title": "Title",
        "image-altText": "Alternate text",
        "image-width": "Width (px)",
        "image-height": "Height (px)",
        "image-cancel": "Cancel",
        "image-insert": "Insert",
        "viewHtml": "View HTML",
        "viewHtml-dialog-title": "View HTML",
        "viewHtml-cancel": "Cancel",
        "viewHtml-update": "Update"
    },
    "timepicker": {
        "now": "NOW",
        "selectNow": "Select Now",
        "cancel": "Cancel",
        "set": "Set",
        "toggleTimeSelector": "Toggle TimeSelector",
        "toggleClock": "Toggle Clock"
    },
    "dropdowns":{
        "nodata": "NO DATA FOUND.",
        "clear": "Clear"
    },
    "numerictextbox":{
        "increment": "Increase value",
        "decrement": "Decrease value"
    },
    "upload": {
        "cancel": "Cancel",
        "clearSelectedFiles": "Clear",
        "dropFilesHere": "Drop files here to upload",
        "headerStatusUploaded": "Done",
        "headerStatusUploading": "Uploading...",
        "invalidFileExtension": "File type not allowed.",
        "invalidFiles": "Invalid file(s). Please check file upload requirements.",
        "invalidMaxFileSize": "File size too large.",
        "invalidMinFileSize": "File size too small.",
        "remove": "Remove",
        "retry": "Retry",
        "select": "Select files...",
        "uploadSelectedFiles": "Upload",
        "total": "Total",
        "files": "files"
    },
    "sortable": {
        "noData": "No Data"
    },
    "pager": {
        "itemsPerPage": "items per page",
        "info": "{0} - {1} of {2} items",
        "firstPage": "Go to the first page",
        "previousPage": "Go to the previous page",
        "nextPage": "Go to the next page",
        "lastPage": "Go to the last page",
        "page": "Page",
        "of": "of"
    },
    "treelist": {
        "filterClearButton": "Clear",
        "filterEqOperator": "Is equal to",
        "filterNotEqOperator": "Is not equal to",
        "filterIsNullOperator": "Is null",
        "filterIsNotNullOperator": "Is not null",
        "filterIsEmptyOperator": "Is empty",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterStartsWithOperator": "Starts with",
        "filterContainsOperator": "Contains",
        "filterNotContainsOperator": "Does not contain",
        "filterEndsWithOperator": "Ends with",
        "filterGteOperator": "Is greater than or equal to",
        "filterGtOperator": "Is greater than",
        "filterLteOperator": "Is less than or equal to",
        "filterLtOperator": "Is less than",
        "filterIsTrue": "Is true",
        "filterIsFalse": "Is false",
        "filterBooleanAll": "(All)",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterAfterOperator": "Is after",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "noRecords": "No records available"
    },
    "scheduler": {
        "editorValidationRequired": "Field is required.",
        "editorValidationStart": "Start time must be be before End time.",
        "editorValidationEnd": "End time must be after Start time.",
        "allEvents": "All events",
        "allDay": "all day",
        "today": "Today",
        "dayViewTitle": "Day",
        "monthViewTitle": "Month",
        "weekViewTitle": "Week",
        "workWeekViewTitle": "Work Week",
        "timelineViewTitle": "Timeline",
        "agendaViewTitle": "Agenda",
        "deleteTitle": "Delete",
        "previousTitle": "Previous",
        "nextTitle": "Next",
        "showFullDay": "Show full day",
        "showWorkDay": "Show business hours",
        "editorOccurrence": "Edit current occurrence",
        "editorSeries": "Edit the series",
        "editorRecurringConfirmation": "Do you want to edit only this event occurrence or the whole series?",
        "editorRecurringDialogTitle": "Edit Recurring Item",
        "editorSave": "Save",
        "editorCancel": "Cancel",
        "editorDelete": "Delete",
        "editorTitle": "Event",
        "editorEventTitle": "Title",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start Time Zone",
        "editorEventEnd": "End",
        "editorEventEndTimeZone": "End Time Zone",
        "editorEventAllDay": "All Day Event",
        "editorEventDescription": "Description",
        "editorEventSeparateTimeZones": "End in different Time Zone",
        "editorEventTimeZone": "Specify Time Zone",
        "recurrenceEditorRepeat": "Repeat",
        "recurrenceEditorDailyInterval": "day(s)",
        "recurrenceEditorDailyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyInterval": "week(s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyRepeatOn": "Repeat on",
        "recurrenceEditorMonthlyDay": "Day",
        "recurrenceEditorMonthlyInterval": "month(s)",
        "recurrenceEditorMonthlyRepeatEvery": "Repeat every",
        "recurrenceEditorMonthlyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyOf": "of",
        "recurrenceEditorYearlyRepeatEvery": "Repeat every",
        "recurrenceEditorYearlyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyInterval": "year(s)",
        "recurrenceEditorFrequenciesDaily": "Daily",
        "recurrenceEditorFrequenciesMonthly": "Monthly",
        "recurrenceEditorFrequenciesNever": "Never",
        "recurrenceEditorFrequenciesWeekly": "Weekly",
        "recurrenceEditorFrequenciesYearly": "Yearly",
        "recurrenceEditorOffsetPositionsFirst": "First",
        "recurrenceEditorOffsetPositionsSecond": "Second",
        "recurrenceEditorOffsetPositionsThird": "Third",
        "recurrenceEditorOffsetPositionsFourth": "Fourth",
        "recurrenceEditorOffsetPositionsLast": "Last",
        "recurrenceEditorWeekdaysDay": "Day",
        "recurrenceEditorWeekdaysWeekday": "Weekday",
        "recurrenceEditorWeekdaysWeekendday": "Weekend Day",
        "recurrenceEditorEndAfter": "After",
        "recurrenceEditorEndOccurrence": "occurrence(s)",
        "recurrenceEditorEndLabel": "End",
        "recurrenceEditorEndNever": "Never",
        "recurrenceEditorEndOn": "On",
        "deleteConfirmation": "Are you sure you want to delete this event?",
        "deleteRecurringConfirmation": "Do you want to delete only this event occurrence or the whole series?",
        "deleteOccurrence": "Delete current occurrence",
        "deleteSeries": "Delete the series",
        "deleteDialogTitle": "Delete Event",
        "deleteRecurringDialogTitle": "Delete Recurring Item",
        "dateTitle": "Date",
        "timeTitle": "Time",
        "eventTitle": "Event",
        "noEvents": "no events"
    }
};