import React, { useContext, useEffect } from 'react'
import { UserContext } from '../contexts/UserContextProvider'
import { Container } from 'reactstrap'
import UserProfile from "../components/users/UserProfile";


/**
 * Page for the Profile user info.
 * A user can see their profile personal information.
 */
const Profile = () => {
    const { getSessions, currentUser } = useContext<any>(UserContext)

    // useEffect(()=>{
    //   getSessions()
    // }, [])

    return (
        <Container fluid className="profile">

            <UserProfile />

        </Container>
    )
}

export default Profile