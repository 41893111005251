import { Container, Row, Col } from 'reactstrap'

/**
* Component for the Footer.
*/
const Footer = () => {
  return (
        <Container fluid style={{backgroundColor: "#002a3a", padding: "5px 0 0 0", zIndex: 1}}>
            <Container>
                <Row className="text-white pt-2 px-0">
                    <Col lg="6">
                        <p style={{fontSize: "14px"}}>&copy; 2018 ALL RIGHT RESERVED</p>
          </Col>
                    <Col lg="6">
                        <p className="text-lg-right" style={{fontSize: "14px"}}>ENERSIZE ADVANCED RESEARCH AB</p>
          </Col>
        </Row>
      </Container>      
    </Container>
  )
}

export default Footer
