/******
 * Global helper methods to cast types in the project
 * 
 ******/

export const forceCast = <T extends unknown> (input: any): T => { 
    return input;
  } 

export const tryParseInt = (value : string) : number | null => {
  try {
    return parseInt(value);
  } catch (error) {
    return null;
  }
} 

export const tryParseFloat = (value : string) : number | null => {
  try {
    return parseFloat(value);
  } catch (error) {
    return null;
  }
} 