import {createContext, useEffect, useState, useContext} from 'react'
import { Site } from '../api/interfaces/site';
import { fetchSites } from '../api/sites/sites';
import { UserContext } from './UserContextProvider';

// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const SiteContext = createContext();

const SiteContextProvider = (props: any) => {

  const [sites, setSites] = useState<Site[]>([]);
  const [currentSite, setCurrentSite] = useState<Site>();

  const { authToken } = useContext<any>(UserContext);

 
  const fetchSitesData = async () => { 
    fetchSites(authToken)
    .then((response) => {
      if(response != undefined && response != null && Object.keys(response).includes("data")) {
        const { data } = response   
        setSites(data) 
        localStorage.setItem("siteID", data[0].id)
        setCurrentSite(data[0]);
      } 
    }) 
  }

  useEffect(()=>{
    if(authToken !="") {
      fetchSitesData();
    }
  }, [authToken])
 
  return (
    <SiteContext.Provider value={{sites, currentSite, setCurrentSite}}>
      {props.children}
    </SiteContext.Provider>
  )
}
export default SiteContextProvider
