import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import MyCASystem from './MyCASystem'
import DisplayCAS from './DisplayCAS'
import PublishCAS from './PublishCAS'
import { Button } from '@progress/kendo-react-buttons'
import { useLocalization } from '@progress/kendo-react-intl';

/**
* CAS main- add new, display and publish CAS 
*/

enum Section{
  AddNew,
  Display,
  Publish
}

const MyCASystemMain = () => {
  const [rSelected, setRSelected] = useState(Section.AddNew);
  const localizationService = useLocalization();

  return (
    <Container className="">
      <Row className="">
        <Col lg="12" className="px-0 mt-3">
          <h4 className="text-info">{localizationService.toLanguageString('custom.myCASInfo', '')}</h4>
          <hr/>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between px-0 profile-">
        <Col lg="3" className="mt-3">
        <Button className="add-CAS-btn" autoFocus 
            onClick={() => setRSelected(Section.AddNew)}>
            {localizationService.toLanguageString('custom.addNewCas', '')}
          </Button>
        </Col>
        <Col lg="3" className="mt-3">
        <Button className="display-CAS-btn"
            onClick={() => setRSelected(Section.Display)} >
            {localizationService.toLanguageString('custom.displayCas', '')}
          </Button>
        </Col>
        <Col lg="2" className="mt-3">
        <Button className="publish-CAS-btn"
            onClick={() => setRSelected(Section.Publish)} >
            {localizationService.toLanguageString('custom.publishCas', '')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mt-3">
          {(() => {
            if (rSelected === Section.Display) return <DisplayCAS />
            if (rSelected === Section.AddNew) return <MyCASystem />
            else if (rSelected === Section.Publish) return <PublishCAS />
          })()}
        </Col>
      </Row>
    </Container>
  )
}

export default MyCASystemMain
