import React, {useContext} from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";

import DrawerContainer from './drawercontainer/Drawercontainer';
import TopbarMenu from './drawercontainer/TopbarMenu';

import Home from '../pages/Home';
import Signup from '../pages/SignupCognito'
import Signin from '../pages/UserSignin'
import SigninToken from '../pages/SigninToken'
import MyPage from '../pages/MyPage'
import Customers from '../pages/partner/Customers'
import Profile from '../pages/Profile'
import MyCASystem from "../pages/MyCASystem";
import MyCASystemMain from '../pages/MyCASystemMain';
import { SiteContext } from '../contexts/SiteContextProvider';
import { Loader } from '@progress/kendo-react-indicators';
import Footer from './partials/Footer';
import  { UserContext }  from '../contexts/UserContextProvider';
import  { LocalContext }  from '../contexts/LocaleContextProvider';
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  IntlService,
} from "@progress/kendo-react-intl";
import Sites from '../pages/Sites';
import { Dialog } from '@progress/kendo-react-dialogs';

/**
* Router Component.
* All routes are declared here. 
*/

const MainWrapper = () => {

    const { sites } = useContext<any>(SiteContext)
    const { authToken } = useContext<any>(UserContext);
   
    //ts-migrate FixME: investigate a better type for useContext that's not any
    const { getSessions, userStatus, logout } = useContext<any>(UserContext);
    const { currentLocale, setCurrentLocale, locales } = useContext<any>(LocalContext);

    return (
      <>
          <LocalizationProvider language={currentLocale.language}>
            <IntlProvider locale={currentLocale.locale}>
              {sites.length <= 0 && authToken  ? <Dialog className='loaderDialog'><Loader  size='large' type={'infinite-spinner'} className="spinner"/></Dialog> : ''} 
              <Router>
                <div className="content">
                  <TopbarMenu />
                  {!userStatus && <Switch>
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/main-signin" component={Signin} />
                    <Route exact path="/signin" component={SigninToken} /> </Switch>
                  }
                  {userStatus && <DrawerContainer>
                    <Switch>
                      <Route exact={true} path='/profile' component={Profile}  />
                      <Route exact={true} path='/mycasystem' component={MyCASystemMain}  />
                      <Route exact={true} path='/mypage' component={MyPage}  />
                      <Route exact path='/customers' component={Customers} />
                      <Route exact path="/" component={MyCASystemMain} />
                      <Route exact path="/sites" component={Sites} />
                    </Switch>
                  </DrawerContainer>}
                </div>
                <Footer />
              </Router>
            </IntlProvider>
          </LocalizationProvider>
      </>
    );
  }
  
  export default MainWrapper;