import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../contexts/UserContextProvider'
import DisplayComp from "../components/compressors/DisplayComp";
import { SiteContext } from "../contexts/SiteContextProvider";
import { fetchCaSystems } from "../api/cas/cas";
import { useLocalization } from '@progress/kendo-react-intl';

const gridWidth = 600;
 
const setPercentage = (percentage: any) => {
  return Math.round(gridWidth / 100) * percentage;
};

const DisplayCAS = () => {
    const localizationService = useLocalization();
    const { sites, currentSite } = useContext<any>(SiteContext)
    const { authToken } = useContext<any>(UserContext);
    const editField = "inEdit";
    const [data, setData] = React.useState([]);

    useEffect(() => {
        if (currentSite) {
            fetchCaSystems(authToken, currentSite.id, null, setData)
        }
    }, [currentSite])

    return (<>
        <Grid style={{ marginTop: "40px", marginBottom: "40px", fontSize: "17px", paddingTop: "2px",  width: gridWidth.toString()}}
            data={data}
            editField={editField}
            dataItemKey={"id"}
        >
            {/* displaying all cas columns */}
             <Column field="nickname" title={localizationService.toLanguageString('custom.nickname', '')} width={setPercentage(20)} />
             <Column field="currency" title={localizationService.toLanguageString('custom.currency', '')} width={setPercentage(20)}/>
             <Column field="hours" title={localizationService.toLanguageString('custom.shutdownHoursPerYear', '')} editor="numeric" width={setPercentage(50)}/>
             <Column field="co2" title={localizationService.toLanguageString('custom.intensityOfElectricity', '')} editor="numeric" width={setPercentage(55)}/>
             <Column field="volume" title={localizationService.toLanguageString('custom.systemStorageVolume', '')} width={setPercentage(35)}/>
             <Column field="price" title={localizationService.toLanguageString('custom.priceOfElectricity', '')} width={setPercentage(30)} />
	         <Column field="status" title={localizationService.toLanguageString('custom.status', '')} width={setPercentage(20)}/>
        </Grid>
        <DisplayComp></DisplayComp>
    </>
    );
};

export default DisplayCAS;