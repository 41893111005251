export const esMessages = {
    "custom": { 
        "number": "№",
        "nickname": "Apodo",
        "brand": "Marca",
        "model": "Modelo",
        "output": "Salida",
        "powerSupply": "Suministro de poder",
        "cooling": "Enfriamiento",
        "motorPower": "Poder del motor",
        "pressure": "Presión",
        "regulation": "Regulación",
        "serialNumber": "№ serial",
        "type": "Tipo",
        "yearManufactured": "Año de manufactura",
        "comment": "Comentario",
        "exportExcel": "Exportar a Excel",
        "exportPDF": "Exportar a PDF",
        "AddCompressor": "Añadir Compresor",
        "DisplayCompressors": "Mostrar Compresores",
        "compressedAirSystem": "Sistema de Aire Comprimido",
        "newCompressor": "NUEVO COMPRESOR", 
        "AddNewBrand": "Añadir nueva marca",
        "AddNewModel": "Añadir nuevo modelo",
        "AllFieldsAreRequired": "Todos los campos son requeridos",
        "step": "Paso",
        "of": "de",
        "next": "Siguiente",
        "newCompressorBrand": "Nueva Marca del Compresor",
        "newBrand": "Nueva marca",
        "add": "Añadir",
        "cancel": "Cancelar",
        "newModel": "Nuevo modelo",
        "newCompressorModel": "Nuevo Modelo del Compresor",
        "unit": "Unidad",
        "powerSupplyVoltage": "Voltaje de poder del motor",
        "types": "Tipos",
        "serialNo": "Número Serial",
        "myCompressorFiles": "Archivos de mi Compresor",
        "uploadFiles": "Cargar Archivos",
        "selectFiles": "Seleccionar archivos",
        "filename": "Nombre del Archivo",
        "clear": "Limpiar",
        "upload": "Cargar",
        "allUnitsAreRequired": "Todas las unidades son requeridas",
        "submitAndDisplay": "Guardar y Mostrar",
        "yourChangesSaved": "Tus cambios se han guardado",
        "previous": "Anterior",
        "uploadedFiles": "Archivos Cargados",
        "none": "Ninguno",
        "addNewCas": "Agregar nuevo SAC",
        "displayCas": "Mostrar SAC",
        "publishCas": "Publicar SAC",
        "personalInfo": "INFORMACION PERSONAL",
        "role": "Rol",
        "email": "Correo electrónico",
        "lastName": "Apellido",
        "firstName": "Nombre",
        "country": "País",
        "phonenumber": "Teléfono",
        "sitesInfo": "INFORMACION DE LOS SITIOS",
        "siteName": "Nombre del Sitio",
        "exampleSites": "Algún ejemplo de texto rápido si necesitamos describir el sitio o agregar más información",
        "changeSite": "Cambiar el Sitio",
        "addNew": "Agregar Nuevo",
        "cannotDeleteCAS": "No puede eliminar porque hay compresores asociados a este SAC",
        "selectionOfCurrency": "La selección de una nueva moneda va a sobrescribir el precio de electricidad",
        "hoursFieldMustBeNumber": "El campo horas debe ser un número (no un decimal)",
        "shutdownHoursPerYear": "Horas de apagado del sistema por año",
        "intensityOfElectricity": "Intensidad de CO2 de la generación de electricidad",
        "systemStorageVolume": "Volumen de Sistema de almacenamiento",
        "priceOfElectricity": "Precio de electricidad",
        "currency": "Moneda",
        "status": "Estado",
        "youAreGoingToPublishCAS": "Estás a punto de publicar el Sistema de Aire Comprimido",
        "withTheFollowingData": "con la siguiente información ",
        "totalNumberOf": "Número total de",
        "compressors": "COMPRESORES",
        "afterPublishingCAS": "Después de publicar la información del Sistema de Aire Comprimido",
        "youWillNotBeAbleToModify": "no vas a poder hacer modificaciones y tu información quedará bloqueada.",
        "enersizeExpertWillReview": "El experto de Enersize va a revisar y a verificar la información. Recibirás un email cuando el proceso de verificación esté completado y tu información quedará desbloqueada. ",
        "areYouSureYouWantToPublish": "Estás seguro de que deseas publicar esta información? Si presionas que si, no la vas a poder modificar",
        "yes": "Si", 
        "ThankYouYourInformation": "Gracias! Tu información ha sido publicada exitosamente",
        "ourExpertsWillReviewAndInformYou": "Nuestros expertos van a revisar tu información y te contactarán",
        "menu": "Menú",
        "addElementsToYourCAS": "Agregar elementos a tu Sistema de Aire Comprimido",
        "myCASInfo": "INFORMACION DE MI SAC",
        "site": "Sitio",
        "locale": "Idiomas",
        "bePartOfTheChange": "Se parte del cambio en gerencia sostenible de aire comprimido ",
        "enersizeIs": "Enersize es independiente de los fabricantes de compresores y tiene la misión de convertirse en el líder mundial " +
        "en software de eficiencia de aire comprimido. Desarrollamos y entregamos software y servicios inteligentes para habilitar" +
        "Optimización energética de sistemas industriales de aire comprimido " +
        "Nuestro viaje de 3 pasos hacia la excelencia energética ofrece las herramientas y la experiencia para la optimización energética de " +
        "aire comprimido industrial. Comience con una auditoría para identificar su potencial de ahorro único y aprenda " +
        "cómo lograrlo.",
        "needAnAccount": "Deseas abrir una cuenta? Contacta a",
        "theSelectedCas": "El SAC que has seleccionado está ya publicado. Seleccione otro o espere la verificación del experto/a",
        "ok":"De acuerdo",
        "maximumtenfiles": "Puedes adjuntar diez archivos máximo",
        "confirmDeletingCAS": "Confirmar la eliminación del SAC",
        "enterValidCompressorInformation": "Por favor completar la información del compresor antes de subir archivos",
        "delete": "Eliminar",
        "pleaseConfirm": "Por favor confirmar",
        "deleteConfirmationCompressor": "Estás seguro de que deseas eliminar este compresor?",  
        "co2FieldMustBeFloat": "El campo CO2 debe ser un decimal separado por coma",
        "priceFieldMustBeFloat": "El campo precio de electricidad debe ser un decimal separado por coma",
        "volumeFieldMustBeFloat": "El campo Volumen de Sistema de almacenamiento debe ser número (no decimal)",
        "profile": "Perfil",
        "sites": "Sitios",
        "mycas": "Mi sistema de aire comprimido",
        "compressorNickname": "Apodo del Compresor",
        "compressorSerialNo": "Número serial del Compresor",
        "compressorType": "Tipo de Compresor",
        "compressorPressure": "Presión del Compresor",
        "pressureUnit": "Unidad de la Presión",
        "compressorRegulation": "Regulación del Compresor",
        "compressorCooling": "Enfriamiento del Compresor",
        "compressorYearManufactured": "Año de Manufactura del Compresor",
        "compressorPowerSupply": "Fuente de alimentación del compresor",
        "powerSupplyUnit": "Unidad de fuente de alimentación",
        "compressorMotorPower": "Poder del motor del Compresor",
        "motorPowerUnit": "Unidad de Poder del Motor",
        "compressorOutput": "Salida del Compresor",
        "outputUnit": "Unidad de la Salida",
        "save": "Guardar",
        "phonenumber": "Teléfono",
        "updateSite": "Modificar Planta",
        "createSite": "Crear Planta",
        "disableSite": "Deshabilitar Planta",
        "energy_cost": "Coste energético",
        "map": "Mapa / Plano",
        "changes_saved": "Tus cambios han sido guardados",
        "something_wrong": "Oops! Algo ha fallado. Inténtelo de nuevo más tarde",
        "draw/map": "Mapa/dibujo de la planta (opcional)",
        "name": "Nombre",
        "address": "Dirección",
        "currency": "Moneda",
        "contact_person_name": "Contacto",
        "contact_person_email": "Email",
        "contact_person_telephone": "Telefono",
        "company_name": "Nombre de la empresa",
        "editSite" : "Modificar Planta",
        "validEmail": "Por favor introduzca un email válido",
        "frequency": "Frequencia",
        "optionalFields": "Campos Opcionales",
        "optionalFieldsInfo": "No es necesario completar la información de los siguientes campos. Nuestro experto añadirá la información si usted no la tiene disponible.",
        "PowerFactorLoaded": "Factor de potencia cargado",
        "PowerFactorOffload": "Descarga del factor de potencia",
        "totalPowerLoaded": "Potencia total del paquete cargado",
        "totalPowerOffload": "Descarga total de energía del paquete",
        "controlSystem": "¿Conectado a un sistema de control?",
        "start": "Inicio",
        "leaqs": "Q+Leaqs",
        "workShifts": "Turnos de trabajo (número, horas)",
        "controlSystemCode": "Marca del sistema de control y código de producto",
        "dewPoint": "Dew point/ °C",
        "isoStandard": "¿Qué norma ISO sobre la calidad del aire?",
        "nextYearConsumption": "¿Espera que el consumo de aire aumente o disminuya durante el próximo año?",
        "productionArea": "Área de producción / m2",
        "gases": "¿Otros gases que se espera informar? ¿Nitrógeno, CO2, Argón, Oxígeno?",
        "workingPressure": "Presión de trabajo en el sistema / bar",
        "operationHoursPerWeek": "Horas de operación del sistema por semana"                   
                                                                                                
                                
    },
    "datepicker": {
        "toggleCalendar": "Alternar calendario"
    },
    "calendar": {
        "today": "Hoy"
    },
    "dateinput": {
        "increment": "Incrementar valor",
        "decrement": "Disminuir valor"
    },
    "datetimepicker": {
        "date": "Fecha",
        "time": "Hora",
        "cancel": "Cancelar",
        "set": "Establecer"
    },
    "numerictextbox": {
        "increment": "Incrementar valor",
        "decrement": "Disminuir valor"
    },
    "timepicker": {
        "now": "AHORA",
        "set": "Establecer",
        "cancel": "Cancelar"
    },
    "grid": {
        "groupPanelEmpty": "Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio",
        "noRecords": "No hay datos disponibles.",
        "pagerFirstPage": "Ir a la primera página",
        "pagerPreviousPage": "Ir a la página anterior",
        "pagerNextPage": "Ir a la página siguiente",
        "pagerLastPage": "Ir a la última página",
        "pagerPage": "Página",
        "pagerOf": "de",
        "pagerItems": "ítems",
        "pagerInfo": "{0} - {1} de {2} ítems",
        "pagerItemsPerPage": "ítems por página",
        "filterEqOperator": "Es igual a",
        "filterNotEqOperator": "No es igual a",
        "filterIsNullOperator": "Es nulo",
        "filterIsNotNullOperator": "No es nulo",
        "filterIsEmptyOperator": "Está vacío",
        "filterIsNotEmptyOperator": "No está vacío",
        "filterStartsWithOperator": "Comienza con",
        "filterContainsOperator": "Contiene",
        "filterNotContainsOperator": "No contiene",
        "filterEndsWithOperator": "Termina en",
        "filterGteOperator": "Es mayor o igual que",
        "filterGtOperator": "Es mayor que",
        "filterLteOperator": "Es menor o igual que",
        "filterLtOperator": "Es menor o igual que",
        "filterIsTrue": "Sí",
        "filterIsFalse": "No",
        "filterBooleanAll": "(Todas)",
        "filterAfterOrEqualOperator": "Es posterior o igual a",
        "filterAfterOperator": "Es posterior",
        "filterBeforeOperator": "Es anterior",
        "filterBeforeOrEqualOperator": "Es anterior o igual a",
        "filterSubmitButton": "Filtrar",
        "filterClearButton": "Limpiar",
        "filterAndLogic": "Y",
        "filterOrLogic": "O",
        "filterTitle": "Filtros",
        "sortAscending": "Orden ascendente",
        "sortDescending": "Orden descendente"
    },
    "scheduler": {
        "editorValidationRequired": "El campo es obligatorio",
        "editorValidationStart": "La hora de inicio debe ser anterior a la hora de finalización",
        "editorValidationEnd": "La hora de finalización debe ser posterior a la hora de inicio",
        "allEvents": "Todos los eventos",
        "allDay": "todo el día",
        "today": "Hoy",
        "dayViewTitle": "Día",
        "weekViewTitle": "Semana",
        "monthViewTitle": "Mes",
        "workWeekViewTitle": "Semana laboral",
        "timelineViewTitle": "Línea de tiempo",
        "agendaViewTitle": "Agenda",
        "deleteTitle": "Eliminar",
        "previousTitle": "Anterior",
        "nextTitle": "Siguiente",
        "showFullDay": "Mostrar día completo",
        "showWorkDay": "Mostrar horario comercial",
        "editorOccurrence": "Editar ocurrencia actual",
        "editorSeries": "Editar la serie",
        "editorRecurringConfirmation": "¿Desea editar solo este evento o la serie completa?",
        "editorRecurringDialogTitle": "Editar elemento recurrente",
        "editorSave": "Guardar",
        "editorCancel": "Cancelar",
        "editorDelete": "Eliminar",
        "editorTitle": "Evento",
        "editorEventTitle": "Título",
        "editorEventStart": "Inicio",
        "editorEventStartTimeZone": "Zona horaria de inicio",
        "editorEventEnd": "Fin",
        "editorEventEndTimeZone": "Zona horaria final",
        "editorEventAllDay": "Evento de todo el día",
        "editorEventDescription": "Descripción",
        "editorEventSeparateTimeZones": "Finalizar en una zona horaria diferente",
        "editorEventTimeZone": "Especificar zona horaria",
        "recurrenceEditorRepeat": "Repetir",
        "recurrenceEditorDailyInterval": "día (s)",
        "recurrenceEditorDailyRepeatEvery": "Repetir cada",
        "recurrenceEditorWeeklyInterval": "week (s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repetir cada",
        "recurrenceEditorWeeklyRepeatOn": "Repetir en",
        "recurrenceEditorMonthlyDay": "Día",
        "recurrenceEditorMonthlyInterval": "mes (es)",
        "recurrenceEditorMonthlyRepeatEvery": "Repetir cada",
        "recurrenceEditorMonthlyRepeatOn": "Repetir en",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Repetir cada",
        "recurrenceEditorYearlyRepeatOn": "Repetir en",
        "recurrenceEditorYearlyInterval": "año (s)",
        "recurrenceEditorFrequenciesDaily": "Diario",
        "recurrenceEditorFrequenciesMonthly": "Monthly",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanal",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorOffsetPositionsFirst": "Primero",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Tercero",
        "recurrenceEditorOffsetPositionsFourth": "Cuarto",
        "recurrenceEditorOffsetPositionsLast": "Último",
        "recurrenceEditorWeekdaysDay": "Día",
        "recurrenceEditorWeekdaysWeekday": "Weekday",
        "recurrenceEditorWeekdaysWeekendday": "Día de fin de semana",
        "recurrenceEditorEndAfter": "Después",
        "recurrenceEditorEndOccurrence": "ocurrencias",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOn": "On",
        "deleteConfirmation": "¿Está seguro de que desea eliminar este evento?",
        "deleteRecurringConfirmation": "¿Desea eliminar solo este evento o la serie completa?",
        "deleteOccurrence": "Eliminar la ocurrencia actual",
        "deleteSeries": "Eliminar la serie",
        "deleteDialogTitle": "Eliminar evento",
        "deleteRecurringDialogTitle": "Eliminar elemento recurrente",
        "dateTitle": "Fecha",
        "timeTitle": "Tiempo",
        "eventTitle": "Evento",
        "noEvents": "sin eventos"
    }
}; 