import React, {useContext, useState, useEffect} from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Fade } from 'reactstrap'
import { UserContext } from '../../contexts/UserContextProvider'
import APIURL from '../../configs/APIConfig'
import axios from 'axios';

/**
* Component to add Compressor Morot
*/
const AddCompMotor = () => {
  //ts-migrate FIXME: investigate type of useContext to dont be "any"
  const { authToken } = useContext<any>(UserContext);

  const [manufacturers, setManufacturers] = useState('')
  const [manufacturerId, setManufacturerId] = useState('')
  const [motorModel, setMotorModel] = useState('')
  const [motorPower, setMotorPower] = useState('')
  const [motorVoltage, setMotorVoltage] = useState('')
  const [motorPhases, setMotorPhases] = useState('')

  const [message, setMessage] = useState('')

  /**
  * Axios (NPM) is used to send the 'Auth Token' through headers
  */
  const authAxios = axios.create({
    baseURL: APIURL.url,
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })

  /**
  * Fetch Manufacturers from the API.
  * Store data to 'manufacturer' state.
  */
  const fetchManufactuers= async () => {
    try{
    const result = await authAxios.get(`/manufacturers`)
      setManufacturers(result.data)
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  /**
  * Get user data from input fields and create an object 
  * to save to the DB.
  */
  const data = {
    manufacturer_id: manufacturerId,
    motor_model: motorModel,
    motor_power: motorPower,
    motor_voltage: motorVoltage,
    motor_phases: motorPhases
  }

  
  /**
  * Function to save Compressor Motor.
  * Error message will save in the 'message' state.
  */
  const postMotor = async (e: any) => {
    e.preventDefault();
    try{
    const result = await authAxios.post(`/motors`, data)
      setMessage(result.data)
      console.log(message);
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  useEffect(()=>{
    fetchManufactuers()
  }, [])

  /**
  * Input fields for Manufacturer.
  * Create input fields with drop-down options.
  */
  const manufacturerOptions = () => {
    return (
      <Input type="select" name="select" id="exampleSelect" required
        className="py-0" style={{height: "30px"}}
        onChange={e=>setManufacturerId(e.target.value)}>
         <option value =''>Select a manufacturer</option>
         {(() => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'string'.
          return manufacturers.map((manufacturer: any, i: any) => {
            return (
              <option key={'tab' + manufacturer.manufacturer_id + i} 
                value={manufacturer.manufacturer_id}>
                {manufacturer.manufacturer_name}
              </option>
            )
          });
        })()}
      </Input>
    );
  }


  return (
    <Container className="px-0">
      <Row className="px-0">
        <Col lg="7" className="px-0 mt-3">
          <h5 className="text-secondary">ADD COMPRESSOR MOTOR</h5>
          <hr/>
          <Fade>
          <Form className="mt-3 mb-4 px-0" onSubmit={postMotor}>
            <FormGroup>
              <Label for="mnufacturer-name" className="text-info">Motor Manufacturer</Label>
              {!manufacturers ? (
                <div className ="spinner-border text-dark mt-1" role="status">
                  <span className ="sr-only">Loading...</span>
                </div>
              ): manufacturerOptions()}
            </FormGroup>
            <FormGroup>
              <Label for="mnufacturer-name" className="text-info">Model Number</Label>
              <Input type="text" name="model-number" placeholder="ex: ABC-2021"
                style={{height: "30px"}}
                onChange={e=>setMotorModel(e.target.value)}/>
            </FormGroup>
            <FormGroup>
              <Label for="mnufacturer-name" className="text-info">Motor Power</Label>
              <Input type="text" name="motor-power" placeholder="ex: 200"
                style={{height: "30px"}}
                onChange={e=>setMotorPower(e.target.value)}/>
            </FormGroup>
                        <FormGroup>
              <Label for="mnufacturer-name" className="text-info">Motor Voltage</Label>
              <Input type="text" name="motor-voltage" placeholder="ex: 1000"
                style={{height: "30px"}}
                onChange={e=>setMotorVoltage(e.target.value)}/>
            </FormGroup>
                        <FormGroup>
              <Label for="mnufacturer-name" className="text-info">Motor Phases</Label>
              <Input type="text" name="motor-phases" placeholder="ex: 3"
                style={{height: "30px"}}
                onChange={e=>setMotorPhases(e.target.value)}/>
            </FormGroup>
                        <button className="btn btn-outline-success btn-block mt-1" 
              style={{height: "30px", fontSize: "16px", paddingTop: "4px"}}>
              <h6>SUBMIT</h6>
            </button>
          </Form>
          </Fade>
        </Col>
                <Col lg="5" className="pl-4 pr-0 mt-5">
                    <p className="pr-0 pl-2 text-dark">Select a table from the menu. If the table does 
            not exist, please create a table with corresponding attrubutes.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default AddCompMotor
