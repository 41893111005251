import { useLocation } from "react-router"
import { config } from "../Config"
import { UserContext } from '../contexts/UserContextProvider'
import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//test

const SigninToken = () => {
    const { getSessions, setUserStatus } = useContext<any>(UserContext);
    const history = useHistory();
    const location = useLocation()
    const code = new URLSearchParams(location.search).get("code")

    useEffect(() => {
        const params = new URLSearchParams()
        params.append("grant_type", "authorization_code")
        params.append("client_id", config.clientId)
        params.append("code", code)
        params.append("redirect_uri", config.redirectUri)

        const req = {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: params,
        }
        fetch(`https://${config.authDomain}/oauth2/token`, req)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem("token", JSON.stringify(data))
                localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + "." + code + ".refreshToken", data.refresh_token)
                localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + "." + code + ".idToken", data.id_token)
                localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + ".LastAuthUser", code)
                // window.location.href = "/"
            })
            .then(getSessions)
            .catch(err => console.log(err))
            .then(() => {
                history.push("/");
            }).catch(err => err)
    }, [code])

    return (
        <div>
            <h5>Signing in....</h5>
        </div>
    )
}

export default SigninToken