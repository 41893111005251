import React, {createContext, useEffect, useState, useContext} from 'react'
import { Site } from '../api/interfaces/site';
import { fetchSites } from '../api/sites/sites';
import { UserContext } from './UserContextProvider';
import { LocaleInterface } from '../api/interfaces/LocaleInterface';
  
// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const LocalContext = createContext();

const LocaleContextProvider = (props: any) => {
    const locales: LocaleInterface[] = [
        {
          language: "en-US",
          locale: "en",
          name: "English",
        },
        {
          language: "es-ES",
          locale: "es",
          name: "Spanish",
        },
        {
          language: "sv",
          locale: "sv",
          name: "Swedish",
        },
      ];

    const [currentLocale, setCurrentLocale] = React.useState<LocaleInterface>(
        locales[0]
    );

  return (
    <LocalContext.Provider value={{currentLocale, setCurrentLocale, locales}}>
      {props.children}
    </LocalContext.Provider>
  )
}
export default LocaleContextProvider
