import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Fade } from 'reactstrap'

const AddCompProperties = () => {
  const [propertyName, setPropertyName] = useState('')
  const [propertyType, setPropertyType] = useState('')

  return (
        <Container className="px-0">
            <Row className="px-0">
                <Col lg="7" className="px-0 mt-3">
                    <h5 className="text-secondary">ADD COMPRESSOR PROPERTY</h5>
                    <hr/>
                    <Fade>
                    <Form className="mt-3 mb-4 px-0">
                        <FormGroup>
                            <Label for="mnufacturer-name" className="text-info">Property Name</Label>
                            <Input type="text" name="property-name" placeholder="ex: ac frequency"
                style={{height: "30px"}}
                onChange={e=>setPropertyName(e.target.value)}/>
            </FormGroup>
                        <FormGroup>
                            <Label for="property-type" className="text-info">Property Type</Label>
                            <Input type="text" name="property-type" placeholder="ex: electrical"
                style={{height: "30px"}}
                onChange={e=>setPropertyType(e.target.value)}/>
            </FormGroup>
                        <button className="btn btn-outline-success btn-block mt-1" 
              style={{height: "30px", fontSize: "16px", paddingTop: "4px"}}>
                            <h6>SUBMIT</h6>
            </button>
          </Form>
          </Fade>
        </Col>
                <Col lg="5" className="pl-4 pr-0 mt-5">
                    <p className="pr-0 pl-2 text-dark">Select a table from the menu. If the table does 
            not exist, please create a table with corresponding attrubutes.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default AddCompProperties
