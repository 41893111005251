import {SigninCognito} from "./SigninCognito" 
import jwtDecode from "jwt-decode" 

 const UserSignin = () => {
    const tokenRaw = localStorage.getItem("token")
    let token, profile = false
    if (tokenRaw) {
        token = JSON.parse(tokenRaw)
        profile = jwtDecode(token.id_token)
    }
    //const { getSessions, setUserStatus, setAccessToken } = useContext(UserContext); 
 /*   const location = useLocation()
  const code = new URLSearchParams(location.search).get("code")
  
  useEffect(() => {
      const params = new URLSearchParams()
      params.append("grant_type", "authorization_code")
      params.append("client_id", config.clientId)
      params.append("code", code)
      params.append("redirect_uri", config.redirectUri)

      const req = {
          method: "POST",
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: params,
      }
      console.log(config)
      fetch(`https://${config.authDomain}/oauth2/token`, req)
          .then(response => response.json())
          .then(data => {
              localStorage.setItem("token", JSON.stringify(data))
              console.log(" data.id_token")
              console.log( data.id_token)
              localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + "." + code + ".accessToken", data.access_token)
              localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + "." + code + ".refreshToken", data.refresh_token)
              localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + "." + code + ".idToken", data.id_token) 
              localStorage.setItem("CognitoIdentityServiceProvider." + config.clientId + ".LastAuthUser", code) 
              localStorage.setItem("token", JSON.stringify(data))
             // setUserStatus(true);
             // setAccessToken(data);
              window.location.href = "/"
          })
         // .then(getSessions) 
  }, [code]) */ 
     return ( 
        <div>
            <SigninCognito profile={profile} />
        </div>
    ) 
}

export default UserSignin