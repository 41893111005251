import axios from 'axios';
import APIURL from '../configs/APIConfig';
import RefreshToken from "../common/RefreshToken"
import jwtDecode from "jwt-decode"
import { forceCast } from  '../common/castHelpers';
import { DecodedAuthToken } from '../api/interfaces/decoded_auth_token';
import { minutesUntilDate } from  '../common/dateHelpers';


/**
 * Getter helper for all API calls
 * This method doesn't return anything, it just sets the data in the setter (state)
 * @param authToken 
 * @param endpoint 
 * @param transformer 
 * @param dataSetter 
 * 
 */
export const getCall = async (authToken: string, endpoint: string, transformer: any, dataSetter: any) => {
    const decodedToken : DecodedAuthToken = forceCast<DecodedAuthToken>(jwtDecode(authToken));
    const expirationDateToken = new Date(1000*decodedToken.exp);
    const minutesUntilExpiration = minutesUntilDate(expirationDateToken);
    if(minutesUntilExpiration <= 10 && localStorage.getItem("refreshingTokenSemaphore") == "false") {
        localStorage.setItem("refreshingTokenSemaphore", "true");
        RefreshToken();
    } 
    const authAxios = axios.create({
        baseURL: APIURL.url,
        headers: {
            authorization: `Bearer ${authToken}`,
        },
    });

    try {
        let result = await authAxios.get(endpoint);
        if (transformer) {
            result = transformer(result);
        }
        if (dataSetter) { dataSetter(result); }
        console.log(`inside apicall to ${endpoint} ${JSON.stringify(result)}`); //leave the console log for development proposes

    } catch (err: any) {
        console.log('AXIOS ERROR', err.message);
        if(err.response && err.response.status == 401) {
            if(localStorage.getItem("refreshingTokenSemaphore") == "false") {
                localStorage.setItem("refreshingTokenSemaphore", "true");
                RefreshToken();
            }  
            window.location.reload();  
        }
    }
};

/**
 * Getter helper for all API calls
 * This method returns async call so the caller handles the response
 * @param authToken 
 * @param endpoint  
 * 
 */
 export const getCallAsync = async (authToken: string, endpoint: string) => {
    const decodedToken : DecodedAuthToken = forceCast<DecodedAuthToken>(jwtDecode(authToken));
    const expirationDateToken = new Date(1000*decodedToken.exp);
    const minutesUntilExpiration = minutesUntilDate(expirationDateToken);
    if(minutesUntilExpiration <= 10 && localStorage.getItem("refreshingTokenSemaphore") == "false") {
        localStorage.setItem("refreshingTokenSemaphore", "true");
        RefreshToken();
    } 
    const authAxios = axios.create({
        baseURL: APIURL.url,
        headers: {
            authorization: `Bearer ${authToken}`,
        },
    });

    try {
        return await authAxios.get(endpoint); 
    } catch (err: any) {
        console.log('AXIOS ERROR', err.message);
        if(err.response && err.response.status == 401) {
            if(localStorage.getItem("refreshingTokenSemaphore") == "false") {
                localStorage.setItem("refreshingTokenSemaphore", "true");
                RefreshToken();
            }  
            window.location.reload();  
        }
    }
};

/**
 * Post helper for all API calls
 * Returns id of new element
 * @param authToken 
 * @param endpoint 
 * @param data 
 */
export const postCall = async (authToken: string, endpoint: string, data: any) => {
    const decodedToken : DecodedAuthToken = forceCast<DecodedAuthToken>(jwtDecode(authToken));
    const expirationDateToken = new Date(1000*decodedToken.exp);
    const minutesUntilExpiration = minutesUntilDate(expirationDateToken);
    if(minutesUntilExpiration <= 10 && localStorage.getItem("refreshingTokenSemaphore") == "false") {
        localStorage.setItem("refreshingTokenSemaphore", "true");
        RefreshToken();
    } 
    const authAxios = axios.create({
        baseURL: APIURL.url,
        headers: {
            authorization: `Bearer ${authToken}`,
        },
    });

    try {
        return await authAxios.post(endpoint, data);
    } catch (err: any) {
        console.log('AXIOS ERROR', err.message);
        if(err.response && err.response.status == 401) {
            if(localStorage.getItem("refreshingTokenSemaphore") == "false") {
                localStorage.setItem("refreshingTokenSemaphore", "true");
                RefreshToken();
            }  
            window.location.reload();  
        }
    }
};

/**
 * Delete helper for all API calls
 * This method returns async call so the caller handles the response
 * @param authToken 
 * @param endpoint  
 * 
 */
 export const deleteCallAsync = async (authToken: string, endpoint: string) => {
    const decodedToken : DecodedAuthToken = forceCast<DecodedAuthToken>(jwtDecode(authToken));
    const expirationDateToken = new Date(1000*decodedToken.exp);
    const minutesUntilExpiration = minutesUntilDate(expirationDateToken);
    if(minutesUntilExpiration <= 10 && localStorage.getItem("refreshingTokenSemaphore") == "false") {
        localStorage.setItem("refreshingTokenSemaphore", "true");
        RefreshToken();
    }
    const authAxios = axios.create({
        baseURL: APIURL.url,
        headers: {
            authorization: `Bearer ${authToken}`,
        },
    });

    try {
        return await authAxios.delete(endpoint); 
    } catch (err: any) {
        console.log('AXIOS ERROR', err.message);
        if(err.response && err.response.status == 401) {
            if(localStorage.getItem("refreshingTokenSemaphore") == "false") {
                localStorage.setItem("refreshingTokenSemaphore", "true");
                RefreshToken();
            }  
            window.location.reload();  
        }
    }
};