import {createContext, useState} from 'react'

// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const SelectedCasContext = createContext();

const SelectedCasContextProvider = (props: any) => {
 
  const [selectedCas, setSelectedCas] = useState<number>();
 

  return (
    <SelectedCasContext.Provider value={{selectedCas, setSelectedCas}}>
      {props.children}
    </SelectedCasContext.Provider>
  )
}
export default SelectedCasContextProvider
