import * as React from "react";
import { useState, useContext } from "react";
import { UserContext } from "../contexts/UserContextProvider";
import { Button } from '@progress/kendo-react-buttons';
import { Container, Row, Col, Fade } from 'reactstrap';
import axios from "axios";
import APIURL from "../configs/APIConfig"
import AddCas from "../components/cas/AddCas";
import { useHistory } from 'react-router-dom';
import { useLocalization } from '@progress/kendo-react-intl';

const MyCASystem = () => {
    const history = useHistory();
    const localizationService = useLocalization();

    return (<>
        <AddCas></AddCas>
        <>
            <Row className="">
                <Col lg="12" className="px-0 mt-3" >
                    <h4 className="text-info" style={{ marginLeft: "12px", marginTop: "30px" }}>{localizationService.toLanguageString('custom.addElementsToYourCAS', '')}</h4>
                    <hr />
                </Col>
            </Row>
            <div className="k-form-buttons" style={{ marginTop: "10px" }}>
                <Button primary={true} onClick={() => {
                    history.push("/mypage")
                }}>{localizationService.toLanguageString('custom.compressors', '')}</Button>
            </div>
        </>
    </>)
};

export default MyCASystem;