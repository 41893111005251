import React, {useState} from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import AddCompData from './AddCompData'
import AddManufacturer from './AddManufacturer'
import AddCompType from './AddCompType'
import AddCompProperties from './AddCompProperties'
import AddCompMotor from './AddCompMotor'
import { useLocalization } from '@progress/kendo-react-intl';


const CreateComp = () => {
  const [rSelected, setRSelected] = useState(1);
  const localizationService = useLocalization();

  return (
        <Container>
            <Row>
                <Col lg="12" className="px-0 mt-3">
                    <h4 className="text-info">{localizationService.toLanguageString('custom.newCompressor', '')}</h4>
        </Col>
      </Row>
            <Row className="mb-5">
                <Col lg="4" className="px-0">
                    <div style={{marginTop:"11.5%"}}>
            {/* <div>
              <button className="btn btn-outline-info mt-1" 
                style={{height: "30px", width:"330px", fontSize: "16px", paddingTop: "4px"}}
                onClick={() => setRSelected(1)} active={rSelected}>
                <h6>Add Compressor Data</h6>
              </button>
            </div>
            <div className="mt-2">
              <button className="btn btn-outline-info mt-1" 
                style={{height: "30px", width:"330px", fontSize: "16px", paddingTop: "4px"}}
                onClick={() => setRSelected(2)} active={rSelected}>
                <h6>Add Manufacturer</h6>
              </button>
            </div> */}
            {/* <div className="mt-2">
              <button className="btn btn-outline-info mt-1" 
                style={{height: "30px", width:"330px", fontSize: "16px", paddingTop: "4px"}}
                onClick={() => setRSelected(3)} active={rSelected}>
                <h6>Add Comp Type</h6>
              </button>
            </div>  */}
            {/* <div className="mt-2">
              <button className="btn btn-outline-info mt-1" 
                style={{height: "30px", width:"330px", fontSize: "16px", paddingTop: "4px"}}
                onClick={() => setRSelected(4)} active={rSelected}>
                <h6>Add Comp Property</h6>
              </button>
            </div>  */}
            {/* <div className="mt-2">
              <button className="btn btn-outline-info mt-1" 
                style={{height: "30px", width:"330px", fontSize: "16px", paddingTop: "4px"}}
                onClick={() => setRSelected(5)} active={rSelected}>
                <h6>Add Comp Motor</h6>
              </button>
            </div>  */}
          </div>        
        </Col>  
        {/* <Col lg="5" className="pl-4 pr-0 mt-5">
          <p className="pr-0 pl-2 text-dark">Select a table from the menu. If the table does 
            not exist, please create a table with corresponding attrubutes.
          </p>
        </Col> */}
                <Col lg="10">
                    <>
            {(() => {
              if (rSelected === 2) {
                return (
                                    <AddManufacturer/>
                )
              } else if (rSelected === 3) {
                return (
                                    <AddCompType/>
                )
              } else if (rSelected === 4) {
                return (
                                    <AddCompProperties />
                )
              } else if (rSelected === 5) {
                return (
                                    <AddCompMotor/>
                )
              } else {
                return (                  
                                    <AddCompData/>                  
                )
              }
            })()}
          </>
        </Col>
      </Row>      
    </Container>
  )
}

export default CreateComp
