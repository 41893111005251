import React, {useContext, useState, useEffect} from 'react'
import { withRouter, Link } from 'react-router-dom';
import { AppBar, AppBarSection, AppBarSpacer, Avatar, Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import TopbarMenu from './TopbarMenu';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
 import { LocalContext } from '../../contexts/LocaleContextProvider';
import { SiteContext } from "../../contexts/SiteContextProvider";
import { fetchCaSystemsAsync } from "../../api/cas/cas";
import { UserContext } from "../../contexts/UserContextProvider";
import { Cas } from '../../api/interfaces/cas';

/**
* Menu Component.
* All routes are declared here. 
*/

const CustomItem = (props: any) => {
  const {
    visible,
    ...others
  } = props;
  const arrowDir = props['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
  return <React.Fragment>
    {props.visible === false ? null : <DrawerItem {...others}>
      <span className={'k-icon ' + props.icon} />
      <span className={'k-item-text'}>{props.text}</span>
      {props['data-expanded'] !== undefined && <span className={"k-icon " + arrowDir} style={{
        position: "absolute",
        right: 10
      }} />}
    </DrawerItem>}
  </React.Fragment>;
};

const LocalizedContent = (props) => {
  const localizationService = useLocalization();
  const { authToken } = useContext<any>(UserContext);
  const [cas, setCas] = useState<Cas[]>([]);

  useEffect(() => {
      if (props.currentSite) {
        fetchCaSystemsAsync(authToken, props.currentSite.id)
        .then((response) => {
          if(response != undefined && response != null && Object.keys(response).includes("data")) {
            const { data } = response   
            if (data && data.length==0) { 
              setItems(items.filter((item) => item.route != '/mypage'));
            } else {
              setItems(itemsOriginal);
            }
          } 
        }) 
       }
  }, [props.currentSite]);

   const [items, setItems] = useState([
    {
      text: localizationService.toLanguageString('custom.profile', ''),
      route: '/profile'
    },
    {
      separator: true
    },
    {
      text: localizationService.toLanguageString('custom.sites', ''),
      route: '/sites'
    },
    {
      separator: true
    },
    {
      text: localizationService.toLanguageString('custom.mycas', ''),
      route: '/mycasystem'
    },
    {
      text: Array(25).fill('\xa0').join('') + localizationService.toLanguageString('custom.compressors', ''),
      route: '/mypage'
    }
  ]);

  const [itemsOriginal, setItemsOriginal] = useState([...items]);

  const onSelect = (e: any) => {
    props.history.push(e.itemTarget.props.route);
  };

  const setSelectedItem = (pathName: any) => {
    let currentPath = items.find(item => item.route === pathName);

    if (!!currentPath && currentPath.text) {
      return currentPath.text;
    }
  };
  const selected = setSelectedItem(props.pathname);

  return (
    <Drawer expanded={props.expanded} position={'start'} mode={'push'} width={300} items={items.map(item => ({
      ...item,
      selected: item.text === selected
    }))} onSelect={onSelect}>
      <DrawerContent>
        {props.children}
      </DrawerContent>
    </Drawer>
  );
};

const DrawerContainer = (props: any) => {
  const localizationService = useLocalization();
  const [expanded, setExpanded] = useState(true);
  const { currentLocale } = useContext<any>(LocalContext);
  const { currentSite } = useContext<any>(SiteContext);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return <div>
          <div className="custom-toolbar">
        <Button icon="menu" onClick={handleClick}  />
        <span  className="title" id="burguer-menu">{localizationService.toLanguageString('custom.menu', '')}</span>
      </div>
      <LocalizedContent currentSite={currentSite} children={props.children} expanded={expanded} pathname={props.location.pathname} history={props.history} key={currentLocale.locale}></LocalizedContent>
  </div >;
};

export default withRouter(DrawerContainer);