import React, { createContext, useState } from 'react'
// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
export const EventContext = createContext();

const EventContextProvider = (props: any) => {
  const [eventDetail, setEventDetail] = useState();

  const [dispEventDetail, setDispEventDetail] = useState(false);
  const dispEvents = () => setDispEventDetail(!dispEventDetail);

  const [dispEditEvent, setDispEditEvent] = useState(false);
  const dispEventToEdit = () => setDispEditEvent(!dispEditEvent);

  const values = {
    eventDetail,
    setEventDetail,
    dispEvents,
    dispEventDetail,
    dispEventToEdit,
    dispEditEvent
  }

  return (
    <EventContext.Provider value={values}>
      {props.children}
    </EventContext.Provider>
  )
}

export default EventContextProvider
