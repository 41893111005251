import { getCall, getCallAsync, postCall } from '../apiHelpers';
import { Site } from '../interfaces/site';


export const fetchSites = (authToken: string) =>
getCallAsync(authToken,`/sites`)

export const fetchSite = (authToken: string, site_id: number, transformer: any, setter: any) =>
getCall(authToken,`/sites/${site_id}`, transformer, setter)

export const updateSite = (authToken: string, site_id: number, data) =>
postCall(authToken, `/sites/${site_id}`, data )

export const createSite = (authToken: string, data) =>
postCall(authToken, `/sites`, data )

export const fetchUserSites = (authToken: string, transformer: any, setter: any) =>
getCall(authToken,`/sites`, transformer, setter)

export const listSiteFiles = (authToken: string, site_id: number, transformer: any, setter: any) =>
getCall(authToken,`/sites/${site_id}/file`, transformer, setter)

