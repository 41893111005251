import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../../contexts/UserContextProvider'
import { EventContext } from '../../contexts/EventContextProvider'
import { Container, Row, Col, Fade } from 'reactstrap'
import Spinner from '../../images/loading.gif'
import APIURL from '../../configs/APIConfig'
import axios from 'axios';
import moment from 'moment'

/**
* Display all Customer Events as list.
*/
const EventList = () => {
  //ts-migrate FIXME: investigate type of useContext to dont be "any"
  const {authToken} = useContext<any>(UserContext);
  const {setEventDetail, dispEvents} = useContext<any>(EventContext);

  const [userEvents, setUserEvents] = useState('');

  // Axios send the auth tokent to access the API
  const authAxios = axios.create({
    baseURL: APIURL.url,
    headers: {
      authorization: `Bearer ${authToken}`
    }
  })

  //Fetch all the customer saved raw-data
  const fetchUserEvents = async () => {
    try{
    const result = await authAxios.get(`/customerevents`)
      setUserEvents(result.data)
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  //Fetch a single raw-data.
  const fetchUserEvent = async (id: any) => {
    try{
    const result = await authAxios.get(`/customerevents/${id}`)
      setEventDetail(result.data)
      dispEvents()
    }catch(err: any){
      console.log("AXIOS ERROR", err.message);
    }
  }

  useEffect(()=>{
    fetchUserEvents()
  }, [])

  /**
  * Convert the timestamp as readable date and time format
  */
  const formatTime = (time: any) => {
    return moment(time).format("YYYY-MM-DD, HH:mm")
  }

  /**
  * Iterate all the event values and display as row.
  */
  const eventValues = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'string'.
    return userEvents.map((event: any, i: any) => {
       let color; i%2===0?color='#FAFAFA':color='rgb(209 255 206)'   
      return (
        <div key={'comp' + event.user_event_id+ i} className="d-flex mt-1 data-row" 
          style={{cursor: 'pointer'}} onClick={()=> fetchUserEvent(event.event_id)}>
          <div className="ml-1" style={{background:color}} >
            <h6 className="px-2 pt-2" style={{width: "65px"}}>{i+1}</h6>
          </div>
          <div className="ml-1" style={{background:color}}>
            <h6 className="px-2 pt-2" style={{width: "165px"}}>{formatTime(event.created_at)}</h6>
          </div>
          <div className="ml-1" style={{background:color}}>
            <h6 className="px-2 pt-2" style={{width: "280px", overflow: "hidden", whiteSpace: 'nowrap'}}>{event.customer_id}</h6>
          </div>
          <div className="ml-1" style={{background:color}}>
            <h6 className="pl-2 pt-2" style={{width: "360px"}}>{event.action_desc}</h6>
          </div>
          <hr className="mt-1"/>
        </div>    
      )
    });
  }

  /**
  * Display the keys (hard coded) according to the API.
  */
  const EventKeys = () => {
    return(
      <div className="event-detail-top-text d-flex ">
        <div style={{width: "65px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Index</h5>
        </div>
        <div style={{width: "165px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Created At</h5>
        </div>
        <div style={{width: "280px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Customer Email</h5>
        </div>
        <div style={{width: "360px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Event Description</h5>
        </div>
        <div style={{width: "100px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Verified</h5>
        </div>
        <div style={{width: "321px"}} className="pt-2 ml-1 event-detail-top-bg pl-2 rounded">
          <h5>Verified By</h5>
        </div>
      </div>
    )
  }

  return (
    <Container>
      <Row>
        <Col lg="12" className="px-0 mb-3 pb-3" 
          style={{overflowX: 'scroll', border:"2px solid #ddd", borderRadius: "5px"}}>
          <div className="px-0" style={{width: "1320px", maxHeight: "50vh"}}>
            {!userEvents?
              (<div className="d-flex justify-content-center">
                <img src={Spinner} alt="Loading..." width="30%"/>
              </div>):
              (<Fade>
                <div className="py-1">
                  <EventKeys/>   
                </div>
                <div>
                  {userEvents && eventValues()}
                </div>
            </Fade>)}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default EventList
