import React from 'react'
import splash from './../images/splash_Q2.jpg';

const Home = () => {
  return (
    <div className="splashContainer">
        <img className="splashImage" src={splash} alt="vision" />
    </div>


  )
}

export default Home
