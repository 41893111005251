import * as React from "react";
import { Dialog, DialogActionsBar, Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import AddCompProperty from "./AddCompBrand"
import axios from "axios";
import APIURL from "../../configs/APIConfig";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import { addBrand } from '../../api/brands/brands';
import { Brand } from "../../api/interfaces/brands";
import { useLocalization } from '@progress/kendo-react-intl';


/**
 * Component for the creation of a new compressor brand and new compressor model
 */

interface AddCompPropertiesProps {
    dialogTitle: string;
    fetchBrands: any;
    fetchCompModelData: any;
    toggleDialog: any;
    label: string;
    onInsert: any;
}

const AddCompProperties = (props: AddCompPropertiesProps) => {
    const localizationService = useLocalization();
    const { authToken } = useContext<any>(UserContext);
    const dialogTitle = props.dialogTitle;
    const fetchBrands = props.fetchBrands;
    const fetchCompModelData = props.fetchCompModelData;
    const toggleDialog = props.toggleDialog;
     

    const [compNewBrandName, setCompNewBrandName] = useState('');
    const [compNewModelName, setCompNewModelName] = useState('');

    return (
        <div>

            <Dialog title={dialogTitle} onClose={toggleDialog} width={500} height={300}>
                <div
                    style={{
                        margin: "25px",
                        textAlign: "center",
                        zIndex: 10003

                    }}

                >
                    <AddCompProperty label={props.label} inputValue={compNewBrandName}
                        setInputValue={setCompNewBrandName} />
                </div>
                <DialogActionsBar>
                    <p style={{ textAlign: "center" }}>
                        <button style={{ margin: "25px", }} className="k-button" onClick={() => {
                            props.onInsert(compNewBrandName);
                            toggleDialog();}}>
                            {localizationService.toLanguageString('custom.add', '')}
                        </button>
                        <button style={{ margin: "25px", }} className="k-button" onClick={toggleDialog}>
                            {localizationService.toLanguageString('custom.cancel', '')}
                        </button>
                    </p>
                </DialogActionsBar>
            </Dialog>

        </div>

    );

};

export default AddCompProperties;