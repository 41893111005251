import React, { useContext, useEffect } from 'react';
import { withRouter, Link, useHistory, NavLink } from 'react-router-dom';
import { AppBar, AppBarSection, AppBarSpacer, Avatar, Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import CompanyLogo from '../../images/enersize_logo.png';
import avatarprofile from '../../images/icon.png'
import UserContextProvider, { UserContext } from '../../contexts/UserContextProvider';
import { SiteContext } from '../../contexts/SiteContextProvider';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import  { LocalContext }  from '../../contexts/LocaleContextProvider';
import { useLocalization } from '@progress/kendo-react-intl';

/**
* Top bar Component.
* All routes are declared here. 
*/

const TopbarMenu = () => {
    //ts-migrate FIXME: investigate better type for useContext
    const { sites, currentSite } = useContext<any>(SiteContext)
    const { getSessions, currentUser, logout } = useContext<any>(UserContext);
    const { currentLocale, setCurrentLocale, locales } = useContext<any>(LocalContext);
    const localizationService = useLocalization();

    const history = useHistory();


    const navigateTo = (route: any) => {
        history.push(route);
    }

    useEffect(() => {
        getSessions()
    }, [])

    return <div>
        <AppBar>
            <Link to="/" className="navbar-brand">
                <img src={CompanyLogo} alt="Enersize-Logo" width="140" />
            </Link>

            <AppBarSpacer />

            <AppBarSection>
                {!currentUser &&
                    <ul className="ul-menu" style={{ alignItems: "baseline" }}>
                        <li><Button primary={true} onClick={() => navigateTo('/main-signin')} style={{ marginLeft: "225px", marginBottom: 10, marginTop: 20 }}>Sign In</Button></li>
                        <li ><div >{localizationService.toLanguageString('custom.needAnAccount', '')}: <a href="mailto:early_adopter@enersize.com">early_adopter@enersize.com</a></div></li>

                    </ul>}

                {currentUser &&
                    <ul className="ul-menu" style={{ display: "contents" }}>
                        <li className="site-name">
                            <NavLink
                                className="navbar-item"
                                activeClassName="is-active"
                                style={{ color: "black" }}
                                to="/sites"
                                exact
                            >{localizationService.toLanguageString('custom.site', '')}: {(sites) ? currentSite?.name : "Select a site"}
                            </NavLink>
                        </li>
                        <li>
                        {localizationService.toLanguageString('custom.locale', '')}:&nbsp;&nbsp;&nbsp;
                            <DropDownList
                                value={currentLocale}
                                textField="name"
                                onChange={(e) => {
                                    setCurrentLocale(e.target.value);
                                }}
                                data={locales}
                            />
                        </li>
                        <li className="avatar-container"><Avatar className="user-avatar" shape="circle" type="image">
                            <img src={avatarprofile} />
                        </Avatar></li>
                        <li><Button primary={true} onClick={logout}>Sign Out</Button></li>
                    </ul>}
            </AppBarSection>

        </AppBar>
    </div>
};

export default TopbarMenu;