
import React, { useContext, useState, useEffect, SyntheticEvent } from 'react'
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { load } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import { process } from "@progress/kendo-data-query";
import { UserContext } from '../../contexts/UserContextProvider'
import { fetchCompressorTypes } from '../../api/compressor_types.ts/compressor_types';
import { SiteContext } from '../../contexts/SiteContextProvider';
import { fetchCaSystems, fetchCompressorsFromCas, fetchCas } from '../../api/cas/cas';
import { fetchCompressorCoolings } from '../../api/compressor_coolings/compressor_coolings';
import { fetchCompressorRegulations } from '../../api/compressor_regulations/compressor_regulations';
import { SiteCompressor } from '../../api/interfaces/site_compressor';
import { updateSiteCompressor, fetchAllSiteCompressors, deleteSiteCompressor } from '../../api/compressors_site/compressors_site';
import { Dropdown } from '../../api/interfaces/Dropdown';
import { fetchBrands } from '../../api/brands/brands';
import { fetchTemplateCompressors } from '../../api/compressors_template/compressors_template';
import UploadFiles from '../uploadfiles/UploadFiles';
import { CompressorFiles } from '../../api/interfaces/compressor_files';
import { listCompFiles } from '../../api/compressor_site_files/compressor_site_files';
import { FileCompressor } from '../../api/interfaces/file_compressor';
import DisplayFiles from '../uploadfiles/DisplayFiles';
import { STATUS } from "../../constants/Status"
import { useLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { tryParseFloat, tryParseInt } from '../../common/castHelpers';

import { fetchUnits } from '../../api/units/units';
import { Property } from '../../api/enums/property';
import { Unit } from '../../api/interfaces/unit';
import { Button } from '@progress/kendo-react-buttons';
import { Cas } from '../../api/interfaces/cas';
import { SelectedCasContext } from '../../contexts/SelectedCasContextProvider';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

const gridWidth = 600;

const setPercentage = (percentage: any) => {
  return Math.round(gridWidth / 100) * percentage;
};
const DetailComponent = (props: any) => {
  const dataItem = props.dataItem;
  const localizationService = useLocalization();

  return (
    <div>
      <section
        style={{
          width: "200px",
          float: "left",
        }}
      >
        <p>
          <strong>{localizationService.toLanguageString('custom.nickname', '')}:</strong> {dataItem.nickname}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.type', '')}:</strong> {dataItem.type}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.brand', '')}:</strong> {dataItem.brand}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.model', '')}:</strong> {dataItem.model}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.output', '')}:</strong> {dataItem.output}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.powerSupply', '')}:</strong> {dataItem.power_supply}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.cooling', '')}:</strong> {dataItem.cooling}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.motorPower', '')}:</strong> {dataItem.motor_power}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.pressure', '')}:</strong> {dataItem.pressure}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.regulation', '')}:</strong> {dataItem.regulation}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.serialNumber', '')}:</strong> {dataItem.serial_number}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.yearManufactured', '')}:</strong> {dataItem.year}
        </p>
        <p>
          <strong>{localizationService.toLanguageString('custom.comment', '')}:</strong> {dataItem.comment}
        </p>
      </section>
      {/* <Grid
        style={{
          width: gridWidth,
        }}
        data={dataItem.client.users}
      /> */}
    </div>
  );
};

/**
* 'DISPLAY COMPRESSORS' is the Component to display other two components.
* Display either 'CompList' or 'CompDetail'.
*/
const DisplayComp = (props: any) => {
  const { authToken } = useContext<any>(UserContext);
  const { sites, currentSite } = useContext<any>(SiteContext);
  const [compressors, setCompressors] = useState('');
  const [compAirSystemData, setCompAirSystemData] = useState([]);
  const [compAirSystemId, setcompAirSystemId] = useState<Dropdown>({ id: -1, text: "" });
  const localizationService = useLocalization();


  const transformData = (result: any) => {
    for (let i = 0; i < result.data.length; i++) {
      result.data[i].numberElement = i + 1;
    }
    setNewData(result.data)
    return result.data;
  }

  const fetchCompressors = async (id: any) => {
    if (id == null || id == undefined) {
      return;
    }
    fetchCompressorsFromCas(authToken, id, currentSite.id, transformData, setCompressors)
  }

  useEffect(() => {
    fetchCaSystems(authToken, currentSite.id, (d: any) => d.data.map((e: any) => ({
      text: e.nickname,
      id: e.id,
      status: e.status
    })), setCompAirSystemData)
    fetchCompressorTypes(authToken, (d: any) => d.data.map((e: any) => ({ text: e.name, id: e.id })), setCompTypes)
    fetchCompressorRegulations(authToken, (d: any) => d.data.map((e: any) => ({ text: e.name, id: e.id })), setCompRegulations)
    fetchCompressorCoolings(authToken, (d: any) => d.data.map((e: any) => ({ text: e.name, id: e.id })), setCompCoolings)
    fetchTemplateCompressors(authToken, currentSite.id, (d: any) => d.data, setCompressorsTemplateData)
    fetchBrands(authToken, (d: any) => d.data.map((e: any) => ({ text: e.name, id: e.id })), setCompBrandData)
  }, [])

  useEffect(() => {
    if (selectedCas > 0) {
      const selectedCasDropdown: Dropdown = compAirSystemData.find(item => item.id == selectedCas);
      setcompAirSystemId(selectedCasDropdown);
    }
  }, [compAirSystemData])

  const setNewData = (newData: any) => {
    setDataResult(process(newData, dataState));
  }

  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [
      {
        field: "nickname",
        dir: "desc",
      },
    ]
  });
  const [dataResult, setDataResult] = React.useState(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    process(compressors, dataState)
  );

  const dataStateChange = (event: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setDataResult(process(compressors, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: any) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({ ...dataResult });
  };

  let _pdfExport: any;

  const exportExcel = () => {
    _export.save();
  };

  let _export: any;

  const exportPDF = () => {
    _pdfExport.save();
  };

  const [visibleDialog, setVisibleDialog] = React.useState(false);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  /**units Data */
  const [unitsPower, setUnitsPower] = React.useState<[Unit]>()
  const [unitsPressure, setUnitsPressure] = React.useState<[Unit]>()
  const [unitsMotorPower, setMotorPower] = React.useState<[Unit]>()
  const [unitsOutput, setUnitsOutput] = React.useState<[Unit]>()
  const [unitsPressureSelected, setUnitsPressureSelected] = React.useState<Unit>()
  const [unitsPowerSelected, setUnitsPowerSelected] = React.useState<Unit>()
  const [unitsMotorPowerSelected, setUnitsMotorPowerSelected] = React.useState<Unit>()
  const [unitsOutputSelected, setUnitsOutputSelected] = React.useState<Unit>()

  useEffect(() => {
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsPower, Property.GENERIC_POWER_SUPPLY);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsPressure, Property.GENERIC_PRESSURE);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setMotorPower, Property.GENERIC_MOTOR_POWER);
    fetchUnits(authToken, (d: any) => d.data.map((e: any) => ({ symbol: e.symbol, id: e.id })), setUnitsOutput, Property.GENERIC_OUTPUT);

  }, [])





  const [compTypes, setCompTypes] = useState([])
  const [compType, setCompType] = useState<Dropdown>({ id: -1, text: "" })
  const [compNickname, setCompNickname] = useState('')
  const [compSerialNo, setCompSerialNo] = useState('')
  const [compPressure, setCompPressure] = useState<number>()
  const [compRegulation, setCompRegulation] = useState<Dropdown>({ id: -1, text: "" })
  const [compRegulations, setCompRegulations] = useState([])
  const [compCooling, setCompCooling] = useState<Dropdown>({ id: -1, text: "" })
  const [compCoolings, setCompCoolings] = useState([])
  const [compYear, setCompYear] = useState<Dropdown>({ id: -1, text: "" })
  const [compPowerSupply, setCompPowerSupply] = useState<number>()
  const [message, setMessage] = useState('')
  const [compMotorPower, setCompMotorPower] = useState<number>()
  const [compOutput, setCompOutput] = useState<number>()
  const [selectedCompressor, setSelectedCompressor] = useState<SiteCompressor>({} as SiteCompressor)
  const [compBrandData, setCompBrandData] = useState([])
  const [compBrand, setCompBrand] = useState<Dropdown>({ id: -1, text: "" })
  const [compModelData, setCompModelData] = useState([])
  const [compModel, setCompModel] = useState('')
  const [compressorsTemplateData, setCompressorsTemplateData] = useState([])
  const [compressorModelSelected, setCompressorModelSelected] = useState([])
  const [compressorComment, setCompressorComment] = useState('')
  const [fileName, setFileName] = React.useState("");
  const [currentFiles, setCurrentFiles] = React.useState<Array<FileCompressor>>([]);
  const [visibleDialogConfirmationDelete, setVisibleDialogConfirmationDelete] = React.useState(false);

  const [compFrequency, setCompFrequency] = useState<number>()
  const [powerFactorLoaded, setPowerFactorLoaded] = useState<number>()
  const [powerFactorOffload, setPowerFactorOffload] = useState<number>()
  const [totalPowerOffload, setTotalPowerOffload] = useState<number>()
  const [totalPowerLoaded, setTotalPowerLoaded] = useState<number>()
  const [controlSystem, setControlSystem] = useState<boolean>()

  const startingYear = 2001;
  const years = Array.from(new Array(22), (val, index) => ({ text: (index + startingYear).toString(), id: index } as Dropdown));
  const { selectedCas } = useContext<any>(SelectedCasContext);

  let newCompressorFilesForApi: CompressorFiles = {
    id: null,
    site_compressor_id: selectedCompressor.id,
    name: fileName,
    site_id: currentSite.id
  };


  const newData: SiteCompressor = {
    id: selectedCompressor.id,
    nickname: compNickname,
    output: compOutput && tryParseFloat(compOutput.toString()) || null,
    output_unit_id: unitsOutputSelected?.id || null,
    output_unit_symbol: unitsOutputSelected?.symbol || null,
    pressure: compPressure && tryParseFloat(compPressure.toString()) || null,
    pressure_unit_id: unitsPressureSelected?.id || null,
    pressure_unit_symbol: unitsPressureSelected?.symbol || null,
    motor_power: compMotorPower && tryParseFloat(compMotorPower.toString()) || null,
    motor_power_unit_id: unitsMotorPowerSelected?.id || null,
    motor_power_unit_symbol: unitsMotorPowerSelected?.symbol || null,
    serial_number: compSerialNo,
    year: compYear.text,
    cooling: compCooling.text,
    type: compType.text,
    regulation: compRegulation.text,
    power_supply: compPowerSupply && tryParseInt(compPowerSupply.toString()) || null,
    power_supply_unit_id: unitsPowerSelected?.id || null,
    power_supply_unit_symbol: unitsPowerSelected?.symbol || null,
    brand: compBrand.text,
    model: compModel,
    cas_id: selectedCompressor.cas_id,
    type_id: compType.id,
    regulation_id: compRegulation.id,
    brand_id: compBrand.id,
    cooling_id: compCooling.id,
    template_compressor_id: selectedCompressor.template_compressor_id,
    site_id: currentSite?.id,
    file_number: selectedCompressor.file_number,
    comment: compressorComment,
    status: selectedCompressor.status,
    frequency: compFrequency || null,
    power_factor_loaded: powerFactorLoaded || null,
    power_factor_offload: powerFactorOffload || null,
    total_power_loaded: totalPowerLoaded || null,
    total_power_offload: totalPowerOffload || null,
    control_system: controlSystem || null

  }

  /**
  * Function to save Compressor Data.
  * Error message will save in the 'message' state.
  */
  const postCompressorSave = async (e: any) => {
    const dataWithStatus: SiteCompressor = { ...newData }
     updateSiteCompressor(authToken, currentSite.id, selectedCompressor.id, dataWithStatus)
 
       .then((response) => {
         if (response != undefined &&
           response != null &&
           response.data
           && response.data.success) {
           setMessage(response.data)
           console.log(message);
           fetchCompressors(compAirSystemId.id)
         }
       })
     toggleDialog()
  }

  const uniqueElementBrand = (arr: any) => {
    var resultBrand = arr.filter(function (this: any, a: any) {
      var key = a.brand_id + '|' + a.brand;
      if (!this[key]) {
        this[key] = true;
        return true;
      }
    }, Object.create(null));
    const newresult = resultBrand.map((e: any) => ({
      text: e.brand,
      id: e.brand_id
    }));
    return newresult
  }

  const uniqueElementModel = (arr: any) => {
    var resultModel = arr.filter(function (this: any, a: any) {
      var key = a.model;
      if (key != undefined && key != null && !this[key]) {
        this[key] = true;
        return true;
      }
    }, Object.create(null));
    const newresult = resultModel.map((e: any) => e.model);
    return newresult
  }


  const finishUploadCallback = () => {
    listCompFiles(authToken, selectedCompressor.id, currentSite.id, (d: any) => d.data.map((e: any) => e), setCurrentFiles)
  };

  const integerValidationMessage = "Only numbers with no decimals"
  const floatValidationMessage = "Only numbers or decimals with dot"

  const onSubmit = (values: {
    [name: string]: any;
  }, event?: React.SyntheticEvent<any, Event>) => {
    postCompressorSave(event);
  }

  const deleteCompressor = () => {
    deleteSiteCompressor(authToken, currentSite.id, selectedCompressor.id)
      .then(() => {
        fetchCompressorsFromCas(authToken, compAirSystemId.id, currentSite.id, transformData, setCompressors);
        toggleDialogConfirmationDelete();
        toggleDialog();
      })
  };

  const [casIsBlocked, setCasIsBlocked] = useState<boolean>(false)

  const checkIfBlockCAS = (cas_status: any) => {
    if (cas_status == STATUS.PUBLISHED_WAITING_FOR_APPROVAL) {
      setCasIsBlocked(true)
    } else {
      setCasIsBlocked(false)
    }
  }

  useEffect(() => {
    if (compAirSystemData.some(item => item.text === null)) {
      setCompAirSystemData(oldData => oldData.filter(item => item.text !== null));
    }
  }, [compAirSystemData])

  useEffect(() => {
    if (compAirSystemId && compAirSystemId.id) {
      fetchCompressors(compAirSystemId.id);
    }
  }, [compAirSystemId])

  const toggleDialogConfirmationDelete = () => {
    setVisibleDialogConfirmationDelete(!visibleDialogConfirmationDelete);
  };

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="compressed-air-system" className="text-info"> {localizationService.toLanguageString('custom.compressedAirSystem', '')} </label>&nbsp;&nbsp;
        <DropDownList
          value={compAirSystemId}
          textField="text"
          dataItemKey="id"
          onChange={(e) => {
            const airSystemId = e.target.value;
            setcompAirSystemId(e.target.value);
            checkIfBlockCAS(airSystemId.status);
          }}
          data={compAirSystemData}
        />
      </div>
      {!casIsBlocked &&
        <div>
          <ExcelExport
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'any[] | u... Remove this comment to see the full error message
            data={compressors}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
            <Grid
              style={{
                height: "700px",
              }}
              sortable={true}
              filterable={true}
              reorderable={true}
              onRowClick={(e) => {
                setSelectedCompressor(e.dataItem)
                setCompType({ id: e.dataItem.type_id, text: e.dataItem.type })
                setCompNickname(e.dataItem.nickname)
                setCompSerialNo(e.dataItem.serial_number)
                setCompPressure(e.dataItem.pressure)
                setCompRegulation({ id: e.dataItem.regulation_id, text: e.dataItem.regulation })
                setCompCooling({ id: e.dataItem.cooling_id, text: e.dataItem.cooling })
                setCompressorComment(e.dataItem.comment)
                var year = years.filter(function (el) {
                  return el.text == e.dataItem.year;
                });
                if (year.length > 0) {
                  setCompYear(year[0])
                }
                setCompPowerSupply(e.dataItem.power_supply)
                setCompMotorPower(e.dataItem.motor_power)
                setCompOutput(e.dataItem.output)
                setCompBrand({ id: e.dataItem.brand_id, text: e.dataItem.brand })
                const brandIdModels = compressorsTemplateData.filter(function (elem) {
                  return (elem.brand_id == e.dataItem.brand_id)
                });
                const uniqueItemsModel = uniqueElementModel(brandIdModels);
                setCompModelData(uniqueItemsModel);
                setCompModel(e.dataItem.model);
                listCompFiles(authToken, e.dataItem.id, currentSite.id, (d: any) => d.data.map((e: any) => e), setCurrentFiles)
                setUnitsMotorPowerSelected({ symbol: e.dataItem.motor_power_unit_symbol, id: e.dataItem.motor_power_unit_id })
                setUnitsOutputSelected({ id: e.dataItem.output_unit_id, symbol: e.dataItem.output_unit_symbol })
                setUnitsPowerSelected({ id: e.dataItem.power_supply_unit_id, symbol: e.dataItem.power_supply_unit_symbol })
                setUnitsPressureSelected({ id: e.dataItem.pressure_unit_id, symbol: e.dataItem.pressure_unit_symbol })
                setCompFrequency(e.dataItem.frequency)
                setPowerFactorLoaded(e.dataItem.power_factor_loaded)
                setPowerFactorOffload(e.dataItem.power_factor_offload)
                setTotalPowerLoaded(e.dataItem.total_power_loaded)
                setTotalPowerOffload(e.dataItem.total_power_offload)
                setControlSystem(e.dataItem.control_system)
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                toggleDialog(e.target.value);
              }}
              pageable={{
                buttonCount: 4,
                pageSizes: true,
              }}
              data={dataResult}
              {...dataState}
              onDataStateChange={dataStateChange}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}
            >
              <GridToolbar>
                &nbsp;&nbsp;&nbsp;
                <button
                  title={localizationService.toLanguageString('custom.exportExcel', '')}
                  className="k-button k-primary"
                  onClick={exportExcel}
                >
                  {localizationService.toLanguageString('custom.exportExcel', '')}
                </button>
                &nbsp;
                <button className="k-button k-primary" onClick={exportPDF}>
                  {localizationService.toLanguageString('custom.exportPDF', '')}
                </button>
              </GridToolbar>
              <GridColumn field="numberElement" title={localizationService.toLanguageString('custom.number', '')} editable={false} width={setPercentage(20)} />
              <GridColumn field="nickname" title={localizationService.toLanguageString('custom.nickname', '')} width={setPercentage(20)} />
              <GridColumn field="brand" title={localizationService.toLanguageString('custom.brand', '')} width={setPercentage(20)} />
              <GridColumn field="model" title={localizationService.toLanguageString('custom.model', '')} width={setPercentage(20)} />
              <GridColumn field="output" title={localizationService.toLanguageString('custom.output', '')} width={setPercentage(20)} />
              <GridColumn field="power_supply" title={localizationService.toLanguageString('custom.powerSupply', '')} width={setPercentage(23)} />
              <GridColumn field="cooling" title={localizationService.toLanguageString('custom.cooling', '')} width={setPercentage(20)} />
              <GridColumn field="motor_power" title={localizationService.toLanguageString('custom.motorPower', '')} width={setPercentage(23)} />
              <GridColumn field="pressure" title={localizationService.toLanguageString('custom.pressure', '')} width={setPercentage(20)} />
              <GridColumn field="regulation" title={localizationService.toLanguageString('custom.regulation', '')} width={setPercentage(20)} />
              <GridColumn field="serial_number" title={localizationService.toLanguageString('custom.serialNumber', '')} width={setPercentage(23)} />
              <GridColumn field="type" title={localizationService.toLanguageString('custom.type', '')} width={setPercentage(20)} />
              <GridColumn field="year" title={localizationService.toLanguageString('custom.yearManufactured', '')} width={setPercentage(30)} />
              <GridColumn field="comment" title={localizationService.toLanguageString('custom.comment', '')} width={setPercentage(30)} />
            </Grid>
          </ExcelExport>
          <GridPDFExport
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
          >
            {
              <Grid
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                data={process(compressors, {
                  skip: dataState.skip,
                  take: dataState.take,
                })}
              >
                <GridColumn field="client.id" width="200px" />
                <GridColumn
                  field="real_comp_nickname"
                  width="300px"
                />
              </Grid>
            }
          </GridPDFExport>
        </div>
      }
      {casIsBlocked &&
        <div>The Cas that you've selected is been reviewed. You can't modify the data.</div>

      }
      <div style={{ display: "flex" }}>
        {visibleDialog && (
          <Dialog style={{ display: "flex", maxWidth: "850" }} title={localizationService.toLanguageString('custom.pleaseConfirm', '')} onClose={toggleDialog} className="dialogDisplayComp">
            <Form onSubmitClick={onSubmit} onSubmit={onSubmit} render={(formRenderProps) => (
              <FormElement
                style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >

                <div className="column">
                  <div>
                    <label htmlFor="brand-name" className="text-info">{localizationService.toLanguageString('custom.brand', '')}</label>
                    <DropDownList
                      value={compBrand}
                      textField="text"
                      dataItemKey="id"
                      onChange={(e) => {
                        setCompBrand(e.target.value);
                        const brandIdModels = compressorsTemplateData.filter(function (elem) {
                          return (elem.brand_id == e.target.value.id)
                        });
                        const uniqueItemsModel = uniqueElementModel(brandIdModels);
                        setCompModelData(uniqueItemsModel);
                        setCompModel('');
                        Object.keys(compressorModelSelected).forEach((i) => compressorModelSelected[i] = null);
                      }}
                      data={compBrandData}
                    />
                  </div>
                  <div>
                    <label htmlFor="model-name" className="text-info">{localizationService.toLanguageString('custom.model', '')}</label>
                    <DropDownList
                      data={compModelData}
                      onChange={(e) => {
                        setCompModel(e.target.value);
                        compressorsTemplateData.filter(function (elem) {
                          //return false for the element that matches both the name and the id
                          return (elem.brand_id == compBrand.id && elem.comp_model == e.target.value)
                        });
                      }
                      }
                      value={compModel}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressor-nickname-name" className="text-info">{localizationService.toLanguageString('custom.compressorNickname', '')}</label>
                    <Input type="text" name="nickname"
                      value={compNickname}
                      style={{ height: "30px" }}
                      onChange={e =>
                        //@ts-ignore
                        setCompNickname(e.target.value)
                      } />
                  </div>
                  <div>
                    <label htmlFor="compresor-serial-no-name" className="text-info">{localizationService.toLanguageString('custom.compressorSerialNo', '')}</label>
                    <Input type="text" name="serialNo"
                      value={compSerialNo}
                      style={{ height: "30px" }}
                      onChange={e =>
                        //@ts-ignore
                        setCompSerialNo(e.target.value)
                      } />
                  </div>



                  <div>
                    <label htmlFor="compressor-type-name" className="text-info">{localizationService.toLanguageString('custom.compressorType', '')}</label>
                    <DropDownList
                      data={compTypes}
                      textField="text"
                      dataItemKey="id"
                      onChange={e => setCompType(e.target.value)}
                      value={compType}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressure-pressure-name" className="text-info">{localizationService.toLanguageString('custom.compressorPressure', '')}</label>
                    <Input type="text" name="pressure"
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      value={compPressure}
                      validationMessage={floatValidationMessage}
                      style={{ height: "30px" }}
                      onChange={e =>
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompPressure(e.target.value)} />
                    <label htmlFor="compressure-pressure-name" className="text-info">{localizationService.toLanguageString('custom.pressureUnit', '')}</label>
                    <DropDownList
                      required={true}
                      value={unitsPressureSelected}
                      textField="symbol"
                      dataItemKey="id"
                      onChange={(e) => {
                        setUnitsPressureSelected(e.target.value);
                      }}
                      data={unitsPressure}
                    />
                  </div>
                </div>




                <div className="column">

                  <div>
                    <label htmlFor="compressor-regulation-name" className="text-info">{localizationService.toLanguageString('custom.compressorRegulation', '')}</label>
                    <DropDownList
                      data={compRegulations}
                      textField="text"
                      dataItemKey="id"
                      onChange={e => setCompRegulation(e.target.value)}
                      value={compRegulation}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressor-cooling-name" className="text-info">{localizationService.toLanguageString('custom.compressorCooling', '')}</label>
                    <DropDownList
                      data={compCoolings}
                      textField="text"
                      dataItemKey="id"
                      onChange={e => setCompCooling(e.target.value)}
                      value={compCooling}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressor-year" className="text-info">{localizationService.toLanguageString('custom.compressorYearManufactured', '')}</label>
                    <DropDownList
                      value={compYear}
                      textField="text"
                      dataItemKey="id"
                      onChange={(e) => {
                        setCompYear(e.target.value);
                      }}
                      data={years}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressor-power-supply-name" className="text-info">{localizationService.toLanguageString('custom.compressorPowerSupply', '')}</label>
                    <Input
                      type="text" name="power-supply"
                      pattern={"^[0-9]*[1-9][0-9]*$"}
                      validationMessage={integerValidationMessage}
                      value={compPowerSupply}
                      style={{ height: "30px" }}
                      onChange={e =>
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompPowerSupply(e.target.value)
                      } />

                    <label htmlFor="compressor-power-supply-name" className="text-info">{localizationService.toLanguageString('custom.powerSupplyUnit', '')}</label>
                    <DropDownList
                      value={unitsPowerSelected}
                      textField="symbol"
                      dataItemKey="id"
                      onChange={(e) => {
                        setUnitsPowerSelected(e.target.value);
                      }}
                      data={unitsPower}
                    />
                  </div>
                  <div>
                    <label htmlFor="compressor-motor-power-name" className="text-info">{localizationService.toLanguageString('custom.compressorMotorPower', '')}</label>
                    <Input type="text" name="motor-power"
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      validationMessage={floatValidationMessage}
                      value={compMotorPower}
                      style={{ height: "30px" }}
                      onChange={e =>
                        //@ts-ignore
                        setCompMotorPower(e.target.value)
                      } />
                    <label htmlFor="compressor-motor-power-name" className="text-info">{localizationService.toLanguageString('custom.motorPowerUnit', '')}</label>
                    <DropDownList
                      value={unitsMotorPowerSelected}
                      textField="symbol"
                      dataItemKey="id"
                      onChange={(e) => {
                        setUnitsMotorPowerSelected(e.target.value);

                      }}
                      data={unitsMotorPower}
                    />
                  </div>

                </div>



                <div className="column">

                  <div>
                    <label htmlFor="compressor-output-name" className="text-info">{localizationService.toLanguageString('custom.compressorOutput', '')}</label>
                    <Input name="output"
                      validationMessage={floatValidationMessage}
                      pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                      value={compOutput}
                      style={{ height: "30px" }}
                      onChange={e =>
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                        setCompOutput(e.target.value)
                      } />
                    <label htmlFor="compressor-output-name" className="text-info">{localizationService.toLanguageString('custom.outputUnit', '')}</label>
                    <DropDownList
                      value={unitsOutputSelected}
                      textField="symbol"
                      dataItemKey="id"
                      onChange={(e) => {
                        setUnitsOutputSelected(e.target.value);
                      }}
                      data={unitsOutput}
                    />
                  </div>
                  <div>
                    <label htmlFor="comment-name" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.comment', '')}</label>
                    <TextArea
                      value={compressorComment}
                      maxLength={500}
                      rows={4}
                      onChange={e => {
                        setCompressorComment(e.value)
                      }}
                    />
                  </div>
                  <h1 className="UploadFilesFirstTitle">{localizationService.toLanguageString('custom.myCompressorFiles', '')}</h1>
                  <UploadFiles idElement={selectedCompressor.id} element="compressors_site" setFileName={setFileName} fileName={fileName} data={newCompressorFilesForApi} finishUploadCallback={finishUploadCallback}></UploadFiles>
                  <DisplayFiles currentFiles={currentFiles} finishUploadCallback={finishUploadCallback} />
                </div>


                <div className="column">
                  <div className="strike"><span>{localizationService.toLanguageString('custom.optionalFields', '')} </span></div>
                  <div className="info">
                    <p><strong><span className="k-icon k-i-information"></span></strong> {localizationService.toLanguageString('custom.optionalFieldsInfo', '')} </p>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="frequency" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.frequency', '')} Hz</label>
                      <Input value={compFrequency}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setCompFrequency(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="power_factor_loaded" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.PowerFactorLoaded', '')}</label>
                      <Input value={powerFactorLoaded}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setPowerFactorLoaded(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="power_factor_offload" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.PowerFactorOffload', '')} </label>
                      <Input value={powerFactorOffload}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setPowerFactorOffload(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="total_power_loaded" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.totalPowerLoaded', '')}</label>
                      <Input value={totalPowerLoaded}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setTotalPowerLoaded(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="total_power_offload" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.totalPowerOffload', '')}</label>
                      <Input value={totalPowerOffload}
                        pattern={"[\-\+]?[0-9]*(\\.[0-9]+)?"}
                        maxLength={5}
                        validationMessage={floatValidationMessage}
                        onBlur={postCompressorSave}
                        style={{ height: "30px", marginRight: "10px", width: "190px" }}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setTotalPowerOffload(e.target.value)
                        }} />

                    </div>
                  </div>
                  <div className="mb-3">
                    <div>
                      <label htmlFor="control_system" className="text-info, label-add-compressors">{localizationService.toLanguageString('custom.controlSystem', '')}</label>
                      <Checkbox value={controlSystem}
                        style={{ marginLeft: "10px" }}
                        onBlur={postCompressorSave}
                        onChange={e => {
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
                          setControlSystem(e.target.value)
                        }} />

                    </div>
                  </div>
                </div>

                <Button type={"submit"}  primary className="btn btn-outline-success btn-block mt-1">
                  <h6>{localizationService.toLanguageString('custom.save', '')}</h6>

                </Button>
                <Button type='button' className="btn btn-outline-danger btn-block mt-1" onClick={toggleDialogConfirmationDelete}>
                  {localizationService.toLanguageString('custom.delete', '')}
                </Button>
              </FormElement>
            )}
            />


          </Dialog>

        )}
      </div>

      {(visibleDialogConfirmationDelete) &&
        <Dialog title={localizationService.toLanguageString('custom.pleaseConfirm', '')} onClose={toggleDialogConfirmationDelete}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {localizationService.toLanguageString('custom.deleteConfirmationCompressor', '')}
          </p>
          <DialogActionsBar>
            <button className="k-button" onClick={deleteCompressor}>
              {localizationService.toLanguageString('custom.ok', '')}
            </button>
            <button className="k-button" onClick={toggleDialogConfirmationDelete}>
              {localizationService.toLanguageString('custom.cancel', '')}
            </button>
          </DialogActionsBar>
        </Dialog>
      }
    </div >
  );
};
export default DisplayComp
