 import { config } from "../Config" 
 
 const RefreshToken = () => { 
        let refreshToken;
        for (const key in localStorage) { 
            if (key.includes("refreshToken")){
                refreshToken = localStorage.getItem(key);
                break;
            }
        }
        fetch(`https://${config.authDomain}/oauth2/token`, {
            method: "POST",
            headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
            body: Object.entries({
            "grant_type": "refresh_token",
            "client_id": config.clientId,
            "redirect_uri": window.location.origin,
            "refresh_token": refreshToken,
            }).map(([k, v]) => `${k}=${v}`).join("&"),
        })
        .then(response => response.json())
        .then(data => {
            for (const key in localStorage) { 
            if (key.includes("idToken")){
                localStorage.setItem(key, data.id_token)
                window.dispatchEvent( new Event('storage') ) 
            } 
            if (key.includes("accessToken")){
                localStorage.setItem(key, data.access_token)
            }
            localStorage.setItem("refreshingTokenSemaphore", "false");
        }          
        })
        .catch(err => {
            console.log(err)
            localStorage.clear();
            window.location.href = `https://${config.authDomain}/logout?client_id=${config.clientId}&logout_uri=${config.signOutUri}` 
            window.location.href = "/"
            }
        ); 
}

export default RefreshToken

