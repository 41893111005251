import { getCall, postCall, deleteCallAsync, getCallAsync } from '../apiHelpers';
import { Cas } from '../interfaces/cas';


export const fetchCaSystems =  (authToken: string, site_id: number, transformer:any, setter: any) => 
    getCall(authToken, `/casystems?siteid=${site_id}`, transformer, setter)
    
export const fetchCaSystemsAsync =  (authToken: string, site_id: number) => 
    getCallAsync(authToken, `/casystems?siteid=${site_id}`)

export const fetchCas =  (authToken: string, cas_id: number, site_id: number, transformer: any, setter: any) => 
    getCall(authToken, `/casystems/${cas_id}?siteid=${site_id}`, transformer, setter)

export const createCas =  (authToken: string, data: Cas) => 
    postCall(authToken, `/casystems`, data )

export const updateCas = (authToken: string, cas_id: number, data: Cas) => 
    postCall(authToken, `/casystems/${cas_id}`, data )

export const fetchCompressorsFromCas =  (authToken: string, cas_id: number, site_id: number, transformer: any, setter: any) => 
    getCall(authToken, `/casystems/${cas_id}/compressors?siteid=${site_id}`, transformer, setter)

export const deleteCas =  (authToken: string, cas_id: number, site_id: number, transformer: any, setter: any) => 
    deleteCallAsync(authToken, `/casystems/${cas_id}/delete?siteid=${site_id}`)

export const publishCas =  (authToken: string, cas_id: number, site_id: number, data: any) => 
    postCall(authToken, `/casystems/${cas_id}/publish?siteid=${site_id}`, data)

export const fetchCasSummary =  (authToken: string, cas_id: number, site_id: number, transformer: any, setter: any) => 
    getCall(authToken, `/casystems/${cas_id}/summary?siteid=${site_id}`, transformer, setter)


    

    