import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../contexts/UserContextProvider'
import { USER_ROLES } from '../constants/Constants'
import Admin from '../components/users/Admin'
import BasicUser from '../components/users/BasicUser'
import Customer from '../components/users/Customer'
import Employee from '../components/users/Employee'
import Partner from '../components/users/Partner'
import { Container, Row, Col } from 'reactstrap'
import DisplayComp from '../components/compressors/DisplayComp'
import CreateComp from '../components/newcomp/CreateComp'
import UserProfile from '../components/users/UserProfile'
import CustomerEvents from '../components/customerevents/CustomerEvents'
import { Button } from '@progress/kendo-react-buttons'
import { useLocalization } from '@progress/kendo-react-intl';
import SelectedCasContextProvider from '../contexts/SelectedCasContextProvider'

/**
* Component for the MyPage.
* A user can reach to diffrent 'MyPage' according to their 'custome:role' attribute.
*/

enum Section{
  AddNew,
  Display,
  Publish
}

const MyPage = () => {
  const { getSessions, currentUser } = useContext<any>(UserContext)
  const [rSelected, setRSelected] = useState(Section.AddNew);
  const localizationService = useLocalization();

  return (
    <SelectedCasContextProvider>
      <Container className="">
        <Row className="">
          <Col lg="12" className="px-0 mt-3">
            <h4 className="text-info">COMPRESSORS</h4>
            <hr />
          </Col>
        </Row>
        <Row className="d-flex px-0 profile-">
          <Col lg="3" className="mt-3">
          <Button autoFocus className="add-compressor-btn"
              onClick={() => setRSelected(Section.AddNew)} >
              {localizationService.toLanguageString('custom.AddCompressor', '')}
            </Button>
          </Col>
          <Col lg="3" className="mt-3">
          <Button className="display-compressor-btn"
              onClick={() => setRSelected(Section.Display)}>
              {localizationService.toLanguageString('custom.DisplayCompressors', '')}
            </Button>
          </Col>
          {/*        <Col lg="2" className="mt-3">
          <button className="btn btn-sm btn-outline-info mt-0 btn-block publish-compressor-btn"
            onClick={() => setRSelected(5)} active={rSelected}>
              Publish log
          </button>
        </Col>*/}
        </Row>
        <hr />
        <Row>
          <Col lg="12" className="mt-3">
            {(() => {
              if (rSelected === Section.Display) return <DisplayComp />
              else if (rSelected === Section.AddNew) return <CreateComp />
              else if (rSelected === Section.Publish) return <CustomerEvents />
            })()}
          </Col>
        </Row>
      </Container>
    </SelectedCasContextProvider>
  )
}

export default MyPage