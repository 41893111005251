import { getCall, deleteCallAsync, postCall } from '../apiHelpers';
import { CompressorFiles } from '../interfaces/compressor_files';

export const compSitefileUpload =  (authToken: string, compId: number, data: CompressorFiles) => 
    postCall(authToken, `/compressors_site/${compId}/file/upload`, data)

export const listCompFiles =  (authToken: string, compId: number, site_id: number, transformer:any, setter: any) => 
    getCall(authToken, `/compressors_site/${compId}/file?siteid=${site_id}`, transformer, setter)

export const presignedDownloadUrl =  (authToken: string, compId: number, fileId: number, site_id: number, transformer:any, setter: any) => 
    getCall(authToken, `/compressors_site/${compId}/file/${fileId}?siteid=${site_id}`, transformer, setter)
 
export const deleteCompFile =  (authToken: string, compId: number, fileId: number, site_id: number) => 
    deleteCallAsync(authToken, `/compressors_site/${compId}/file/${fileId}/delete?siteid=${site_id}`)
