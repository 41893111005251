import axios from 'axios';
import React, { useState } from 'react'
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { load } from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import currencies from "cldr-numbers-full/main/es/currencies.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import { esMessages } from "../../languages/es";
import { process } from "@progress/kendo-data-query";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

const DetailComponent = (props: any) => {

  const dataItem = props.dataItem;
  return (
    <div>
      <section
        style={{
          width: "200px",
          float: "left",
        }}
      >
        <p>
          <strong>name:</strong> {dataItem.name}
        </p>
        <p>
          <strong>phone:</strong> {dataItem.phone}
        </p>
        <p>
          <strong>email:</strong> {dataItem.email}
        </p>
        <p>
          <strong>title:</strong> {dataItem.title}
        </p>
      </section>
      <Grid
        style={{
          width: "500px",
        }}
        data={dataItem.client.users}
      />
    </div>
  );
};

const Customers = () => {
  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async (id: any) => {
    try {
      const result = await axios.get(`https://integration.upsales.com/api/v2/contacts/?token=f2c4a4e9747cc45c68fccdbfaf6f6b93269d858f9b5586fb9e546df3ffafbd70`)
      setCustomers(result.data.data)
      setNewData(result.data.data)
    } catch (err: any) {
      console.log("AXIOS ERROR", err.message);
    }
  }
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  fetchCustomers()
  const setNewData = (newData: any) => {
    setDataResult(process(newData, dataState));
  }

  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [
      {
        field: "name",
        dir: "desc",
      },
    ],
    group: [
      {
        field: "id",
      },
    ],
  });
  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [dataResult, setDataResult] = React.useState(
    // ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    process(customers, dataState)
  );

  const dataStateChange = (event: any) => {
    setDataResult(process(customers, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: any) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({ ...dataResult });
  };

  let _pdfExport: any;

  const exportExcel = () => {
    _export.save();
  };

  let _export: any;

  const exportPDF = () => {
    _pdfExport.save();
  };

  return (
        <div>
          <ExcelExport
            data={customers}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
            <Grid
              style={{
                height: "700px",
              }}
              sortable={true}
              filterable={true}
              groupable={true}
              reorderable={true}
              pageable={{
                buttonCount: 4,
                pageSizes: true,
              }}
              data={dataResult}
              {...dataState}
              onDataStateChange={dataStateChange}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}
            >
              <GridToolbar>
                <button
                  title="Export to Excel"
                  className="k-button k-primary"
                  onClick={exportExcel}
                >
                  Export to Excel
                </button>
                &nbsp;
                <button className="k-button k-primary" onClick={exportPDF}>
                  Export to PDF
                </button>
              </GridToolbar>
              <GridColumn field="id" width="200px" />
              <GridColumn
                field="client.name"
                width="300px"
              />
            </Grid>
          </ExcelExport>
          <GridPDFExport
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
          >
            {
              <Grid
                data={process(customers, {
                  skip: dataState.skip,
                  take: dataState.take,
                })}
              >
                <GridColumn field="client.id" width="200px" />
                <GridColumn
                  field="client.name"
                  width="300px"
                />
              </Grid>
            }
          </GridPDFExport>
        </div>
  );
};


export default Customers
